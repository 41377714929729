import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getFormDataFromPayload } from "../../utilities/src/handle-form";
import { getStorageData } from "../../../framework/src/Utilities";

type ImgLink = string | ArrayBuffer | null;

type CategorySubCategory = {
  id: number;
  name: string;
};

type Category = {
  id: number;
  attributes: {
    name: string;
    sub_categories: CategorySubCategory[];
  };
};

export const initCategory = {
  id: 0,
  name: "",
};

type Slot = {
  id: number;
  duration: string;
  price: string;
};

export type Service = {
  catalogueId: number;
  serviceImage: File | null;
  serviceName: string;
  category: CategorySubCategory;
  subCategory: CategorySubCategory;
  targetedRegion: CategorySubCategory;
  serviceDescription: string;
  slots: Slot[];
};

export type Slots = {
  id: number;
  duration: string;
  price: string;
};

export type Catalogue = {
  id: number;
  attributes: {
    name: string;
    region: CategorySubCategory | null;
    availabilities: {
      availability_id: number;
      capacity: number;
      dates: string[];
      duration: {
        duration: string;
        id: number;
        price: string;
        start_time: string;
        end_time: string;
      };
      timeslots: string[];
    }[];
    images:
      | {
          url: string;
        }[]
      | null;
    description: string;
    sub_category: CategorySubCategory;
    category: {
      attributes: CategorySubCategory;
    };
    catalogue_slots: Slots[];
  };
};

const initCatSubCat = {
  id: 0,
  name: "",
};

export const initCatalogues = [
  {
    id: 0,
    attributes: {
      name: "",
      region: initCatSubCat,
      availabilities: [
        {
          availability_id: 0,
          capacity: 0,
          dates: [],
          duration: {
            duration: "",
            id: 0,
            price: "0",
            start_time: "",
            end_time: "",
          },
          timeslots: [],
        },
      ],
      images: [
        {
          url: "",
        },
      ],
      description: "",
      sub_category: initCatSubCat,
      category: {
        attributes: initCatSubCat,
      },
      catalogue_slots: [
        {
          id: 0,
          duration: "",
          price: "",
        },
      ],
    },
  },
];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  upldImgLinks: ImgLink[];
  catalogueIdsToDelete: number[];
  categories: Category[];
  regions: CategorySubCategory[];
  slotsIdsToDelete: number[][];
  loading: boolean;
  catalogues: Catalogue[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class EditServiceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCataloguesCallId = "";
  apiGetCategoriesCallId = "";
  apiGetRegionsCallId = "";
  apiCreateUpdateServiceCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      upldImgLinks: [],
      catalogueIdsToDelete: [],
      categories: [],
      regions: [],
      slotsIdsToDelete: [],
      loading: false,
      catalogues: initCatalogues,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.setState({ loading: false });

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson) {
        this.apiSuccesssResponseCallback(apiRequestCallId, responseJson);
      }
      if (errorReponse || responseJson.errors) {
        this.showAlert(
          configJSON.commonErrorText,
          configJSON.commonErrorDescription
        );
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getCategories();
    this.getRegions();
    await this.getCatalogues();
  }

  apiSuccesssResponseCallback = (
    apiRequestCallId: string,
    responseJson: any
  ) => {
    if (apiRequestCallId === this.apiGetCategoriesCallId) {
      this.setState({ categories: responseJson.data });
    }
    if (apiRequestCallId === this.apiCreateUpdateServiceCallId) {
      this.goToBasicInformation();
    }
    if (apiRequestCallId === this.apiGetCataloguesCallId) {
      this.setState({ catalogues: responseJson.data });
    }
    if (apiRequestCallId === this.apiGetRegionsCallId) {
      this.setState({
        regions: responseJson.map((region: CategorySubCategory) => ({
          id: region.id,
          name: region.name,
        })),
      });
    }
  };

  async getCatalogues() {
    this.setState({ loading: true });
    const authToken = await getStorageData("authToken");

    const headers = {
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCataloguesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userCatalogueAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  removeImgUpload = (index: number) => {
    const upldImgLinks = this.state.upldImgLinks;
    upldImgLinks.splice(index, 1);
    this.setState({ upldImgLinks });
  };

  setCatalogueIdsToDelete = (catalogue: number) =>
    this.setState({
      catalogueIdsToDelete: [...this.state.catalogueIdsToDelete, catalogue],
    });

  handleImgUpload = (imageItem: File | undefined, index: number) => {
    const fileReader = new FileReader();
    fileReader.addEventListener("load", async () => {
      const upldImgLinks = this.state.upldImgLinks;
      upldImgLinks[index] = fileReader.result;
      this.setState({ upldImgLinks });
    });
    imageItem && fileReader.readAsDataURL(imageItem);
  };

  urlToFileObject = async (image?: string): Promise<File | undefined> => {
    if (image) {
      const response = await fetch(image);
      // here image is url/location of image
      const blob = await response.blob();
      return new File([blob], "image.jpg", { type: blob.type });
    }
  };

  stripTags = (data: string) => {
    const tmpElement = document.createElement("div");
    tmpElement.innerHTML = data;
    return tmpElement.textContent || tmpElement.innerText || "";
  };

  getSimpleCategories = (): CategorySubCategory[] =>
    this.state.categories.map((category) => ({
      id: Number(category.id),
      name: category.attributes.name,
    }));

  getCategories() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCategoriesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRegions() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetRegionsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.regionApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAutocompleteStyle = (isError: boolean) => ({
    padding: "12px 32px 12px 16px",
    borderRadius: "8px",
    minHeight: "56px",
    ...(isError
      ? { border: "1px solid red" }
      : { border: "1px solid #CBD5E1" }),
  });

  getSubCategories = (categoryId: number): CategorySubCategory[] =>
    this.state.categories
      .find((category) => Number(category.id) === categoryId)
      ?.attributes.sub_categories.map((subCategory) => ({
        id: Number(subCategory.id),
        name: subCategory.name,
      })) || [initCategory];

  setSlotIdsToDelete = (slot: number, index: number) => {
    const copySlots = this.state.slotsIdsToDelete;
    copySlots[index] = [...(copySlots[index] || []), slot];
    this.setState({ slotsIdsToDelete: copySlots });
  };

  callCreateUpdateCatalogue = async (services: Service[]) => {
    const formData = new FormData();
    const updFormData = new FormData();
    services.forEach((service: Service, index) => {
      const {
        catalogueId,
        serviceImage,
        serviceName,
        category,
        subCategory,
        serviceDescription,
        targetedRegion,
        slots,
      } = service;

      const slotPairs = slots.map((slot) => ({
        "data[][slots_attributes][][duration]": slot.duration,
        "data[][slots_attributes][][price]": slot.price,
        ...(slot.id && { "data[][slots_attributes][][id]": slot.id }),
      }));

      // Remove slots
      const slotsToDelete = this.state.slotsIdsToDelete[index]?.map(
        (slotId) => ({
          "data[][slots_attributes][][id]": slotId,
          "data[][slots_attributes][][_destroy]": true,
        })
      );

      const productPayload = {
        "data[]id": catalogueId,
        "data[][price]": slots[0].price,
        "data[][images][]": [serviceImage],
        "data[][name]": serviceName,
        "data[][category_id]": category.id,
        "data[][sub_category_id]": subCategory.id,
        "data[][description]": serviceDescription,
        "data[]bx_block_categories_region_id": targetedRegion.id,
        slotPairs: slotPairs,
        slotPairsToDelete: slotsToDelete,
      };
      getFormDataFromPayload(
        productPayload,
        catalogueId ? updFormData : formData
      );
    });
    const isFormData = !formData.entries().next().done;
    const isUpdFormData = !updFormData.entries().next().done;

    isFormData && this.createUpdateProduct(formData);
    isUpdFormData && this.createUpdateProduct(updFormData, true);
  };

  async createUpdateProduct(formData: any, updateMode?: boolean) {
    this.setState({ loading: true });
    const authToken = await getStorageData("authToken");
    const headers = {
      token: authToken,
    };
    const queryparams = updateMode
      ? {
          endPoint: configJSON.productApiUpdateEndPoint,
          method: configJSON.apiUpdateUserType,
        }
      : {
          endPoint: configJSON.productApiEndPoint,
          method: configJSON.callTypeApiValidateMobileNo,
        };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateUpdateServiceCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      queryparams.endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      queryparams.method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  callDeleteCatalogues = () =>
    this.state.catalogueIdsToDelete.forEach((catalogueId: number) =>
      this.deleteProduct(catalogueId)
    );

  async deleteProduct(id: number) {
    this.setState({ loading: true });
    const authToken = await getStorageData("authToken");
    const headers = {
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productApiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToBasicInformation = (isSuccess = true) => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationBasicInformation)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    isSuccess &&
      msg.addData(getName(MessageEnum.NavigationPayLoadMessage), {
        editMessage: "New service added successfully",
      });

    this.send(msg);
  };
  // Customizable Area End
}
