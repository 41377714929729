import {
    Box,
    IconButton,
    InputAdornment,
    InputLabel,
    Modal,
    Snackbar,
    TextField,
    Typography,
    styled,
} from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import UserProfileBasicController, {
    Props,
} from '../UserProfileBasicController';
import { TypographyView } from '../../../ordermanagement1/src/styledFont';
import { DeleteButton, LogoutButton } from '../CutomerSettings.web';
import { getStorageData } from '../../../../../packages/framework/src/Utilities';
import { getFormDataFromPayload } from '../../../../blocks/utilities/src/handle-form';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const CustomInputLabel = styled(InputLabel)({
    fontFamily: 'Ubuntu',
    fontWeight: 700,
    lineHeight: '24px',
    fontSize: '16px',
    color: '#334155',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '-9px',
});

const CustomTextField = styled(TextField)({
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
    marginBottom: '30px',
    width: '30rem',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#CBD5E1',
        },
        '&:hover fieldset': {
            borderColor: '#CBD5E1',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#CBD5E1',
        },
    },
    '& .MuiOutlinedInput-input': {
        fontFamily: 'Ubuntu',
        fontSize: '18px',
        padding: '12px 14px',
    },
    '& .MuiFormHelperText-root': {
        color: '#EF4444',
        marginTop: '5px',
        marginBottom: '5px',
        fontSize: '14px',
        background: 'none',
        padding: '0',
    },
});

export interface StateType {
    newValue: string;
    confirmValue: string;
    emailError: string | null;
    passwordError: string | null;
}

export default class UpdateDetailsModal extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    handleCloseModal = () => {
        if (this.props.setOpenUpdateModal) {
            this.setState({
                emailError: null,
                passwordError: null,
                newValue: '',
                confirmValue: '',
            });
            this.props.setOpenUpdateModal(false);
        }
    };

    handleInputChange = (name: keyof StateType, value: string) => {
        if (name === 'newValue') {
            const emailError = this.validateEmailId(value.trim()) ? null : 'Please enter a valid email';
            this.setState({
                newValue: value,
                emailError,
            });
        } else if (name === 'confirmValue') {
            const emailError = value !== this.state.newValue ? 'Emails do not match' : null;
            this.setState({
                confirmValue: value,
                emailError,
            });
        } else {
            this.setState({
                [name]: value,
            } as Pick<StateType, keyof StateType>);
        }
    };


    handleClickShowPassword = () => {
        this.setState((prevState) => ({
            enablePasswordField: !prevState.enablePasswordField,
        }));
    };

    handleClickConfirmShowPassword = () => {
        this.setState((prevState) => ({
            enableReTypePasswordField: !prevState.enableReTypePasswordField,
        }));
    };

    handleUpdate = async () => {
        const { newValue, confirmValue } = this.state;
        const { updateType } = this.props;

        if (newValue !== confirmValue) {
            this.setState(
                {
                    showSnack: true,
                    snackMessage: 'The values do not match!',
                },
                this.handleCloseModal
            );
            return;
        }

        try {
            const user_id = await getStorageData('user_id');
            let APIPayload = {};

            if (updateType === 'email') {
                if (!this.validateEmailId(newValue)) return;
                APIPayload = {
                    'account[email]': newValue,
                    'account[account_id]': user_id,
                };
            } else if (updateType === 'password') {
                if (!this.validateUserPassword(newValue)) return;
                APIPayload = {
                    'account[password]': newValue,
                    'account[account_id]': user_id,
                };
            }

            await this.updatProfileApiCall(getFormDataFromPayload(APIPayload));

            const successMessage = `${updateType === 'email' ? 'Email' : 'Password'
                } successfully updated`;
            this.setState(
                {
                    showSnack: true,
                    snackMessage: successMessage,
                },
                () => {
                    this.handleCloseModal();
                    if (updateType === 'email' && this.props.onEmailUpdate) {
                        this.props.onEmailUpdate(newValue);
                    }
                }
            );
        } catch (error) {
            this.setState({
                showSnack: true,
                snackMessage: `Update ${updateType === 'email' ? 'email' : 'password'
                    } failed. Please try again.`,
            });
        }
    };

    render() {
        const { openUpdateModal } = this.props;
        const isEmailUpdate = this.props.updateType === 'email';
        const { showSnack, snackMessage, emailError, passwordError } = this.state;

        const isError =
            this.getEitherLogicalValue(
                snackMessage.includes('do not match'),
                snackMessage.includes('failed')
            );

        return (
            <>
                <Modal
                    data-test-id='update-modal'
                    open={Boolean(openUpdateModal)}
                    onClose={this.handleCloseModal}
                >
                    <TypographyView>
                        <Box
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                width: '45%',
                                transform: 'translate(-50%, -50%)',
                                backgroundColor: '#FFFFFF',
                                borderRadius: '8px 8px 32px 8px',
                                outline: 'none',
                                maxWidth: '80%',
                                minWidth: '20rem',
                                height: 'auto',
                            }}
                        >
                            <Box p={2} borderBottom='1px solid #E2E8F0'>
                                <Box
                                    display='flex'
                                    justifyContent='space-between'
                                    alignItems='center'
                                >
                                    <CloseIcon
                                        data-test-id='update-close-btn'
                                        style={{ cursor: 'pointer', marginLeft: 'auto' }}
                                        onClick={this.handleCloseModal}
                                    />
                                </Box>
                                <Box px={5} py={1}>
                                    <Typography
                                        style={{
                                            fontSize: '24px',
                                            fontWeight: 700,
                                            marginLeft: '-15px',
                                        }}
                                    >
                                        {this.getTernaryValue(
                                            this.props.updateType === 'email',
                                            'Change Email Address',
                                            'Update Password'
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box borderBottom='1px solid #E2E8F0'>
                                <Box ml={5}>
                                    <Box>
                                        <CustomInputLabel style={{ marginTop: '20px' }}>
                                            {this.getTernaryValue(
                                                isEmailUpdate,
                                                'New Email Address',
                                                'New Password'
                                            )}
                                            <span style={{ color: 'red', paddingLeft: '5px' }}>
                                                {'*'}
                                            </span>
                                        </CustomInputLabel>
                                        <CustomTextField
                                            data-test-id='new-value'
                                            variant='outlined'
                                            fullWidth
                                            margin='normal'
                                            type={
                                                this.getTernaryValue(
                                                    isEmailUpdate,
                                                    'text',
                                                    this.getTernaryValue(this.state.enablePasswordField, 'password', 'text')
                                                )
                                            }
                                            value={this.state.newValue}
                                            onChange={(e) =>
                                                this.handleInputChange('newValue', e.target.value)
                                            }
                                            error={
                                                isEmailUpdate
                                                    ? Boolean(emailError)
                                                    : Boolean(passwordError)
                                            }
                                            helperText={isEmailUpdate ? emailError : passwordError}
                                            InputProps={{
                                                endAdornment: !isEmailUpdate && (
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label='toggle password visibility'
                                                            onClick={this.handleClickShowPassword}
                                                            edge='end'
                                                        >
                                                            {this.state.enablePasswordField ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                        <CustomInputLabel style={{ marginTop: '2px' }}>
                                            {isEmailUpdate
                                                ? 'Confirm New Email Address'
                                                : 'Confirm New Password'}
                                            <span style={{ color: 'red', paddingLeft: '5px' }}>
                                                {'*'}
                                            </span>
                                        </CustomInputLabel>
                                        <CustomTextField
                                            data-test-id='confirm-value'
                                            variant='outlined'
                                            fullWidth
                                            margin='normal'
                                            type={
                                                this.getTernaryValue(
                                                    isEmailUpdate,
                                                    'text',
                                                    this.getTernaryValue(this.state.enableReTypePasswordField, 'password', 'text')
                                                )
                                            }
                                            value={this.state.confirmValue}
                                            onChange={(e) =>
                                                this.handleInputChange('confirmValue', e.target.value)
                                            }
                                            error={
                                                isEmailUpdate
                                                    ? Boolean(emailError)
                                                    : Boolean(passwordError)
                                            }
                                            helperText={isEmailUpdate ? emailError : passwordError}
                                            InputProps={{
                                                endAdornment: !isEmailUpdate && (
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label='toggle password visibility'
                                                            onClick={this.handleClickConfirmShowPassword}
                                                            edge='end'
                                                        >
                                                            {this.state.enableReTypePasswordField ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box p={3}>
                                <Box display='flex' justifyContent='flex-end'>
                                    <LogoutButton
                                        data-test-id='update-cancel-btn'
                                        variant='contained'
                                        color='primary'
                                        style={{ marginRight: '10px', width: '7rem' }}
                                        onClick={this.handleCloseModal}
                                    >
                                        Cancel
                                    </LogoutButton>
                                    <DeleteButton
                                        data-test-id='update-confirm-btn'
                                        variant='contained'
                                        color='primary'
                                        style={{ marginRight: '10px', width: '7rem' }}
                                        onClick={this.handleUpdate}
                                    >
                                        Done
                                    </DeleteButton>
                                </Box>
                            </Box>
                        </Box>
                    </TypographyView>
                </Modal>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={showSnack}
                    onClose={() => this.setState({ showSnack: false })}
                    style={{
                        backgroundColor: '#FFFFFF',
                        color: '#0F172A',
                        borderRadius: '8px',
                        padding: '8px 16px',
                        boxShadow: '0px 6px 15px -3px #00000026',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {isError ? (
                            <CancelIcon
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    color: '#EF4444',
                                    marginRight: '8px',
                                }}
                            />
                        ) : (
                            <CheckCircleIcon
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    color: '#34D399',
                                    marginRight: '8px',
                                }}
                            />
                        )}
                        <Typography>{snackMessage}</Typography>
                    </div>
                </Snackbar>
            </>
        );
    }
}
