import React from "react"
// Customizable Area Start
import './SubCategories.css'
import { AppHeader } from '../../../components/src/AppHeader.web'

import { AppFooter } from '../../../components/src/AppFooter.web'
import LandingPageController, { configJSON } from "./LandingPageController"
import { imgI147504895236797486HTpe556x7cBoLvN2ZuRRme, image_venue, image_venue_1, image_venue_2, image_venue_3, image_venue_4, image_venue_5, image_star, left_arrow, right_arrow, categoryDefaultImage } from './assets'
import { url } from "inspector"
import { BreadCrumbs } from "../../../components/src/BreadCrumbs.web"
import { getStars } from '../../../components/src/helpers'
const baseURL = require('../../../framework/src/config.js').baseURL;

interface Props {
  navigation: any;
  id: string
}

export default class SubCategories extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    this.getSubCategoriesData()
  }

  render() {
    const { venuesData, imageLoading } = this.state;
    // TODO: Replace it with dynamic data from API within render map call
    return (
      <div style={{ backgroundColor: "#CBD5E1" }}>
        <AppHeader onClickEvent={() => this.goToLogin()} />
        {/* style={{backgroundImage: venuesData && venuesData.length>0 && venuesData[0].cat_image ? `url(${baseURL+venuesData[0]?.cat_image})` : `url(${imgI147504895236797486HTpe556x7cBoLvN2ZuRRme})`}} */}
        {/* {venuesData && venuesData.length>0 ? <div>{console.log("It's 1st div")}
          <div className="hero-section" style={{backgroundImage:`url(${baseURL+venuesData[0]?.cat_image})`}}>
            <div className="breadcrumb"><span className='home'>Home</span> {"   >   "} {venuesData && venuesData.length > 0 && venuesData[0].cat_name}</div>
            <div className='message-therapy'>
              <div className='massage'>
                <h1>{venuesData && venuesData.length > 0 && venuesData[0].cat_name}</h1>
              </div>
              <div>

                <p>{venuesData && venuesData.length > 0 && venuesData[0].cat_description}</p>
              </div>
            </div>
          </div>
        </div> : <div>{console.log("It's 2nd div")}
          <div className="hero-section" style={{backgroundImage:`url(${imgI147504895236797486HTpe556x7cBoLvN2ZuRRme})`}}>
            <div className="breadcrumb"><span className='home'>Home</span> {"   >   "} {venuesData && venuesData.length > 0 && venuesData[0].cat_name}</div>
            <div className='message-therapy'>
              <div className='massage'>
                <h1>{venuesData && venuesData.length > 0 && venuesData[0].cat_name}</h1>
              </div>
              <div>

                <p>{venuesData && venuesData.length > 0 && venuesData[0].cat_description}</p>
              </div>
            </div>
          </div>
        </div>} */}
        <div>
          <div
            className="hero-section"
            style={{
              backgroundImage: imageLoading && venuesData?.[0]?.cat_image
                ? `url(${venuesData[0]?.cat_image})`
                : `url(${imgI147504895236797486HTpe556x7cBoLvN2ZuRRme})`,
                paddingTop: 50
            }}
          >
            <div className="breadcrumb" >
              <BreadCrumbs items={['Home', `${venuesData && venuesData.length > 0 && venuesData[0].cat_name}`]} targetColor="#FFFFFF" />
            </div>
            <div className="message-therapy">
              <div className="massage">
                <h1>{venuesData && venuesData.length > 0 && venuesData[0].cat_name}</h1>
              </div>
              <div dangerouslySetInnerHTML={{
                __html: venuesData?.[0]?.cat_description
              }} />
            </div>
          </div>
        </div>

        <div className="top-massage-container">
          <div className="top-massage">
            <p>Top Massage Therapies Venues</p>
          </div>
          <div className="top-massage-lorem">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. . Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. .
            </p>
          </div>
          <div className="grid-div">
            {venuesData && venuesData.map((venue) => (
              <div>
                <div className="book_now_container">
                  <img className="image_venue" src={venue.images || venue.cat_image || categoryDefaultImage} alt="image_venue" />
                  <div className="book_now_div">
                    <button className="book_now"
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.goToVenue(venue.spa_id)}
                    >Book Now</button>
                  </div>
                </div>
                <div>
                  <div className="Textlabel" style={{ padding: '16px' }}>
                    <p className="textLabel">{venue.spa_name ? venue.spa_name : "The Massage Studio"}</p>
                    <p className="canada textLabel">{venue.address ? venue.address : "735 Nicholson Street, Canada"}</p>
                    <div className="star_rating">
                      {getStars(venue.rating)}
                      <p>{`(${venue.rating.toFixed(1)})`}</p>
                    </div>
                    <div className="starRating_below">
                      <p>
                        {venue.description ? venue.description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..."}

                      </p>
                    </div>
                    <div className="Deep_Cleansing">
                      <p style={{ margin: 0 }}>
                        {venue.sub_category.length ? venue.sub_category.map((subCat: any) => subCat[0]).join(", ") : configJSON.serviceConfirmation}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="pagination">
          <div>
            <button><img src={left_arrow} alt="left_arrow" /></button>
          </div>
          <div className="pagination_number">
            <p> 1 </p>
            <p>2</p>
            <p>...</p>
            <p>8</p>
          </div>
          <div>
            <button><img src={right_arrow} alt="right_arrow" /></button>
          </div>
        </div>
        <AppFooter navigation={this.props.navigation} />
      </div>
    )
  }
}
