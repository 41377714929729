import { Box, styled } from "@material-ui/core";

export const styles = {
  bodyDiv: {
    width: "55vw",
    margin: "1.5rem auto 5rem",
  },
  serviceForm: {
    backgroundColor: "#FFFFFF",
    borderBottom: "none",
    marginTop: "1.875rem",
    borderRadius: "8px 8px 32px 8px",
    padding: "2rem 2.5rem",
  },
  formTitle: {
    fontSize: "1.5rem",
    lineHeight: "2rem",
    fontWeight: 700,
  },
  formLabel: {
    color: "#475569",
    fontWeight: 700,
    lineHeight: "1.5rem",
  },
  errorText: {
    color: "#DC2626",
    fontSize: "0.75rem",
    lineHeight: "18px",
  },
  morePricingBtn: {
    background: "#F1F5F9",
    color: "#047854",
    padding: "10px 16px",
    textTransform: "none" as "none",
    marginTop: "0.5rem",
    boxShadow: "none",
  },
  addServiceBtn: {
    background: "var(--Primary, #D9EEEB)",
    color: "#047854",
    marginTop: "32px",
    padding: "10px 16px",
    textTransform: "none" as "none",
    boxShadow: "none",
  },
  addNewButton: {
    display: "flex",
    justifyContent: "end",
  },
  formButton: {
    height: "56px",
    width: "auto",
    minWidth: "100px",
    padding: "16px",
    backgroundColor: "#398378",
    textTransform: "none" as "none",
    fontSize: "1rem",
    fontFamily: "Ubuntu",
    fontWeight: 700,
    boxShadow: "none",
  },
  removeBin: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
};

export const ButtonComponent = styled(Box)({
  display: "flex",
  gap: "30px",
  flexDirection: "row",
  alignItems: "center !important",
  justifyContent: "end",
  "& .regButton": {
    display: "flex",
    width: "150px",
    height: "65px",
    borderRadius: "8px",
    fontSize: "16px",
    background: "var(--Primary, #398378)",
    fontFamily: "Ubuntu",
    textTransform: "none",
    textAlign: "center",
    padding: 0,
  },
});

export const StyledBox = styled(Box)({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "3px solid #D9EEEB !important",
  },
  "& .MuiAutocomplete-inputRoot.Mui-focused": {
    border: "1px solid #047754 !important",
    outline: "3px solid #D9EEEB",
  },
  '& .MuiAutocomplete-option[data-focus="true"]': {
    color: "#398378",
    fontWeight: 700,
    background: "#E8F6F4",
  },
  "& .MuiAutocomplete-option:hover": {
    backgroundColor: "#D9EEEB",
  },
  "& .MuiAutocomplete-endAdornment": {
    paddingRight: "0.75rem",
  },
});
