import { Box, Button, Modal, Typography } from '@material-ui/core';
import React from 'react';
import { TypographyView } from '../styledFont';
import Ordermanagement1Controller, {
  Props,
} from '../Ordermanagement1Controller';
import CloseIcon from '@material-ui/icons/Close';

export default class CustomerCancelModal extends Ordermanagement1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <Modal
        data-test-id='review-modal'
        open={Boolean(this.props.openCustomerCancelModal)}
        onClose={() => this.props.setOpenCustomerCancelModal?.(false)}
      >
        <TypographyView>
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '45%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#FFFFFF',
              borderRadius: '8px 8px 32px 8px',
              outline: 'none',
              // padding: '16px'
            }}
          >
            <Box p={2} borderBottom='1px solid #E2E8F0'>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <CloseIcon
                  data-test-id='review-close-btn'
                  style={{
                    cursor: 'pointer',
                    marginLeft: 'auto',
                  }}
                  onClick={() => this.props.setOpenCancelModal?.()}
                />
              </Box>
              <Box px={5} py={2}>
                <Typography
                  style={{
                    fontSize: '22px',
                    fontWeight: 700,
                    marginBottom: '8px',
                  }}
                >
                  Are you sure you want to cancel your appointment with "The
                  Massage Studio"?
                </Typography>
                <Typography
                  style={{
                    fontSize: '18px',
                    paddingBottom: '2px',
                  }}
                >
                  This action can't be undone, and all the data stored in this
                  account won't be accessible anymore.
                </Typography>
              </Box>
            </Box>
            <Box p={3}>
              <Box
                display='flex'
                justifyContent='space-between'
                width='50%'
                marginLeft='auto'
              >
                <Button
                  data-test-id='cancel-booking-btn'
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    this.props.setOpenCustomerCancelModal?.(false);
                  }}
                  className='declineButton'
                >
                  Yes
                </Button>
                <Button
                  data-test-id='refuse-booking-cancel-btn'
                  type='submit'
                  variant='contained'
                  color='primary'
                  className='agreeButton'
                  onClick={() => this.props.setOpenCustomerCancelModal?.(false)}
                >
                  No
                </Button>
              </Box>
            </Box>
          </Box>
        </TypographyView>
      </Modal>
    );
  }
}

