import React from "react";
// Customizable Area Start
import { Box, InputAdornment, Paper, TextField, Typography } from '@material-ui/core';
import { AppHeader } from '../../../components/src/AppHeader.web';
import {
  imgI14750489301945250HTpe556x7cBoLvN2ZuRRme,
  imgLocationSearch,
  imgMapImage
} from './assets';
import { Autocomplete } from '@material-ui/lab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { AppFooter } from '../../../components/src/AppFooter.web';
import 'pure-react-carousel/dist/react-carousel.es.css';
import LandingPageController from './LandingPageController';

interface Props {
  navigation: any;
  id: string;
}

const CustomPaper = (props: any) => {
  return <Paper style={{ width: 'calc(100% + 17px)' }} {...props} />;
};

export default class LandingPageLocation extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Custom style sheets
    const styles = {
      SafeAreaView: {
        backgroundColor: '#e8f6f4ff',
      },
      inputSearch: {
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        border: '1px solid #CBD5E1',
        fontSize: '24px',
        color: '#334155',
        padding: '10px 8px',
        display: 'block',
        width: 'auto'
      },
      pageTile: {
        backgroundColor: '#ffffff',
        cursor: 'pointer'
      },
      boldSmall: {
        fontFamily: 'Ubuntu',
        fontSize: '14px',
        fontWeight: 700,
        color: '#000000'
      },
      regularMiddle: {
        fontFamily: 'Ubuntu',
        fontSize: '16px',
        fontWeight: 400,
        color: '#000000'
      },
      tileParagraph: {
        padding: '13px 0'
      },
      dotsOverflow: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      },
      venueStatus: {
        color: '#34D399',
        marginRight: '10px'
      },
      backButton: {
        color: '#0F172A',
        cursor:'pointer'
      }
    };
    return (
      <Box style={styles.SafeAreaView} className='info-page-block'>
        <AppHeader onClickEvent={() => this.goToLogin()} />
        <Box className='search-location-block' py={6} px='140px'>
          <ArrowBackIcon style={styles.backButton} />
          <Typography
            style={{
              color: '#0F172A',
              fontFamily: 'Ubuntu',
              fontSize: '36px',
              fontWeight: '700',
              padding: '30px 0'
            }}
          >{`Enter Location`}</Typography>
          <Box
            display='flex'
            justifyContent='space-between'>
            <Box width='40%'>
              <Autocomplete
                id="company-search"
                options={[
                  'Cambodia',
                  'Canada',
                  'Cameroon',
                  'Cabo Verde',
                  'China'
                ]}
                PaperComponent={CustomPaper}
                autoComplete
                includeInputInList
                filterSelectedOptions
                disablePortal
                freeSolo
                renderInput={(params) => (
                  <div>
                    <TextField
                      {...params}
                      placeholder='Search by Name'
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <img width={24} height={24} src={imgI14750489301945250HTpe556x7cBoLvN2ZuRRme} alt="input-search" />
                          </InputAdornment>
                        )
                      }}
                      fullWidth
                      style={{
                        ...styles.inputSearch
                      }}
                    />
                  </div>
                )}
              />
              {/* Page tiles */}
              <Box>
                <Box mt='20px' p='12px' style={styles.pageTile}>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography
                      style={styles.boldSmall}
                    >
                      The Massage Studio
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: 'Ubuntu',
                        fontSize: '14px',
                        fontWeight: 700,
                        color: '#94A3B8'
                      }}
                    >
                      800
                    </Typography>
                  </Box>
                  <Typography
                    style={{
                      ...styles.regularMiddle,
                      ...styles.tileParagraph,
                      ...styles.dotsOverflow
                    }}
                  >
                    735, Nicholson Street, Calgary, Albertalonglonglonglonglong
                  </Typography>
                  <Typography style={styles.boldSmall}>
                    <span style={styles.venueStatus} >Open.</span>Closes at 8:00 pm
                  </Typography>
                </Box>
                <Box mt='20px' p='12px' style={styles.pageTile}>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography
                      style={styles.boldSmall}
                    >
                      The Massage Studio
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: 'Ubuntu',
                        fontSize: '14px',
                        fontWeight: 700,
                        color: '#94A3B8'
                      }}
                    >
                      800
                    </Typography>
                  </Box>
                  <Typography
                    style={{
                      ...styles.regularMiddle,
                      ...styles.tileParagraph,
                      ...styles.dotsOverflow
                    }}
                  >
                    735, Nicholson Street, Calgary, Albertalonglonglonglonglong
                  </Typography>
                  <Typography
                    style={styles.boldSmall}
                  >
                    <span style={styles.venueStatus} >Open.</span>Closes at 8:00 pm
                  </Typography>
                </Box>
                <Box mt='20px' p='12px' style={styles.pageTile}>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography
                      style={styles.boldSmall}
                    >
                      The Massage Studio
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: 'Ubuntu',
                        fontSize: '14px',
                        fontWeight: 700,
                        color: '#94A3B8'
                      }}
                    >
                      800
                    </Typography>
                  </Box>
                  <Typography
                    style={{
                      ...styles.regularMiddle,
                      ...styles.tileParagraph,
                      ...styles.dotsOverflow
                    }}
                  >
                    735, Nicholson Street, Calgary, Albertalonglonglonglonglong
                  </Typography>
                  <Typography
                    style={styles.boldSmall}
                  >
                    <span style={styles.venueStatus} >Open.</span>Closes at 8:00 pm
                  </Typography>
                </Box>
                <Box mt='20px' p='12px' style={styles.pageTile}>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography
                      style={styles.boldSmall}
                    >
                      The Massage Studio
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: 'Ubuntu',
                        fontSize: '14px',
                        fontWeight: 700,
                        color: '#94A3B8'
                      }}
                    >
                      800
                    </Typography>
                  </Box>
                  <Typography
                    style={{
                      ...styles.regularMiddle,
                      ...styles.tileParagraph,
                      ...styles.dotsOverflow
                    }}
                  >
                    735, Nicholson Street, Calgary, Albertalonglonglonglonglong
                  </Typography>
                  <Typography
                    style={styles.boldSmall}
                  >
                    <span style={styles.venueStatus} >Open.</span>Closes at 8:00 pm
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box width='55%'>
              <Typography
                style={{
                  ...styles.boldSmall,
                  marginBottom: '4px'
                }}>
                LOCATION
              </Typography>
              <Box p='12px' maxWidth='80%' borderRadius='30px' bgcolor='#ffffff' whiteSpace='nowrap' mb='14px'>
                <Box p={1} borderRadius='50%' bgcolor='#FEF3C7' display='inline-block' mr='14px'>
                  <img src={imgLocationSearch} alt='map marker' />
                </Box>
                <Typography
                  style={{
                    ...styles.regularMiddle,
                    ...styles.dotsOverflow,
                    display: 'inline-block',
                    width: '90%'
                  }}>
                  735 Nicholson Street Carlton North 3054 longlonglong
                </Typography>
              </Box>
              <img src={imgMapImage} alt='map area' width='100%' />
            </Box>
          </Box>
        </Box>
        {/* footer */}
        <AppFooter navigation={this.props.navigation} />
      </Box>
    )
  }
}
// Customizable Area End
