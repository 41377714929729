Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.getAPIMethod = "GET"
exports.userDetailsAPIEndPoint = "account_block/get_user_details";
exports.landingPageAPIEndPoint = "bx_block_dashboard4/landing_page_for_customer";
exports.userDetailsApiMethodType = "GET";
exports.landingPageApiMethodType = "GET";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.commonErrorText = "There is an error";
exports.commonErrorDescription = "Cannot retrieve data from server";
exports.reviewApiEndPoint = "bx_block_catalogue/reviews";
exports.cataloguesApiEndPoint = "bx_block_catalogue/catalogues";
exports.postCustomerBookingDetailsAPI="/bx_block_calendar/booked_slots"

exports.createBookingsApiEndPoint = "bx_block_calendar/booked_slots";


exports.textHeading = 'Find a Service close to you';
exports.description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.';
exports.slotsNotAvailable = 'Slots for current service are not awailable';
exports.servicesNotAvailable = 'There are no services yet';
exports.serviceConfirmation = 'Provided services should be confirmed';
// Customizable Area End