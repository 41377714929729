import React from "react";
// Customizable Area Start
import { Box, Button, InputLabel, TextField, Typography, styled } from '@material-ui/core';
import { profileCover } from "./assets"
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import { BookingWrapper } from "../../ordermanagement1/src/components/BookingWrapper";
import ConfirmationModal from "./components/ConfirmationModal";
import UpdateDetailsModal from "./components/UpdateDetailsModal";

const UpdateButton = styled(Button)({
    backgroundColor: '#E2E8F0',
    borderRadius: '20px',
    color: '#5500CC',
    padding: '5px 15px',
    textTransform: 'none',
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    fontFamily: "Ubuntu",
    '&:hover': {
        backgroundColor: '#f0f0f0',
    },
});

export const DeleteButton = styled(Button)({
    backgroundColor: '#398378',
    borderRadius: '8px',
    color: 'white',
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    fontFamily: "Ubuntu",
    padding: '16px',
    width: "20rem",
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#398378',
    },
});

export const LogoutButton = styled(Button)({
    backgroundColor: '#D1FAE5',
    borderRadius: '8px',
    color: '#047857',
    textTransform: 'none',
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    fontFamily: "Ubuntu",
    padding: '16px',
    width: "20rem",
    '&:hover': {
        backgroundColor: '#A7F3D0',
    },
});

// Customizable Area End


class CutomerSettings extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    handleOpenModal = (type: 'logout' | 'delete' | 'keep') => {
        let modalConfig;
    
        if (type === 'logout') {
            modalConfig = {
                title: 'Are you sure you want to logout of your account?',
                message: '',
                confirmText: 'Logout',
                cancelText: 'Cancel',
                onConfirm: this.handleLogout,
            };
        } else if (type === 'delete') {
            modalConfig = {
                title: 'Are you sure you want to delete your account?',
                message: "This action can't be undone and all the data stored in this account won’t be accessible anymore.",
                confirmText: 'Delete Account',
                cancelText: 'Keep Account',
                onConfirm: this.handleDeleteAccount,
            };
        }
    
        this.setOpenConfirmationModal(true, modalConfig);
    };    
    

    handleLogout = () => {
        this.setOpenConfirmationModal(false);
        this.logout();
    };

    handleDeleteAccount = async () => {
        this.setOpenConfirmationModal(false);
        const success = await this.deleteUser();
        if (success) {
            this.props.navigation.navigate('EmailAccountLoginBlock');
        }
    }

    handleOpenUpdateModal = (type: string) => {
        this.setState({
            openUpdateModal: true,
            updateType: type,
        });
    };

    handleCloseUpdateModal = () => {
        this.setState({ openUpdateModal: false });
    };

    handleEmailUpdate = (newEmail: string) => {
        this.setState({
            email: newEmail,
        });
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const customStyles = {
            background: `linear-gradient(
                rgba(0, 0, 0, 0.2), 
                rgba(0, 0, 0, 0.5)
              ), url(${profileCover}) center bottom 30% / 100% auto no-repeat`,
        };

        const { openConfirmationModal } = this.state;

        return (
            <Box bgcolor="#D9EEEB">
                <BookingWrapper
                    navigation={this.props.navigation}
                    backgroundImage={profileCover}
                    heading="Settings"
                    breadcrumbs={['Home', 'My Profile', 'Settings']}
                    customStyles={customStyles}
                >
                    <Box bgcolor="#F8FFFE"
                        marginTop={4}
                        data-testid="toggle-button-box"
                        p={6} px={12}>
                        <Box>
                            <Typography style={{
                                fontFamily: "Ubuntu", fontWeight: 700, lineHeight: "32px",
                                fontSize: "24px", letterSpacing: "-0.12px"
                            }}>
                                Update Personal Information
                            </Typography>
                        </Box>
                        <Box mt={4}>
                            <Box >
                                <InputLabel style={{
                                    fontFamily: "Ubuntu", fontWeight: 700,
                                    lineHeight: "24px",
                                    fontSize: "16px",
                                    color: "#334155",
                                    marginBottom: "-9px"
                                }}>
                                    Email Id
                                </InputLabel>
                                <Box display="flex" alignItems="center">
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        name="email"
                                        style={{
                                            borderRadius: "8px",
                                            border: "1px #CBD5E1",
                                            width: "30rem"
                                        }}
                                        value={
                                            this.state.email ||
                                            this.state.userDetails.attributes.email ||
                                            ''
                                        } />
                                    <UpdateButton
                                        data-test-id="email-input"
                                        style={{ marginLeft: "1rem" }}
                                        onClick={() => this.handleOpenUpdateModal('email')}>
                                        Change</UpdateButton>
                                </Box>
                            </Box>
                            <Box >
                                <InputLabel
                                    style={{
                                        fontFamily: "Ubuntu",
                                        fontWeight: 700,
                                        lineHeight: "24px",
                                        fontSize: "16px",
                                        color: "#334155",
                                        marginTop: "1rem",
                                        marginBottom: "-9px"
                                    }}
                                >
                                    Password
                                </InputLabel>
                                <Box display="flex" alignItems="center">
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        type="password"
                                        name="password"
                                        value={"*******"}
                                        style={{
                                            borderRadius: "8px",
                                            border: "1px #CBD5E1",
                                            width: "30rem"
                                        }}
                                    />
                                    <UpdateButton style={{ marginLeft: "1rem" }}
                                        data-test-id="update-password-button"
                                        onClick={() => this.handleOpenUpdateModal('password')}
                                    >Update Password</UpdateButton>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="column" mt={4}>
                                <DeleteButton onClick={() => this.handleOpenModal('delete')}
                                    data-test-id="delete-account-button"
                                >Delete Account</DeleteButton>
                                <LogoutButton style={{ marginTop: "1.5rem" }}
                                    data-test-id="logout-button"
                                    onClick={() => this.handleOpenModal('logout')}
                                >Logout</LogoutButton>
                            </Box>
                        </Box>
                    </Box>
                </BookingWrapper>
                <ConfirmationModal
                    openConfirmationModal={openConfirmationModal}
                    setOpenConfirmationModal={this.setOpenConfirmationModal}
                    modalConfig={this.state.modalConfig}
                    navigation={undefined}
                    id={""}
                />
                <UpdateDetailsModal
                    openUpdateModal={this.state.openUpdateModal}
                    setOpenUpdateModal={this.handleCloseUpdateModal}
                    updateType={this.state.updateType}
                    navigation={undefined}
                    onEmailUpdate={this.handleEmailUpdate}
                    id={""}
                />
            </Box>
        );
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

}
// Customizable Area Start
export default CutomerSettings;
// Customizable Area End


