import React from 'react';
import { imgStar } from './assets';

export function getStars(avgReview: number | null) {
    return avgReview ? Array.from(Array(Math.round(avgReview))).map((item, index) =>
        <img key={index} src={imgStar} alt='image star' />
    ) : ''
}

export const isPlural = (amount: number, word: string, ending = "s") =>
  amount === 1 ? word : word + ending;
