import React from "react";

import {
  Box,
  Button,
  Typography,
  CircularProgress
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styles, TypographyView } from './styledFont';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { BreadCrumbs } from '../../../components/src/BreadCrumbs.web';
import { AppFooter } from '../../../components/src/AppFooter.web';
// Customizable Area End

import SubscriptionbillingController, { Props, SubscriptionTime } from "./SubscriptionbillingController";
import SubscriptionBlock from './components/SubscriptionBlock.web';

export default class SubscriptionbillingPlans extends SubscriptionbillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getSubscriptionPlans();
  }
  // Customizable Area End

  render() {
    const subscriptionPlans = this.state.subscriptionPlans;
    return (
      // Customizable Area Start
      <TypographyView>
        <Box style={styles.SafeAreaView} className='landing-venue-block'>
          <AppHeader />
          <Box className='search-location-block' pt={6} pb={10} px='140px'>
            <BreadCrumbs items={['Home', 'Spa Profile', 'My Subscription', 'Revise Subscription Plans']} targetColor='#398378' />
            <Typography
              style={{
                color: '#0F172A',
                fontFamily: 'Ubuntu',
                fontSize: '48px',
                fontWeight: 700,
                padding: '30px 0'
              }}
            >Subscription Plans</Typography>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. . Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. .
            </Typography>
            {/* Plan modes buttons */}
            <Box display='flex' my={3} gridGap={14} justifyContent='space-between'>
              <Button
                data-test-id='monthly-btn'
                variant="contained"
                color="primary"
                style={{
                  ...styles.actionButton,
                  ...styles.modeButton,
                  ...this.getRenewButtonStyle(!this.state.annualMode),
                } as React.CSSProperties}
                onClick={() => this.setAnnualMode(false)}
              >
                Monthly Plan
              </Button>
              <Button
                data-test-id='annual-btn'
                variant="contained"
                color="primary"
                style={{
                  ...styles.actionButton,
                  ...styles.modeButton,
                  ...this.getRenewButtonStyle(this.state.annualMode)
                } as React.CSSProperties}
                onClick={() => this.setAnnualMode(true)}
              >
                Annual Plan
              </Button>
            </Box>
            {/* Plans Block */}
            <Box
              display='flex'
              gridGap={16}
              mt={6}
              pr={2}
            >
              {subscriptionPlans.length ? subscriptionPlans.map((plan) => {
                const {
                  id,
                  name: title,
                  description,
                  price,
                  monthly_price: monthlyPrice,
                  symbol,
                  image_url: imageUrl,
                  parse_content: parsedDescrList
                } = plan;

                const type = title.includes('Gold') ? 'Advanced' : 'Basic'

                return <SubscriptionBlock
                  key={title}
                  navigation={this.props.navigation}
                  id=''
                  subsContent={{
                    title,
                    imageUrl,
                    currancy: symbol,
                    price,
                    monthlyPrice,
                    period: this.state.annualMode ? SubscriptionTime.year : SubscriptionTime.month,
                    description,
                    parsedDescrList
                  }}
                  subscriptionButtons={
                    <Button
                      data-test-id='buy-btn'
                      variant="contained"
                      color="primary"
                      style={{
                        ...styles.actionButton,
                        background: '#398378',
                        color: '#FFFFFF',
                        padding: '8px 32px'
                      } as React.CSSProperties}
                      onClick={() => this.gotoSubscriptionPayment(id, title, type)}
                    >
                      Buy
                    </Button>
                  }
                />
              }) :
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '42vh',
                  width: '100vw'
                }}>
                  <CircularProgress style={{ color: '#398378' }} />
                </Box>
              }
            </Box>
          </Box>
          {/* footer */}
          <AppFooter navigation={this.props.navigation} />
        </Box>
      </TypographyView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
