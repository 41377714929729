Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.userDetailsAPIEndPoint = "account_block/get_user_details";
exports.userSpaNameAPIEndPoint = "account_block/update_spa_owner_details";
exports.deleteSpaLicenseandCertificate = "account_block/delete_attachment";
exports.customerBookingDetailsAPI = "bx_block_calendar/customer_bookings";
exports.updateProfilePicEndPoint = "account_block/update_profile";
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch =
  "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.txtAddOtherFiles = "Add Files";
exports.cityErrorMsg = "Please select your city";
exports.stateErrorMsg = "Please select your state";
exports.apiErrorMessage = "There is an error, try later, please";
exports.txtBrowseFile = "Browse file to upload";
exports.edgeInterval = 100;

exports.categoryApiEndPoint = "bx_block_categories/categories";
exports.regionApiEndPoint = "account_block/get_regions";
exports.productApiUpdateEndPoint = "bx_block_catalogue/update";
exports.productApiEndPoint = "bx_block_catalogue/catalogues";
exports.userCatalogueAPIEndPoint = "bx_block_catalogue/user_catalogues";

exports.deleteApiMethod = "DELETE";
exports.deleteUserEndPoint = "account_block/accounts"

exports.cancelBookingAPI = "bx_block_calendar/cancel_booking"

exports.emptyServiceName = "Please enter the service name";
exports.emptyCategory = "Please enter the category";
exports.emptySubCategory = "Please enter sub category";
exports.emptyServiceDescription = "Please enter service description";
exports.emptyDuration = "Please enter duration";
exports.emptyPrice = "Please enter price";
exports.emptyDate = "Please choose date";
exports.emptyAvailableSlots = "Please choose slots";

exports.commonErrorText = "There is an error";
exports.commonErrorDescription = "Cannot retrieve data from server";

exports.EightCharacters = "Password must be at least 8 characters long"
exports.Contains = "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
exports.CanNotContains = "Password cannot contain three or more consecutive identical characters"
exports.IsCommon = "Password is too common"
exports.ContainsSequence = "Password cannot contain sequential characters"
// Customizable Area End

