import { Typography, styled } from '@material-ui/core';

export const TypographyView = styled(Typography)({
    '& *': {
        fontFamily: 'Ubuntu',
        fontWeight: 400,
    },
    '& .venue-carousel': {
        position: 'relative'
    },
    '& .carousel__dot': {
        borderRadius: '50%',
        border: 'none',
        padding: 0
    },
    '& .carousel__dot--selected': {
        backgroundColor: '#398378'
    },
    '& .MuiButton-root': {
        textTransform: 'none'
    },
    '& .MuiButton-label': {
        fontWeight: 700
    },
    '& .venue-service-carousel button': {
        border: 'none',
        background: 'none'
    },
    '& .venue-service-carousel button[disabled] div': {
        background: '#CBD5E1'
    },
    '& .venue-service-carousel button div': {
        background: '#398378'
    },
    '& .venue-service-carousel': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    '& .buttons-carosel-tray': {
        width: '100%'
    }
})
