import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
import { Country, State, City, ICountry, IState, ICity } from 'country-state-city';
import React from 'react';
import DateObject from "react-date-object";
import { getFormDataFromPayload } from '../../../blocks/utilities/src/handle-form';
import { incrementRandomNumber } from '../../../blocks/utilities/src/handle-calc';
import { clockClosed, clockOpened, clockWait } from './assets';
import { Catalogue } from "./EditServiceController.web";
import {
  addZeroToTime,
  calculateSlots,
} from "../../../components/src/datesRangeHelper";

// Fix object for duration picker minutes steps to show the range with each step as 10 minutes
const durationConversion = {
  33: '00',
  34: '10',
  0o2: '20',
  0o3: '30',
  0o4: '40',
  32: '50'
};

export const getNavigationMessage = (route: string, props: any): Message => {

  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), route);

  message.addData(getName(MessageEnum.NavigationPropsMessage), props);

  return message;
}

export const callApi = (payload: any, runEngine: any) => {
  const { contentType, method, endPoint, body, headers } = payload;
  const header = {
    "Content-Type": contentType,
    ...headers
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
}

type DurationPickerObjInit = {
  hours?: number;
  minutes?: number;
  marker?: string;
};

type DurationPickerObjConv = {
  hours?: string;
  minutes?: string;
  marker?: string;
};

export type SubCategory = {
  subcategory: {
    id: number,
    name: string
  },
  slots: {
    id: number,
    duration: string,
    price: string,
    start_time: string,
    end_time: string
  }[]
};

export type CategorySlot = {
  id: number;
  catalogueId: number;
  serviceName: string;
  categoryName: string;
  capacity: number;
  date: string[];
  slots: string[];
  slotData: {
    id: number;
    duration: string;
  };
};

export type PopoverAnchor = 'anchorElLicence' | 'anchorElCertificate' | 'anchorElOtherFiles';

export type FileWithId = (File & { id?: string });
export type ImgObj = {
  id: number;
  url: string;
};

type APITypesKeys = 'licenses' | 'certificates' | 'carousal_images';

type TimeSlot = {
  start_time: string;
  end_time: string;
  date: string[];
  status: string;
  same_timing: string;
};

const zeroes = '00';

type Nullable<T> = T | null | undefined;

interface UserDetails {
  id: string;
  attributes: {
    full_name: string;
    open_timing: string;
    spa_timings: TimeSlot[];
    gender: string;
    phone_number: number | undefined;
    email: string;
    country_code: number | undefined;
    about: string;
    about_us: string;
    profile_data: {
      attributes: {
        address: string,
        photo: string,
        country: string,
        city: string,
        postal_code: string
        bio: string
      }
    }
    services: {
      subcategories_with_slots: SubCategory[]
    }[],
    reviews: {
      name: string;
      review: string;
      comment: string;
      created_at: string;
    }[];
    image_url: string;
    rating: string;
    licenses: any;
    certificates: any;
    carousal_images: {
      id: number,
      url: string
    }[]
  }
}

const initialUserDetails: UserDetails = {
  id: "",
  attributes: {
    full_name: "",
    open_timing: "",
    spa_timings: [],
    gender: "",
    phone_number: 0,
    email: "",
    country_code: 0,
    about: "",
    about_us: "",
    profile_data: {
      attributes: {
        address: "",
        photo: "",
        country: "",
        city: "",
        postal_code: "",
        bio: ""
      },
    },
    services: [],
    reviews: [],
    image_url: "",
    rating: "",
    licenses: null,
    certificates: null,
    carousal_images: [],
  },
};
export const spaTimingSlots = [
  { label: "8:00 am - 5:00 pm" },
  { label: "9:30 am - 7:00 pm" },
  { label: "10:00 am - 5:30 pm" },
  { label: "10:00 am - 7:00 pm" },
];

type StateUpdateMap = {
  [key in S['buttonMode']]: Partial<S>;
};

export type CategorySlotsError = {
  date: string;
  duration: string;
  slots: string;
  slotData: {
    duration: string;
  };
};

const initDurationPicker = {
  durationFrom: { hours: zeroes, minutes: zeroes, marker: "am" },
  durationTo: { hours: zeroes, minutes: zeroes, marker: "pm" },
  hasStarted: false,
};
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openCancelModal?: boolean;
  setOpenCancelModal?: (arg: boolean) => void;
  setBookedID?: (id: number) => void;
  openConfirmationModal?: boolean;
  setOpenConfirmationModal?: (arg: boolean) => void;
  openUpdateModal?: boolean;
  setOpenUpdateModal?: (arg: boolean) => void;
  modalConfig?: {
    title: string,
    message: string,
    confirmText: string,
    cancelText: string,
    onConfirm: () => void,
  },
  updateType?: string;
  onEmailUpdate?: (newEmail: string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  buttonIndex: number,
  lastName: any;
  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  services: Catalogue[];
  datesRange: any[];
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  avatarImage: any;
  upldAvatarLink: string | ArrayBuffer | null,
  backgroundImgLink: any;
  fullname: string;
  spaAddress: string;
  isOpen: any;
  updateProfilePopup: boolean;
  anchorEl: any;
  anchorElLicence: any[];
  anchorElCertificate: any[];
  anchorElOtherFiles: any[];
  anchorElSlots: HTMLButtonElement | null;
  currentSlotIndex: number;
  uploadImg: any;
  isSpaNameReviewPopup: boolean;
  isLicenseCertificationPopup: boolean;
  isSpaTimingPopup: boolean;
  isEditLocationPopup: boolean;
  fileError: any;
  certificateError: string;
  businessLicenseError: string;
  businessLicenseImage: (File & { id: string })[],
  certificateImage: File[],
  otherImages: File[],
  addDisabled: boolean,
  files: any;
  countries: ICountry[],
  states: IState[],
  cities: ICity[],
  countryCode: string,
  country: string;
  city: string;
  stateValue: string;
  spaName: string;
  spaNameError: boolean;
  address: string;
  addressError: boolean;
  webLink: string;
  countryError: boolean;
  stateError: boolean;
  cityError: boolean;
  venueCarouselIndex: any;
  updatedSpaTiming: string;
  spaTodayStatus: string;
  uploadProgress: number;
  progressBars: number[];
  uploading?: any;
  certificateUpload?: any;
  newLicenseUploadFiles: File[]
  progress: number;
  addPrefferTiming: boolean;
  carouselLoading: boolean;
  loading: boolean;
  aboutUs: string;
  sameTimeChecked: boolean;
  durationPicker: {
    durationFrom: DurationPickerObjConv,
    durationTo: DurationPickerObjConv,
    hasStarted: boolean
  },
  isDurationFrom: boolean;
  showCalendarPopUp: boolean;
  openCalendar: boolean;
  openingDates: string;
  openingDatesList: string[];
  apiError: boolean,
  idsToRemoveFiles: {
    licenses: string[],
    certificates: string[],
    carousal_images: string[]
  }
  isCustomer: boolean
  isEditMode: boolean
  userDetails: UserDetails;
  editedDetails: UserDetails['attributes'];
  value: string | undefined;
  buttonMode: string;
  openCancelModal?: boolean;
  openConfirmationModal: boolean;
  openUpdateModal: boolean;
  cancelModalServiceName: string | undefined;
  bookedID: number | undefined;
  bookedBookings: any[];
  completedBookings: any[];
  cancelledBookings: any[];
  count: {
    booked: number;
    cancelled: number;
    completed: number;
  };
  modalConfig: {
    title: string,
    message: string,
    confirmText: string,
    cancelText: string,
    onConfirm: () => void,
  },
  updateType?: string;
  newValue: string
  confirmValue: string
  showSnack: boolean;
  snackMessage: string;
  emailError: string | null;
  passwordError: string | null;
  editMessage: string;

  timeSlots: string[][];
  selectedSlotIndexes: number[][];
  slotDates: any;
  anchorDuration: HTMLElement | null,
  selectedServiceIndex: number,
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  updateProfileApiCallId: any;
  userSpaNameAPICallId: string = "";
  userAttr: any;
  apiGetUserDetailsCallId: string = ""
  deleteDocumentApiCallId: string = "";
  licenseTxt = 'License';
  certTxt = 'Certificates';
  otherFilesTxt = 'Other files';
  apiGetCustomerDetailsCallId = ""
  deleteUserApiCallId = ""
  apiUpdateBookingCallId = ""
  apiGetCataloguesCallId = "";
  apiAvailabilityCallId = "";
  private _isMounted: boolean = false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      firstName: "",
      buttonIndex: 0,
      lastName: "",
      email: "",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      services: [],
      datesRange: Array.from(new Array(50), () => []),
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",
      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      backgroundImgLink: null,
      avatarImage: null,
      upldAvatarLink: null,
      fullname: "",
      spaAddress: "",
      isOpen: null,
      updateProfilePopup: false,
      anchorEl: false,
      anchorElLicence: [],
      anchorElCertificate: [],
      anchorElOtherFiles: [],
      anchorElSlots: null,
      currentSlotIndex: 0,
      uploadImg: null,
      isSpaNameReviewPopup: false,
      isLicenseCertificationPopup: false,
      isSpaTimingPopup: false,
      isEditLocationPopup: false,
      fileError: "",
      certificateError: "",
      businessLicenseError: "",
      businessLicenseImage: [],
      certificateImage: [],
      otherImages: [],
      addDisabled: false,
      files: [],
      countries: Country.getAllCountries(),
      states: [],
      cities: [],
      countryCode: "",
      country: "",
      city: "",
      stateValue: "",
      spaNameError: false,
      spaName: '',
      address: '',
      addressError: false,
      webLink: '',
      countryError: false,
      stateError: false,
      cityError: false,
      venueCarouselIndex: 0,
      updatedSpaTiming: '',
      spaTodayStatus: 'OPEN',
      uploadProgress: 0,
      uploading: false,
      certificateUpload: false,
      newLicenseUploadFiles: [],
      progress: 0,
      addPrefferTiming: false,
      loading: false,
      carouselLoading: false,
      aboutUs: '',
      sameTimeChecked: false,
      durationPicker: initDurationPicker,
      isDurationFrom: true,
      showCalendarPopUp: true,
      openCalendar: false,
      openingDates: '',
      openingDatesList: [],
      apiError: false,
      idsToRemoveFiles: {
        licenses: [],
        certificates: [],
        carousal_images: []
      },
      progressBars: [],
      isCustomer: false,
      isEditMode: false,
      userDetails: { ...initialUserDetails },
      editedDetails: { ...initialUserDetails.attributes },
      value: undefined,
      buttonMode: 'completed',
      openCancelModal: false,
      openConfirmationModal: false,
      openUpdateModal: false,
      cancelModalServiceName: "",
      bookedID: 0,
      bookedBookings: [],
      completedBookings: [],
      cancelledBookings: [],
      count: { booked: 0, cancelled: 0, completed: 0 },
      modalConfig: {
        title: '',
        message: '',
        confirmText: '',
        cancelText: '',
        onConfirm: () => { },
      },
      updateType: undefined,
      newValue: "",
      confirmValue: "",
      showSnack: false,
      snackMessage: '',
      emailError: null,
      passwordError: null,
      editMessage: "",

      timeSlots: [],
      selectedSlotIndexes: [],
      slotDates: [],
      anchorDuration: null,
      selectedServiceIndex: -1,
    };

    this.handleEdit = this.handleEdit.bind(this);

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    const navigationPayloadMessage = message.getData(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    if (navigationPayloadMessage) {
      this.setState({ editMessage: navigationPayloadMessage.editMessage });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorReponse || !responseJson || responseJson.errors) {
        const errorMessage = this.getErrorMessage(errorReponse, responseJson)
        this.handleErrorMessage(errorMessage)
        this.setState({ loading: false })
        return;
      }

      if (this.getCombinedLogicalValue(!!responseJson, apiRequestCallId === this.apiGetCustomerDetailsCallId)) {
        const { data } = responseJson;
        const { booking_data, count } = data;

        const stateUpdateMap: Partial<Record<S['buttonMode'], Partial<S>>> = {
          completed: { completedBookings: booking_data, count },
          booked: { bookedBookings: booking_data },
          cancelled: { cancelledBookings: booking_data }
        };

        const mode = this.state.buttonMode;
        const update = stateUpdateMap[mode] || {};

        this.setState(prevState => ({
          ...prevState,
          ...update
        }));
      }

      if (this.getCombinedLogicalValue(!!responseJson.data, !!responseJson.data?.id)) {
        if (apiRequestCallId === this.apiUpdateBookingCallId) {
          this.onCancelBooking(responseJson.data.id);
        }
      }

      if (apiRequestCallId) {
        switch (apiRequestCallId) {
          case this.apiGetUserDetailsCallId:
            this.handleUserProfileInformation(responseJson)
            break;
          case this.apiGetCataloguesCallId:
            this.handleCataloguesInfo(responseJson);
            break
          case this.updateProfileApiCallId:
            this.handleProfileUpdateResponse(responseJson)
            break;
          case this.userSpaNameAPICallId:
            this.handleSpaNameUpdateApiResponse(responseJson)
            break;
          case this.deleteDocumentApiCallId:
            this.handleDeleteDocumentApiResponse(responseJson);
            break;
          case this.apiAvailabilityCallId:
            this.handleSuccessUpdateAvailability();
            break;
        }
      }
    }
    // Customizable Area End
  }

  validateMobileAndThenUpdateUserProfile() {
    let countryCode: any = this.state.currentCountryCode;
    let mobileNo: any = this.state.phoneNumber;

    let error: any = "";

    error = this.validateCountryCodeAndPhoneNumber(countryCode, mobileNo);

    if (error) {
      this.showAlert(configJSON.errorTitle, error);

      return;
    }

    if (this.userAttr) {
      const countryCodeOld = this.userAttr.country_code;
      const mobileNoOld = this.userAttr.phone_number;

      if (
        Number.parseInt(countryCode) === Number.parseInt(countryCodeOld) ||
        countryCode === configJSON.hintCountryCode
      ) {
        countryCode = null;
      }

      if (
        Number.parseInt(this.state.phoneNumber) === Number.parseInt(mobileNoOld)
      ) {
        mobileNo = null;
      }
    }

    if (mobileNo && countryCode) {
      this.validateMobileOnServer(
        this.state.currentCountryCode,
        this.state.phoneNumber
      );
    } else {
      this.validateAndUpdateProfile();
    }
  }

  validateEmail(email: string) {
    let error = null;

    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }

    return error;
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (
        !this.isNonNullAndEmpty(String(countryCode)) ||
        configJSON.hintCountryCode === countryCode
      ) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (
      this.isNonNullAndEmpty(countryCode) &&
      configJSON.hintCountryCode !== countryCode
    ) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  validateAndUpdateProfile() {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let countryCode: any = this.state.currentCountryCode;

    let mobileNo = this.state.phoneNumber;
    let email = this.state.email;

    let currentPwd = this.state.currentPasswordText;
    let newPwd = this.state.newPasswordText;
    let reTypePwd = this.state.reTypePasswordText;

    const errorFirstName = this.validateFirstName(firstName);
    const errorLastName = this.validateLastName(lastName);

    const errorMobileNo = this.validateCountryCodeAndPhoneNumber(
      countryCode,
      mobileNo
    );
    const errorEmail = this.validateEmail(email);

    const errorCurrentPwd = this.validateCurrentPwd(currentPwd);
    const errorNewPwd = this.validatePassword(newPwd);
    const errorRetypePwd = this.validateRePassword(reTypePwd);

    let isValidForSignUp: boolean = true;

    if (errorFirstName != null) {
      this.showAlert(configJSON.errorTitle, errorFirstName);
      return false;
    } else if (errorLastName != null) {
      this.showAlert(configJSON.errorTitle, errorLastName);
      return false;
    }

    if (configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType) {
      if (errorMobileNo !== null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    } else {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);

        return false;
      } else if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    }

    if (
      configJSON.ACCOUNT_TYPE_SOCIAL !== this.registrationAndLoginType &&
      this.state.llDoChangePwdContainerVisible
    ) {
      if (errorCurrentPwd != null) {
        this.showAlert(configJSON.errorTitle, errorCurrentPwd);
        return false;
      } else if (errorNewPwd != null) {
        this.showAlert(configJSON.errorTitle, errorNewPwd);
        return false;
      } else if (errorRetypePwd != null) {
        this.showAlert(configJSON.errorTitle, errorRetypePwd);
        return false;
      } else if (newPwd !== reTypePwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorBothPasswordsNotSame
        );
        return false;
      } else if (currentPwd === newPwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorCurrentNewPasswordMatch
        );
        return false;
      }
    }

    //Call update API
    if (this.userAttr) {
      let firstNameOld = this.userAttr.first_name;
      let lastNameOld = this.userAttr.last_name;
      let countryCodeOld = this.userAttr.country_code + "";
      let mobileNoOld = this.userAttr.phone_number + "";
      let emailOld = this.userAttr.email;
      this.registrationAndLoginType = this.userAttr.type;

      if (this.isNonNullAndEmpty(firstName) && firstName === firstNameOld) {
        firstName = null;
      }

      if (this.isNonNullAndEmpty(lastName) && lastName === lastNameOld) {
        lastName = null;
      }

      if (
        this.isNonNullAndEmpty(countryCode) &&
        countryCode === countryCodeOld
      ) {
        countryCode = null;
      }

      if (this.isNonNullAndEmpty(mobileNo) && mobileNo === mobileNoOld) {
        mobileNo = null;
      }

      if (countryCode != null || mobileNo != null) {
        if (countryCode == null) {
          countryCode = countryCodeOld;
        }

        if (mobileNo == null) {
          mobileNo = mobileNoOld;
        }
      }

      if (this.isNonNullAndEmpty(email) && email === emailOld) {
        email = null;
      }
    }

    if (
      this.isNonNullAndEmpty(firstName) ||
      this.isNonNullAndEmpty(lastName) ||
      this.isNonNullAndEmpty(countryCode) ||
      this.isNonNullAndEmpty(mobileNo) ||
      this.isNonNullAndEmpty(email) ||
      (this.isNonNullAndEmpty(currentPwd) && this.isNonNullAndEmpty(newPwd))
    ) {
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        token: this.authToken
      };

      let data: any = {
        first_name: this.state.firstName,
        last_name: this.state.lastName
      };

      if (this.state.edtMobileNoEnabled) {
        if (
          configJSON.hintCountryCode !== countryCode &&
          this.isNonNullAndEmpty(String(countryCode)) &&
          this.isNonNullAndEmpty(String(mobileNo))
        ) {
          data = {
            ...data,
            ...{ new_phone_number: String(countryCode) + String(mobileNo) }
          };
        }
      }

      if (this.isNonNullAndEmpty(email)) {
        data = { ...data, ...{ new_email: email } };
      }

      if (
        this.isNonNullAndEmpty(currentPwd) &&
        this.isNonNullAndEmpty(newPwd)
      ) {
        data = {
          ...data,
          ...{ current_password: currentPwd, new_password: newPwd }
        };
      }

      const httpBody = {
        data: data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateUser
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: this.authToken
    };

    const data = {
      new_phone_number: countryCode + mobileNo
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: ""
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }

  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area Start
  async componentDidMount() {
    this._isMounted = true;
    this.getSpaUserProfile()
    this.getCalagoues();
    this.getCutomerBookingDetails(this.state.buttonMode)

    const userRole = await getStorageData('userRole');
    if (this._isMounted) {
      this.setState({ isCustomer: userRole === 'Customer' });
    }
  }

  async componentWillUnmount(): Promise<void> {
    this._isMounted = false;
  }


  getErrorMessage(errResponse: any, responseJson: any) {
    if (errResponse) {
      return errResponse
    } else if (responseJson && responseJson.error && responseJson.error.length > 0) {
      return responseJson.errors[0].message;
    } else {
      return '';
    }
  }

  handleErrorMessage(errorMessage: string) {
    if (errorMessage === "No booked slots found.") {
      this.setState({
        bookedBookings: [],
        completedBookings: [],
        cancelledBookings: [],
        count: { booked: 0, completed: 0, cancelled: 0 },
        apiError: false
      });
    } else {
      this.parseApiCatchErrorResponse(errorMessage);
      this.setState({ apiError: true });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.buttonMode !== this.state.buttonMode) {
      this.getCutomerBookingDetails(this.state.buttonMode);
    }
  }

  setTimeSlots = (timeSlots: string[], index: number) => {
    const timeSlotsCopy = this.state.timeSlots;
    timeSlotsCopy[index] = timeSlots;
    this.setState({ timeSlots: timeSlotsCopy });
  };

  generateTimeSlots = (
    slotDuration: string,
    index: number,
    timeslots?: string[]
  ) => {
    const timeStrings = calculateSlots(
      "10:00 am - 06:00 pm",
      slotDuration,
      timeslots
    ).map((timeObject) => timeObject.time);
    this.setTimeSlots(timeStrings, index);
    return timeStrings;
  };

  setSelectedSlotIndexes = (indexes: number[], slotIndex: number) => {
    const copySelectedIndexes = this.state.selectedSlotIndexes;
    copySelectedIndexes[slotIndex] = indexes;
    this.setState({ selectedSlotIndexes: copySelectedIndexes });
  };

  pushOrRemoveIndex = (targetArray: any[], item: string | number) => {
    const slotIdentIndex = targetArray.indexOf(item as never);
    const targetListCopy = [...targetArray];
    ~slotIdentIndex
      ? targetListCopy.splice(slotIdentIndex, 1)
      : targetListCopy.push(item as never);
    return targetListCopy;
  };

  setSlotDates = (slotDates: any, index: number) => {
    const copySlotDates = this.state.slotDates;
    copySlotDates[index] = slotDates;
    this.setState({ slotDates: copySlotDates });
  };

  getInputError = (isTouched?: boolean, error?: string) =>
    !!(isTouched && error);

  getErrorBorder = (isError: boolean) =>
    isError ? { border: "1px solid #DC2626" } : {};

  getFullDate = (multiDateObj: any) => {
    const { year, month, day } = multiDateObj;
    return `${year}-${addZeroToTime(Number(month))}-${addZeroToTime(
      Number(day)
    )}`;
  };

  convertDateToIso = (date: Date) => date.toISOString().split("T")[0];

  setDates = (dates: DateObject[][], callback: (dates: string[]) => void) => {
    const oneDayMs = 86400000;
    const allDatesRange: string[] = [];
    Array.isArray(dates) &&
      dates.forEach((dateObject: any) => {
        if (dateObject.length) {
          const beginDate = new Date(this.getFullDate(dateObject[0]));

          allDatesRange.push(this.convertDateToIso(beginDate));

          if (dateObject.length - 1) {
            const endDate = new Date(this.getFullDate(dateObject[1]));
            const datesRange =
              ((endDate as any) - (beginDate as any) + oneDayMs) / oneDayMs;

            Array.from(Array(datesRange)).forEach((_, index) => {
              const beginDataCopy = new Date(beginDate);
              beginDataCopy.setDate(beginDataCopy.getDate() + index);
              allDatesRange.push(this.convertDateToIso(beginDataCopy));
            });
          }
        }
      });

    const openingDatesList = this.getUniqArray(allDatesRange).sort();
    callback(openingDatesList);
  };

  getAutocompleteStyle = (isError: boolean) => ({
    padding: "12px 16px",
    borderRadius: "8px",
    minHeight: "56px",
    ...(isError && { border: "1px solid red" }),
  });

  getHighlightSlotStyle = (index: number, slotIndex: number) =>
    this.state.selectedSlotIndexes[index]?.includes(slotIndex)
      ? {
        backgroundColor: "#398378ff",
        color: "#ffffff",
      }
      : {};

  handleOpenDurationPopOver = (event: React.MouseEvent<HTMLElement>, index: number) =>
    this.setState({ anchorDuration: event.currentTarget, selectedServiceIndex: index });

  setAmPmColor = (markerExp: string, durationFromExp: boolean) => {
    const { durationFrom, durationTo } = this.state.durationPicker;
    const isFrom = durationFromExp && durationFrom.marker === markerExp;
    const isTo = !durationFromExp && durationTo.marker === markerExp;
    return isFrom || isTo ? "#398378" : "#CBD5E1";
  };

  setTimeMarker = (marker: string, durationFrom: boolean) => {
    const durPicker = this.state.durationPicker;
    const resDuration = durationFrom
      ? {
        durationFrom: {
          ...durPicker.durationFrom,
          marker,
        },
      }
      : {
        durationTo: {
          ...durPicker.durationTo,
          marker,
        },
      };

    this.setState({
      durationPicker: {
        ...durPicker,
        ...resDuration,
      },
    });
  };

  handleTimeSlots = () => {
    const timeSlotsCopy = this.state.timeSlots;
    const index = this.state.selectedServiceIndex
    timeSlotsCopy[index] = [...timeSlotsCopy[index], this.getDuration()];
    this.setState({
      timeSlots: timeSlotsCopy,
      anchorDuration: null,
      durationPicker: initDurationPicker,
      isDurationFrom: true,
      selectedServiceIndex: -1
    });
  };

  handleCloseDurationPopOver = () => this.setState({ anchorDuration: null, selectedServiceIndex: -1 });

  handleSuccessUpdateAvailability() {
    this.handleNavigationToBasicInformation("SpaUserProfile")
  }

  getSpaUserProfile = async () => {
    this.setState({ loading: true, carouselLoading: true });

    const user_id = await getStorageData("user_id")
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    this.apiGetUserDetailsCallId = callApi(
      {
        contentType: configJSON.validationApiContentType,
        method: configJSON.methodTypeApiGetUserProfile,
        endPoint: `${configJSON.userDetailsAPIEndPoint}?id=${user_id}`,
        headers: headers
      },
      runEngine
    )

  }

  getCalagoues = async () => {
    this.setState({ loading: true });
    const authToken = await getStorageData("authToken");

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };

    this.apiGetCataloguesCallId = callApi(
      {
        contentType: configJSON.validationApiContentType,
        method: configJSON.methodTypeApiGetUserProfile,
        endPoint: configJSON.userCatalogueAPIEndPoint,
        headers: headers,
      },
      runEngine
    );
  };

  handleUserProfileInformation = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({
        userDetails: responseJson.data,
        avatarImage: responseJson.data.attributes.image_url,
        businessLicenseImage: responseJson.data.attributes.licenses || [],
        certificateImage: responseJson.data.attributes.certificates || [],
        otherImages: responseJson.data.attributes.other_files || [],
      });
      setStorageData('avatarImageUrl', this.state.avatarImage);
      this.setState({
        carouselLoading: false
      });
    }
  }

  handleCataloguesInfo = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({
        services: responseJson.data,
        loading: false,
      });
    }
  };

  handleUpdateProfilePopup = () => {
    this.setState({
      updateProfilePopup: !this.state.updateProfilePopup
    })
  }

  onSuccessMessageClose = () => this.setState({ editMessage: "" })

  handleProfilePicUpload = async (event: any) => {
    const user_id = await getStorageData("user_id")
    const imageFiles = event.target.files[0];

    const fileReader = new FileReader();
    fileReader.addEventListener("load", async () => {
      this.setState({ avatarImage: fileReader.result, anchorEl: null });
    });
    fileReader.readAsDataURL(imageFiles);
    const APIPayload = {
      'account[profile_photo]': imageFiles,
      'account[profile][account_id]': user_id
    }
    await this.updatProfileApiCall(getFormDataFromPayload(APIPayload));
  }

  handleUploadImage = async (imageFiles: FileList | null) => {
    const user_id = await getStorageData("user_id")

    const APIPayload = {
      'account[carousal_images][]': Array.from(imageFiles || []),
      'account[profile][account_id]': user_id
    }
    this.updatProfileApiCall(getFormDataFromPayload(APIPayload));
    this.setState({ carouselLoading: true });
  }
  handleRemoveProfilePic = async () => {
    const user_id = await getStorageData("user_id")
    this.setState({ avatarImage: null, anchorEl: null });
    const APIPayload = {
      'account[profile_photo]': null,
      'account[profile][account_id]': user_id
    }
    this.updatProfileApiCall(getFormDataFromPayload(APIPayload))
  }

  handleSpaNameUpdateApiResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({ updateProfilePopup: false, isSpaNameReviewPopup: true })
    }
  }
  handleProfileUpdateResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({
        uploadImg: false,
        isEditLocationPopup: false
      });
      this.getSpaUserProfile();
    }
  }
  handleDeleteDocumentApiResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({
        anchorElLicence: [],
        anchorElCertificate: [],
      })
      this.getSpaUserProfile();
    }
  }

  handleNavigationToEditService = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEditSpaService)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  updatProfileApiCall = async (formData: any) => {
    const authToken = await getStorageData('authToken');
    const headers = {
      "token": authToken,
    };
    this.updateProfileApiCallId = callApi(
      {
        contentType: configJSON.apiContentType,
        method: "POST",
        endPoint: configJSON.updateProfilePicEndPoint,
        headers: headers,
        body: formData
      },
      runEngine
    )
  }

  handleSpaReviewPopupClose = () => {
    this.setState({ isSpaNameReviewPopup: false })
  }
  handleNavigationToBasicInformation = (route: string) => {
    this.send(getNavigationMessage(route, this.props))
  }

  openDocumentsUpload = () => this.setState({ isLicenseCertificationPopup: true });

  handleCloseLicenseUploadPopup = () => {
    this.setState({
      idsToRemoveFiles: {
        licenses: [],
        certificates: [],
        carousal_images: []
      },
      progressBars: [],
      isLicenseCertificationPopup: false
    })
  }

  onSliderClick(event: React.MouseEvent<HTMLElement>, backBtnRef: HTMLElement, nextBtnRef: HTMLElement) {
    const isLeftClick = event.screenX < window.innerWidth / 2;
    const targetRef = isLeftClick ? backBtnRef : nextBtnRef;
    targetRef.click();

    const carouslImagesLength = this.state.userDetails?.attributes.carousal_images.length;

    const currentIndex = this.state.venueCarouselIndex;
    const prevSlide = currentIndex > 0 && currentIndex - 1;
    const nextSlide = currentIndex + Number(currentIndex < Number(carouslImagesLength) - 1);

    this.setState({ venueCarouselIndex: Number(isLeftClick ? prevSlide : nextSlide) });
  };

  handleFileChange = (event: any) => {
    const fileList = event.target.files;
    this.setState(prevState => ({
      businessLicenseImage: [...prevState.businessLicenseImage, ...fileList],
      businessLicenseError: ''
    }));

  };

  onSelectCountry = (event: any) => {
    const countryName = event.target.textContent;
    const countryCode = this.state.countries.find((country) => country.name === countryName)?.isoCode || '';
    const states = State.getStatesOfCountry(countryCode);
    this.setState({
      country: countryName,
      countryError: false,
      states,
      stateValue: '',
      city: '',
      countryCode
    });
  }

  onSelectCountryAndCities = (event: any) => {
    const countryName = event.target.textContent;
    const countryCode = this.state.countries.find((country) => country.name === countryName)?.isoCode || '';
    const cities = City.getCitiesOfCountry(countryCode) || [];

    this.setState({
      country: countryName,
      countryError: false,
      states: [],
      stateValue: '',
      city: '',
      countryCode,
      cities
    });
  }

  getDisplayCountry = () => {
    if (this.state.isEditMode) {
      return this.state.country || '';
    } else {
      return this.state.userDetails?.attributes?.profile_data?.attributes?.country || '';
    }
  }

  getDisplayCity = () => {
    if (this.state.isEditMode) {
      return this.state.city || '';
    } else {
      return this.state.userDetails?.attributes?.profile_data?.attributes?.city || '';
    }
  }

  onSelectState = (event: any) => {
    const stateName = event.target.textContent;
    const stateObj = this.state.states.find((state) => state.name === stateName);
    const { countryCode, isoCode } = stateObj || { countryCode: '', isoCode: '' };

    const cities = City.getCitiesOfState(countryCode, isoCode);
    const allCountryCities: any = City.getCitiesOfCountry(this.state.countryCode);

    const resultCities = (cities.length && cities) || allCountryCities;

    this.setState({
      stateValue: event.target.textContent,
      stateError: false,
      cities: resultCities,
      city: ''
    });
  }

  onSelectCity = (event: any) => {
    this.setState({ city: event.target.textContent, cityError: false })
  }
  getStateOptions() {
    return this.state.states.length ? this.state.states.map((state) => state.name) : ['Default']
  }

  getCityOptions() {
    return this.state.cities.length ? this.state.cities.map((city) => city.name) : ['Default']
  }

  setAddress = (event: any) => {
    this.setState({ spaAddress: event.target.value, addressError: false })

  }

  getTruthyValue(firstValue: UserDetails['attributes'] | undefined): UserDetails {
    return {
      id: "",
      attributes: firstValue || initialUserDetails.attributes,
    };
  }

  getTruthyValueOrDefault<T>(value: T | undefined | null, defaultValue: T): T {
    return value !== undefined && value !== null ? value : defaultValue;
  }

  getLogicalValue(firstValue: boolean, secondValue: React.ReactNode): React.ReactNode | null {
    return firstValue ? secondValue : null;
  }

  getTernaryValue<T>(condition: boolean, firstValue: T, secondValue: T): T {
    return condition ? firstValue : secondValue;
  }

  getCombinedLogicalValue(condition1: boolean, condition2: boolean): boolean {
    return condition1 && condition2;
  }

  getAllCombinedLogicalValues(...conditions: boolean[]): boolean {
    return conditions.every(condition => condition);
  }

  getEitherLogicalValue<T>(value1: Nullable<T>, value2: Nullable<T>): Nullable<T> {
    return value1 || value2;
  }

  setOpenCancelModal = (open?: boolean, bookingId?: number, service_name?: string) => {
    this.setState({
      openCancelModal: open,
      cancelModalServiceName: service_name,
      bookedID: bookingId
    });
  };

  setOpenConfirmationModal = (open: boolean, config = {}) => {
    this.setState({
      openConfirmationModal: open,
      modalConfig: { ...this.state.modalConfig, ...config },
    });
  };

  onCancelBooking = async (bookingId?: number) => {
    if (bookingId !== undefined) {
      await this.updateBookingStatus(bookingId);
      this.setState(prevState => {
        const updatedBookedBookings = prevState.bookedBookings.filter(booking => booking.id !== bookingId);
        const cancelledBooking = prevState.bookedBookings.find(booking => booking.id === bookingId);

        const updatedCancelledBookings = cancelledBooking
          ? [...prevState.cancelledBookings, cancelledBooking]
          : prevState.cancelledBookings;

        const updatedCount = {
          completed: prevState.count.completed,
          booked: prevState.count.booked - 1,
          cancelled: prevState.count.cancelled + 1,
        };

        return {
          bookedBookings: updatedBookedBookings,
          cancelledBookings: updatedCancelledBookings,
          count: updatedCount,
          openCancelModal: false,
        };
      });
    }
  };

  updateBookingStatus = async (bookingId?: number) => {
    const authToken = await getStorageData('authToken');
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateBookingCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.cancelBookingAPI}?id=${bookingId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );

    return new Promise((resolve, reject) => {
      try {
        runEngine.sendMessage(requestMessage.id, requestMessage);
        resolve(true);
      } catch (error) {
        reject(false);
      }
    });
  };

  gotoBookingDetails = (id: number) => {
    const { buttonMode, bookedBookings, completedBookings, cancelledBookings } = this.state;

    let currentBookings = [];
    switch (buttonMode) {
      case 'completed':
        currentBookings = completedBookings;
        break;
      case 'booked':
        currentBookings = bookedBookings;
        break;
      case 'cancelled':
        currentBookings = cancelledBookings;
        break;
      default:
        break;
    }

    const bookings = currentBookings.find(booking => booking.id === id);
    if (bookings) {
      const url = `/BookingDetails/${id}?buttonMode=${buttonMode}`;
      const message = new Message(getName(MessageEnum.NavigationBookingDetails));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationPayLoadMessage), { bookings, buttonMode });
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
      this.send(message);
      window.location.href = url;
    }
  };

  handleEdit() {
    this.setState({
      isEditMode: true,
      editedDetails: {
        ...this.state.userDetails.attributes
      }
    });
  }

  handleUpdateProfileInfo = async (details: UserDetails['attributes']) => {
    try {
      const { full_name, email, phone_number, country_code, profile_data, gender } = details;
      const { city, country } = profile_data.attributes;
      const user_id = await getStorageData("user_id");
      const userProfileUrl = await getStorageData("avatarImageUrl");
      const formData = new FormData();

      formData.append('account[full_name]', full_name);
      formData.append('account[email]', email);
      if (phone_number) {
        formData.append('account[phone_number]', phone_number.toString());
      }
      if (country_code !== undefined) {
        formData.append('account[country_code]', country_code.toString());
      }
      formData.append('account[gender]', gender);
      formData.append('account[profile][city]', city);
      formData.append('account[profile][country]', country);
      formData.append('account[profile][account_id]', user_id);

      if (userProfileUrl) {
        try {
          const response = await fetch(userProfileUrl);
          const blob = await response.blob();
          formData.append('account[profile_photo]', blob, 'profile_photo.jpg');
        } catch (error) {
        }
      }

      await this.updatProfileApiCall(formData);

    } catch (error) {
    }
  };

  validateUserPassword = (password: string): boolean => {
    password = password.trim();
    if (password.length < 8) {
      this.setState({ passwordError: configJSON.EightCharacters });
      return false;
    }

    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const numberChars = '0123456789';

    let hasUppercase = false;
    let hasLowercase = false;
    let hasNumber = false;

    for (const char of password) {
      if (uppercaseChars.includes(char)) hasUppercase = true;
      else if (lowercaseChars.includes(char)) hasLowercase = true;
      else if (numberChars.includes(char)) hasNumber = true;
    }

    if (!this.getAllCombinedLogicalValues(
      hasUppercase,
      hasLowercase,
      hasNumber,
    )) {
      this.setState({ passwordError: configJSON.Contains });
      return false;
    }

    for (let i = 0; i < password.length - 2; i++) {
      if (password[i] === password[i + 1] && password[i] === password[i + 2]) {
        this.setState({ passwordError: configJSON.CanNotContains });
        return false;
      }
    }
    const commonPasswords = ['password', '123456', 'qwerty', 'admin'];
    if (commonPasswords.includes(password.toLowerCase())) {
      this.setState({ passwordError: configJSON.IsCommon });
      return false;
    }

    const sequences = 'abcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < sequences.length - 2; i++) {
      const fragment = sequences.substring(i, i + 3);
      if (password.toLowerCase().includes(fragment)) {
        this.setState({ passwordError: configJSON.ContainsSequence });
        return false;
      }
    }
    return true;
  };

  isValidEmailId(email: string): boolean {
    email = email.trim();
    const atIndex = email.indexOf('@');
    const dotIndex = email.lastIndexOf('.');

    if (email.split('@').length !== 2) {
      return false;
    }

    if (
      atIndex <= 0 ||
      dotIndex <= atIndex + 1 ||
      dotIndex === email.length - 1
    ) {
      return false;
    }

    const localPart = email.slice(0, atIndex);
    if (localPart.length > 64 || localPart.length === 0) {
      return false;
    }

    const domainPart = email.slice(atIndex + 1);
    if (domainPart.length > 255 || domainPart.length === 0) {
      return false;
    }

    if (domainPart.indexOf('.') === -1) {
      return false;
    }

    const validChars =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.-_@';
    for (const char of email) {
      if (!validChars.includes(char)) {
        return false;
      }
    }

    if (email.includes('..')) {
      return false;
    }

    const tld = email.slice(dotIndex + 1);
    if (tld.length < 2) {
      return false;
    }

    return true;
  }

  validateEmailId = (email: string): boolean => {
    return this.isValidEmailId(email);
  };

  validateFullName = (fullName: string): boolean => {
    return fullName.trim().length > 0;
  };
  validateCity = (city: string): boolean => {
    return city.trim().length > 0;
  };
  validateCountry = (country: string): boolean => {
    return country.trim().length > 0;
  };
  validateForm = () => {
    const { editedDetails } = this.state;
    const isEmailValid = this.validateEmailId(editedDetails.email);
    const isFullNameValid = this.validateFullName(editedDetails.full_name);
    const isCityValid = this.validateCity(this.state.city);
    const isCountryValid = this.validateCountry(this.state.country);

    this.setState({
      cityError: !isCityValid,
      countryError: !isCountryValid,
    });

    return isEmailValid && isFullNameValid && isCityValid && isCountryValid;
  };

  handleSave = async () => {
    if (this.validateForm()) {
      try {
        const { editedDetails, userDetails, city, country, phoneNumber } = this.state;

        const updatedDetails: UserDetails['attributes'] = {
          ...userDetails.attributes,
          full_name: editedDetails.full_name,
          email: editedDetails.email,
          phone_number: phoneNumber ? Number(phoneNumber) : undefined,
          country_code: userDetails.attributes.country_code,
          gender: editedDetails.gender,
          profile_data: {
            attributes: {
              ...(userDetails.attributes.profile_data?.attributes ?? {}),
              city: city || userDetails.attributes.profile_data?.attributes?.city,
              country: country || userDetails.attributes.profile_data?.attributes?.country,
            },
          },
        };

        await this.handleUpdateProfileInfo(updatedDetails);

        this.setState({
          userDetails: {
            ...userDetails,
            attributes: updatedDetails,
          },
          isEditMode: false,
        });

      } catch (error) {
      }
    }
  };


  async getCutomerBookingDetails(category: string) {
    const authToken = await getStorageData('authToken');
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCustomerDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customerBookingDetailsAPI}?status=${category}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteUser = async () => {
    const authToken = await getStorageData('authToken');
    const userId = await getStorageData('user_id');

    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteUserApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteUserEndPoint + `/${userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCancel = () => {
    this.setState({
      isEditMode: false,
      editedDetails: this.state.userDetails?.attributes || {}
    });
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | { name: string; value: unknown }>) => {
    const { name, value } = e.target;
    this.setState({
      editedDetails: {
        ...this.state.editedDetails,
        [name]: value
      }
    });
  };

  handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    if (name) {
      this.setState({
        editedDetails: {
          ...this.state.editedDetails,
          [name]: value
        }
      });
    }
  };


  getFormattedPhoneNumber() {
    const { userDetails } = this.state;
    if (!userDetails) return '';
    const countryCode = userDetails.attributes.country_code ? '+' + userDetails.attributes.country_code : '';
    const phoneNumber = userDetails.attributes.phone_number ? userDetails.attributes.phone_number.toString().replace(/\D/g, '') : '';
    return countryCode + ' ' + phoneNumber;
  }

  logout = async () => {
    await removeStorageData("user_id");
    await removeStorageData("userRole");
    await removeStorageData("loginDetail");
    this.props.navigation.navigate('OnBoaeding');
  }

  addButtonStyles = (btnName: string) => {
    const buttonMode = this.state.buttonMode;
    const name = buttonMode === btnName ? buttonMode : 'defaultStyles';

    const newStyles: any = {
      completed: { border: '1px solid #059669', backgroundColor: '#D1FAE5' },
      booked: { border: '1px solid #D97706', backgroundColor: '#FEF3C7' },
      cancelled: { border: '1px solid #DC2626', backgroundColor: '#FEE2E2' },
      defaultStyles: { border: '#CBD5E1', backgroundColor: '#FFFFFF' },
    };

    return newStyles[name];
  };

  setWebLink = (event: any) => {
    this.setState({ webLink: event.target.value })
  }

  setSpaName = (event: any) => {
    this.setState({
      spaName: event.target.value, spaNameError: false
    });
  };

  updateProfileProperties = (APIPayload: Record<string, string | number | File[] | FileWithId[]>) => {
    const isPayLoad = Object.values(APIPayload).some((payload) => {
      return Array.isArray(payload) ? payload.length : payload
    });

    if (isPayLoad) {
      this.setState({ uploading: true });
      const completePayload = {
        ...APIPayload,
        'account[profile][account_id]': this.state.userDetails?.id
      }
      this.updatProfileApiCall(getFormDataFromPayload(completePayload));
    } else {
      this.setState({ isLicenseCertificationPopup: false })
    }
  };

  handleSubmitForReview = () => {
    const { spaName, webLink, businessLicenseImage } = this.state;
    const isValid = [
      spaName,
      businessLicenseImage.length
    ].every((formItem) => Boolean(formItem));

    if (isValid) {
      const APIPayload = {
        "spa_name": spaName,
        "documents[]": businessLicenseImage,
        "website_link": webLink
      }
      this.updateSpaNameApiCall(getFormDataFromPayload(APIPayload))
    } else {
      this.setState({ spaNameError: !spaName });
      !businessLicenseImage.length && this.setState({ businessLicenseError: "Please upload licence" })
    }
  }
  getOptionLabel = (option: any) => {
    return option;
  };

  getOptionSelected = (option: any, value: any) => {
    return option === value;
  };

  handleCancelEditLocation = () => {
    this.setState({ isEditLocationPopup: false })
  }

  deleteDocumentsByTypes = () => {
    const removableFiles = this.state.idsToRemoveFiles;
    for (let key in removableFiles) {
      removableFiles[key as APITypesKeys]
        .forEach((id: string) => this.handleDeleteDocument(id, key)
        );
    }
  }

  getOnlyFiles = (files: FileWithId[]) => files.filter((file) => !file.id);

  uploadDocuments = async () => {
    const { businessLicenseImage, certificateImage } = this.state;
    const { licenses, certificates } = this.state.userDetails?.attributes || {};

    const [licensesPresent, certificatesPresent] = [
      businessLicenseImage.length || licenses?.length,
      certificateImage.length || certificates?.length
    ];

    const isValid = licensesPresent && certificatesPresent;

    if (isValid) {
      this.updateProfileProperties(
        {
          'account[licenses][]': this.getOnlyFiles(this.state.businessLicenseImage),
          'account[certificates][]': this.getOnlyFiles(this.state.certificateImage),
          'account[other_files][]': this.getOnlyFiles(this.state.otherImages)
        }
      );
      this.deleteDocumentsByTypes();
    } else {
      !licensesPresent && this.setState({ businessLicenseError: "Please upload licence" });
      !certificatesPresent && this.setState({ certificateError: "Please upload certificates" });
    }
  }

  handleUpdateSpaProfileAddres = () => {
    const { country, stateValue, city, spaAddress } = this.state;
    const isValid = [
      country,
      stateValue,
      city,
      spaAddress
    ].every((formItem) => Boolean(formItem));
    if (isValid) {
      const APIPayload = {
        "account[profile][address]": spaAddress,
        "account[profile][city]": city,
        "account[profile][country]": country,
        "account[profile][postal_code]": stateValue
      }
      this.updatProfileApiCall(getFormDataFromPayload(APIPayload))
    } else {
      this.setState({
        countryError: !country,
        cityError: !city,
        stateError: !stateValue,
        addressError: !spaAddress
      })
    }
  }

  updateSpaNameApiCall = async (formData: any) => {

    const user_id = await getStorageData('user_id');
    const headers = {
      token: ''
    };
    this.userSpaNameAPICallId = callApi(
      {
        contentType: configJSON.apiContentType,
        method: "PUT",
        endPoint: configJSON.userSpaNameAPIEndPoint + `?id=${user_id}`,
        headers: headers,
        body: formData
      },
      runEngine
    )
  }

  handleEditSPALocation = (
    {
      country,
      state,
      city,
      address
    }: {
      country?: string,
      state?: string,
      city?: string,
      address?: string
    }) =>
    this.setState({
      isEditLocationPopup: true,
      country: String(country),
      stateValue: String(state),
      city: String(city),
      spaAddress: String(address)
    })

  SpaTodatStatusOption = (option: string) => {
    switch (option) {
      case 'OPEN':
        return '#059669';
      case 'CLOSED':
        return '#DC2626';
      case 'PARTIALLY CLOSED':
        return '#D97706';
      default:
        return 'inherit';
    }
  };

  handelSpatimingSlot = (item: any) => {
    this.setState({ anchorEl: null, updatedSpaTiming: item.label, addDisabled: false })
  }
  handleCancelSpatimingPopUp = () => {
    this.setState({ isSpaTimingPopup: false })
  }

  closeFilesPopOver = () => {
    this.setState({ anchorElLicence: [], anchorElCertificate: [] });
  }

  closeTimePopOver = () => this.setState({ anchorEl: null });

  handleEditSpaTimingPopup = () => {
    const {
      open_timing: openTiming,
    } = this.state.userDetails?.attributes || {};
    this.setState({ isSpaTimingPopup: true, updatedSpaTiming: this.getDefaultTiming(openTiming) })
  }

  isAndConditionRendering = (condition: any, value: any) => {
    return condition && value;
  }
  isConditinalRendering = (condition: any, value1: any, value2: any) => {
    return condition ? value1 : value2;
  }

  calculateDotsDimensions() {
    const slidesNumber = this.state.userDetails?.attributes?.carousal_images?.length;
    const highSlideEdge = 6;
    const lowSlideEdge = 4;
    // Handle case if there is only one slide
    const multiplier = slidesNumber && slidesNumber > 1 ? (highSlideEdge - lowSlideEdge) / (slidesNumber - 1) : 0;

    return new Array(slidesNumber)
      .fill(highSlideEdge)
      .map((_, index) => highSlideEdge - Math.abs(this.state.venueCarouselIndex - index) * multiplier);
  }

  handleDeleteDocument = async (id: any, type: string) => {
    const authToken = await getStorageData('authToken');

    const headers = {
      "token": authToken,
    };

    this.deleteDocumentApiCallId = callApi(
      {
        contentType: configJSON.validationApiContentType,
        method: "DELETE",
        endPoint: `${configJSON.deleteSpaLicenseandCertificate}?attachment_id=${id}&type=${type}`,
        headers: headers
      },
      runEngine
    )
  }

  addZeroToTime = (time?: number) => {
    const timeStr = String(time);
    return timeStr.length > 1 ? timeStr : `0${timeStr}`;
  }

  setAboutUs = (value: string) => this.setState({ aboutUs: value });
  setSameTimeChecked = () => this.setState({ sameTimeChecked: (!this.state.sameTimeChecked) });
  setIsDurationFrom = (value: boolean) => this.setState({ isDurationFrom: value });
  setOpenPrefferedTiming = (value: boolean) => this.setState({ addPrefferTiming: value });
  setDatesRange = (value: any) => this.setState({ datesRange: value });

  setCalendarPopUp = (value: boolean) => this.setState({ showCalendarPopUp: value });

  getTimeColor = ({ isFromTime }: { isFromTime: boolean }) => isFromTime ? '#398378' : '#94A3B8';

  getTimeString = (mode: 'from' | 'to'): string => {
    const { durationFrom, durationTo } = this.state.durationPicker;
    return mode === 'from' ?
      `${durationFrom.hours}:${durationFrom.minutes} am` :
      `${durationTo.hours}:${durationTo.minutes} pm`;
  }

  getDefaultTiming = (openTiming?: string) => (openTiming === 'Close' || !openTiming) ? spaTimingSlots[0].label : openTiming;

  submitTimingForm = () => {
    this.setState({ isSpaTimingPopup: false });
    const updatedSpaTiming = this.state.updatedSpaTiming;
    const timingData = [];
    // Exclude 'Close' timing status
    const [todayStartTime, todayEndTime] = updatedSpaTiming.includes('-') ? this.state.updatedSpaTiming.split(' - ') : '';

    const commonProps = {
      "status": this.state.spaTodayStatus.toLocaleLowerCase(),
      "same_timing": this.state.sameTimeChecked,
    }

    const todayData = {
      "start_time": todayStartTime,
      "end_time": todayEndTime,
      "date": [new Date().toISOString().split('T')[0]],
      ...commonProps
    };

    const otherDaysData = {
      "start_time": this.getTimeString('from'),
      "end_time": this.getTimeString('to'),
      "date": this.state.openingDatesList,
      ...commonProps
    };

    todayStartTime && timingData.push(todayData);
    this.state.openingDates && this.state.durationPicker && timingData.push(otherDaysData);
    const timingAPIPayload = {
      'account[profile][account_id]': this.state.userDetails?.id,
      'account[spa_timing]': JSON.stringify(timingData)
    };
    this.updatProfileApiCall(getFormDataFromPayload(timingAPIPayload))
  }

  getDuration = (): string =>
    `${this.getTimeString("from")} - ${this.getTimeString("to")}`;

  getCommonDuration = (title: string) =>
    this.state.durationPicker.hasStarted ?
      `${this.getTimeString('from')} - ${this.getTimeString('to')}` :
      title

  getUniqArray = (targetArray: string[]) => Array.from(new Set(targetArray))

  handleDates = (callendarDate: any) => {
    const allDatesRange: string[] = [];
    Array.isArray(callendarDate) && callendarDate.forEach((dateObject: any) => {
      dateObject.forEach((innerData: DateObject) => {
        const { year, month, day } = innerData;
        const fullDate = `${year}-${this.addZeroToTime(Number(month))}-${this.addZeroToTime(Number(day))}`;
        allDatesRange.push(fullDate);
      })
    });

    const openingDatesList = this.getUniqArray(allDatesRange).sort();
    const rangeDates = [openingDatesList[0], openingDatesList[openingDatesList.length - 1]];

    const openingDates = this.getUniqArray(rangeDates).map((dateString) => {
      return new Date(dateString).toLocaleString(
        'en-GB',
        {
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        })
    }).join(' - ');
    this.setState({ openingDates, openingDatesList })
  }

  onDurationChange = (duration: DurationPickerObjInit) => {
    const hours = addZeroToTime(duration.hours);
    const minutes = addZeroToTime(duration.minutes);

    const { durationFrom, durationTo } = this.state.durationPicker;

    // Handele empty values
    if (minutes) {
      const resDuration = this.state.isDurationFrom
        ? { durationFrom: { ...durationFrom, hours, minutes } }
        : { durationTo: { ...durationTo, hours, minutes } };

      this.state.durationPicker &&
        this.setState({
          durationPicker: {
            ...this.state.durationPicker,
            ...resDuration,
            hasStarted: true,
          },
        });
    }
  };

  setApiError = (value: boolean) => this.setState({ apiError: value });

  getTrullyVal = (first?: string, second?: string) => first || second;

  getTernaryNodeOutput = (condition: boolean, first: React.ReactNode, second: React.ReactNode) => condition ? first : second;

  setAnchor = (
    index: number,
    setterName: PopoverAnchor,
    value: HTMLElement | null
  ) => {
    const anchorCopy = this.state[setterName];
    anchorCopy[index] = value;
    this.setState({ [setterName]: anchorCopy } as unknown as Pick<S, keyof S>);
  }

  addFilesForRemove = (uploadedFiles: FileWithId[], index: number, popoverAnchor: PopoverAnchor) => {
    const documentId = uploadedFiles[index].id;

    const objectType = {
      licenses: popoverAnchor === 'anchorElLicence',
      certificates: popoverAnchor === 'anchorElCertificate',
      carousal_images: popoverAnchor === 'anchorElOtherFiles'
    }
    documentId && this.setState({
      idsToRemoveFiles: {
        ...this.state.idsToRemoveFiles,
        ...(objectType.licenses && {
          licenses: [
            ...this.state.idsToRemoveFiles.licenses,
            documentId
          ]
        }),
        ...(objectType.certificates && {
          certificates: [
            ...this.state.idsToRemoveFiles.certificates,
            documentId
          ]
        }),
        ...(objectType.carousal_images && {
          carousal_images: [
            ...this.state.idsToRemoveFiles.carousal_images,
            documentId
          ]
        }),
      }
    });
    this.setAnchor(index, popoverAnchor, null);
  }

  checkAllProgressCompleted = () => {
    const isProgressComp = this.state.progressBars.every((progress) => progress === configJSON.edgeInterval);
    isProgressComp &&
      this.setState({
        isLicenseCertificationPopup: false,
        loading: false,
      });
  }

  setExpectedProgress = (index: number) => {
    const edge = configJSON.edgeInterval;
    const progressInterval = setInterval(() => {
      const { progressBars } = this.state;
      const currentBar = progressBars[index];
      const indexValue = currentBar || 0;

      if (currentBar === edge) {
        this.checkAllProgressCompleted();
        clearInterval(progressInterval);
      } else {
        const totalProgress = incrementRandomNumber(indexValue);
        const resProgress = totalProgress >= edge ? edge : totalProgress;
        progressBars[index] = resProgress;
      }
      if (indexValue <= edge) {
        this.setState({ progressBars: progressBars });
      }
    }, 300);
  }

  findTodayData = () => {
    const spaTiming = (this.state.userDetails?.attributes.spa_timings || []).slice().reverse();
    const todayData = spaTiming?.find((timing: TimeSlot) =>
      timing.date.includes(new Date().toISOString().split('T')[0]));
    return ({
      todayTiming: `${todayData?.start_time} - ${todayData?.end_time}`,
      todayStatus: (todayData?.status.toLocaleLowerCase().replace(/\b[a-z,A-Z]/g, (strg: string) => strg.toUpperCase()) || '...loading')
    })
  }

  getStatusNode = () => {
    const { todayTiming, todayStatus } = this.findTodayData();
    return <>
      {todayStatus} today {todayStatus === 'Open' && <span style={{ fontWeight: 'bold' }}>{todayTiming}</span>}
    </>
  }

  getStatusImg = () => {
    const { todayStatus } = this.findTodayData();
    const openImg = todayStatus === 'Open' && clockOpened;
    const closedImg = todayStatus === 'Closed' && clockClosed;

    return openImg || closedImg || clockWait;
  }
  // Customizable Area End

}
