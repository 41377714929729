import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Country, State, City, ICountry, IState, ICity } from 'country-state-city';
import { Category } from '@material-ui/icons';
import { getStorageData } from '../../../framework/src/Utilities';
import { getFormDataFromPayload } from '../../utilities/src/handle-form';
import React from 'react';
import { DateObject } from 'react-multi-date-picker';
import { addZeroToTime, calculateSlots } from '../../../components/src/datesRangeHelper';

export const spaTimingSlots = [
  { label: "8:00 am - 5:00 pm" },
  { label: "9:30 am - 7:00 pm" },
  { label: "10:00 am - 5:30 pm" },
  { label: "10:00 am - 7:00 pm" },
];

export const durationList = [
  '30 mins',
  '1 hr',
  '1 hr 30 mins',
  '2 hr'
];

export const timeSlots = [
  '10:00 am - 10.45 am',
  '11:45 am - 12.30 am',
  '12:30 am - 13.15 am',
  '13:15 am - 14.00 am',
  '14:00 am - 14.45 am',
  '14:45 am - 15.30 am',
  '15:30 am - 16.15 am',
  '16:15 am - 17.00 am',
  '17:00 am - 17.45 am',
];

export type CategorySubCategory = {
  id: number,
  name: string,
};

export type CarouselImage = {
  id: number,
  url: string
};

const initCatSubCat = {
  id: 0,
  name: '',
};

export type Category = {
  id: number,
  attributes: {
    name: string,
    sub_categories: CategorySubCategory[]
  }
};

export type SubCategory = {
  subcategory: {
    id: number,
    name: string
  },
  slots: {
    id: 4,
    duration: string,
    price: string,
    start_time: string,
    end_time: string
  }[]
};

type UserDetails = {
  id: string;
  attributes: {
    full_name: string;
    open_timing: string;
    about: string;
    about_us: string;
    profile_data: {
      attributes: {
        country: string;
        postal_code: string;
        city: string;
        address: string,
        photo: string
      }
    };
    spa_timings: {
      start_time: string,
      end_time: string,
      date: [],
      status: string,
      same_timing: boolean
    }[],
    services: {
      subcategories_with_slots: SubCategory[]
    }[],
    reviews: {
      name: string;
      review: string;
      comment: string;
      created_at: string;
    }[];
    image_url: string,
    average_review: number,
    average_review_string: string,
    carousal_images: CarouselImage[]
  }
}

export const initCategory = {
  id: 0,
  name: ''
};

export const initSettingsForm = {
  country: '',
  state: '',
  city: '',
  completeAddress: '',
  services: [{
    catalogueId: 0,
    serviceImage: null,
    serviceName: '',
    category: initCategory,
    subCategory: initCategory,
    targetedRegion: initCategory,
    serviceDescription: '',
    slots: [
      {
        id: 0,
        duration: '',
        price: ''
      }
    ]
  }],
  categorySlots: [{
    availabilityId: 0,
    catalogueId: 0,
    serviceName: '',
    categoryName: '',
    capacity: 0,
    date: [],
    slots: [],
    slotData: {
      id: 0,
      duration: '30min'
    },
  }],
  timing: {
    status: 'OPEN',
    todaysTiming: '9:30 am - 7:00 pm',
    dates: [],
    currentTiming: '',
    sameTiming: false
  },
  spaImages: [],
};

type Slot = {
  id: number,
  duration: string,
  price: string
};

export type Service = {
  catalogueId: number,
  serviceImage: File | null,
  serviceName: string,
  category: CategorySubCategory,
  subCategory: CategorySubCategory,
  targetedRegion: CategorySubCategory,
  serviceDescription: string,
  slots: Slot[]
};

export type ServiceError = {
  serviceImage: string,
  serviceName: string,
  category: { name: string },
  subCategory: { name: string },
  targetedRegion: string,
  serviceDescription: string,
  slots: {
    duration: string,
    price: string
  }[]
};

export type CategorySlotsError = {
  date: string,
  duration: string,
  slots: string,
  slotData: {
    duration: string
  }
}

export type CategorySlot = {
  availabilityId: number;
  catalogueId: number;
  serviceName: string;
  categoryName: string;
  capacity: number;
  date: string[];
  slots: string[];
  slotData: {
    id: number,
    duration: string
  }
};

export type Timing = {
  status: string,
  todaysTiming: string,
  dates: string[],
  currentTiming: string,
  sameTiming: boolean
};

export type SettingsForm = {
  country: string,
  state: string,
  city: string,
  completeAddress: string,
  services: Service[],
  categorySlots: CategorySlot[],
  timing: Timing,
  spaImages: File[]
};

type DurationPickerObjConv = {
  hours?: string,
  minutes?: string,
  marker?: string
};

type DurationPickerObjInit = {
  hours?: number,
  minutes?: number,
  marker?: string
};

export type Catalogue = {
  id: number | string,
  attributes: {
    name: string,
    region: CategorySubCategory | null,
    availabilities: {
      availability_id: number,
      capacity: number,
      dates: string[],
      duration: { duration: string, id: number };
      timeslots: string[]
    }[],
    images: {
      url: string
    }[] | null,
    description: string,
    sub_category: CategorySubCategory,
    category: {
      attributes: CategorySubCategory
    }
    catalogue_slots: {
      id: number,
      duration: string,
      price: string
    }[]
  }
};

type DurationPicker = {
  durationFrom: DurationPickerObjConv,
  durationTo: DurationPickerObjConv,
  hasStarted: boolean
};

export type ImgLink = (string | ArrayBuffer | null);

const zeroes = '00';

export const initCatalogues = [{
  id: 0,
  attributes: {
    name: '',
    region: initCatSubCat,
    availabilities: [{
      availability_id: 0,
      capacity: 0,
      dates: [],
      duration: { duration: '', id: 0 },
      timeslots: []
    }],
    images: [{
      url: ''
    }],
    description: '',
    sub_category: initCatSubCat,
    category: {
      attributes: initCatSubCat
    },
    catalogue_slots: [{
      id: 0,
      duration: '',
      price: ''
    }]
  },
}];

const initDurationPicker = {
  durationFrom: { hours: zeroes, minutes: zeroes, marker: 'am' },
  durationTo: { hours: zeroes, minutes: zeroes, marker: 'pm' },
  hasStarted: false
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activeStep: number;
  pricingSlotsNumber: null[],
  countries: ICountry[],
  states: IState[],
  cities: ICity[],
  countryCode: string,
  upldImgLinks: ImgLink[];
  upldSPAImgLinks: ImgLink[];
  isLocationCompleted: boolean,
  categories: Category[],
  regions: CategorySubCategory[],
  loading: boolean,
  firstStage: number,
  anchorDuration: HTMLElement | null,
  anchorTiming: HTMLElement | null,
  durationPicker: DurationPicker,
  isDurationFrom: boolean;
  selectedSlotIndexes: number[][];
  timeSlots: string[][];
  catalogues: Catalogue[],
  userDetails: UserDetails | null,
  slotDates: any,
  timingDate: string,
  isEditMode: boolean,
  catalogueIdsToDelete: number[],
  slotsIdsToDelete: number[][],
  imagesIdsToDelete: number[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCategoriesCallId = '';
  apiGetRegionsCallId = '';
  apiPostUpdateProfileCallId = '';
  apiCreateUpdateProductCallId = '';
  apiAvailabilityCallId = '';
  apiGetUserDetailsCallId = '';
  apiGetCataloguesCallId = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activeStep: 0,
      pricingSlotsNumber: [null],
      countries: Country.getAllCountries(),
      states: [],
      cities: [],
      countryCode: '',
      upldImgLinks: [],
      upldSPAImgLinks: [],
      isLocationCompleted: false,
      categories: [],
      regions: [],
      loading: false,
      firstStage: 0,
      anchorDuration: null,
      anchorTiming: null,
      durationPicker: initDurationPicker,
      isDurationFrom: true,
      selectedSlotIndexes: [],
      timeSlots: [],
      catalogues: initCatalogues,
      userDetails: null,
      slotDates: [],
      timingDate: '',
      catalogueIdsToDelete: [],
      slotsIdsToDelete: [],
      imagesIdsToDelete: [],
      // If true - it's for testing purposes
      isEditMode: true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const navigationPayloadMessage = message.getData(getName(MessageEnum.NavigationPayLoadMessage));

    if (navigationPayloadMessage) {
      this.setState({ activeStep: Number(navigationPayloadMessage) })
    }

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.setState({ loading: false });

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson) {
        if (apiRequestCallId === this.apiGetUserDetailsCallId) {
          this.setState({ userDetails: responseJson.data })
        }
        if (apiRequestCallId === this.apiGetCategoriesCallId) {
          this.setState({ categories: responseJson.data })
        }
        if (apiRequestCallId === this.apiGetRegionsCallId) {
          this.setState({
            regions: responseJson.map((region: CategorySubCategory) => ({
              id: region.id,
              name: region.name
            }))
          })
        }
        if (apiRequestCallId === this.apiCreateUpdateProductCallId) {
          setTimeout(() => this.getCatalogues(), 1000);
        }
        if (apiRequestCallId === this.apiAvailabilityCallId) {
          this.getUserDetails();
        }
        if (apiRequestCallId === this.apiGetCataloguesCallId) {
          this.setState({ catalogues: responseJson.data });
        }
        if (
          apiRequestCallId === this.apiPostUpdateProfileCallId ||
          apiRequestCallId === this.apiCreateUpdateProductCallId ||
          apiRequestCallId === this.apiAvailabilityCallId
        ) {
          this.setStep(this.state.activeStep + 1);
        }
      }
      if (errorReponse || responseJson.errors) {
        this.showAlert(configJSON.commonErrorText, configJSON.commonErrorDescription);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async getUserDetails() {
    const userID = await getStorageData('user_id');
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetailsAPIEndPoint}?id=${userID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.retrieveApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCategories() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCategoriesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.retrieveApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getRegions() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetRegionsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.regionApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.retrieveApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async getCatalogues() {
    this.setState({ loading: true });
    const authToken = await getStorageData('authToken');

    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCataloguesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userCatalogueAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.retrieveApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async updateSpaProfile(formData: any, callGetReq = true) {
    this.setState({ loading: true });
    const authToken = await getStorageData('authToken');
    const headers = {
      "token": authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (callGetReq) this.apiPostUpdateProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.saveApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async createUpdateProduct(formData: any, updateMode?: boolean) {
    this.setState({ loading: true })
    const authToken = await getStorageData('authToken');
    const headers = {
      "token": authToken
    };
    const queryparams = updateMode ?
      {
        endPoint: configJSON.productApiUpdateEndPoint,
        method: configJSON.updateApiMethod
      } :
      {
        endPoint: configJSON.productApiEndPoint,
        method: configJSON.saveApiMethod
      }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateUpdateProductCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      queryparams.endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      queryparams.method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async deleteProduct(id: number) {
    this.setState({ loading: true })
    const authToken = await getStorageData('authToken');
    const headers = {
      "token": authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productApiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async deleteImage(id: number) {
    this.setState({ loading: true });
    const authToken = await getStorageData('authToken');

    const headers = {
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteAttachmentsAPIEndPoint}?attachment_id=${id}&type=carousal_images`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  incStep = () => this.state.activeStep + 1;

  callUpdateProfileLocation = (settingsForm: SettingsForm) => {
    const {
      country,
      state,
      city,
      completeAddress,
    } = settingsForm;
    const profilePayload = {
      'account[profile][country]': country,
      'account[profile][postal_code]': state,
      'account[profile][city]': city,
      'account[profile][address]': completeAddress,
      'account[profile_step]': this.incStep()
    };
    this.updateSpaProfile(getFormDataFromPayload(profilePayload));
  };

  callUpdateActiveStep = async () => {
    const accountID = await getStorageData('user_id');
    const profilePayload = {
      'account[profile][account_id]': accountID,
      'account[profile_step]': this.incStep()
    };
    this.updateSpaProfile(getFormDataFromPayload(profilePayload), false);
  };

  // Renew it
  callUpdateProfileTiming = async (settingsForm: SettingsForm) => {
    const accountID = await getStorageData('user_id');

    const {
      status,
      todaysTiming,
      dates,
      currentTiming,
      sameTiming
    } = settingsForm.timing;
    const timingData = [];
    const [todayStartTime, todayEndTime] = todaysTiming.split(' - ');
    const [currentStartTime, currentTodayEndTime] = currentTiming.split(' - ');

    const commonProps = {
      "status": status.toLocaleLowerCase(),
      "same_timing": sameTiming,
    }

    const todayData = {
      "start_time": todayStartTime,
      "end_time": todayEndTime,
      "date": [new Date().toISOString().split('T')[0]],
      ...commonProps
    };

    const otherDaysData = {
      "start_time": currentStartTime,
      "end_time": currentTodayEndTime,
      "date": dates,
      ...commonProps
    };

    timingData.push(todayData);
    currentStartTime && timingData.push(otherDaysData);
    const profilePayload = {
      'account[profile][account_id]': accountID,
      'account[spa_timing]': JSON.stringify(timingData),
      'account[profile_step]': this.incStep()
    };
    this.updateSpaProfile(getFormDataFromPayload(profilePayload));
  };

  callUpdateProfileImages = async (settingsForm: SettingsForm) => {
    const accountID = await getStorageData('user_id');

    // Calculate difference between existed and newly added images
    const exImages = (this.state.userDetails?.attributes.carousal_images?.length || 0) - this.state.imagesIdsToDelete.length;
    const profilePayload = {
      'account[profile][account_id]': accountID,
      'account[carousal_images][]': settingsForm.spaImages.slice(exImages),
      'account[profile_step]': this.incStep()
    };
    this.updateSpaProfile(getFormDataFromPayload(profilePayload));
  }

  callCreateUpdateCatalogue = async (settingsForm: SettingsForm) => {
    const formData = new FormData();
    const updFormData = new FormData();
    settingsForm.services.forEach((service: Service, index) => {
      const {
        catalogueId,
        serviceImage,
        serviceName,
        category,
        subCategory,
        serviceDescription,
        targetedRegion,
        slots
      } = service;

      const slotPairs = slots.map((slot) => ({
        'data[][slots_attributes][][duration]': slot.duration,
        'data[][slots_attributes][][price]': slot.price,
        ...(slot.id && { 'data[][slots_attributes][][id]': slot.id })
      }));

      // Remove slots
      const slotsToDelete = this.state.slotsIdsToDelete[index]?.map((slotId) => ({
        // The devider is needed to create new item to add new and remove old slots in the same time
        'data[][slots_attributes][]': 'devider',
        'data[][slots_attributes][][id]': slotId,
        'data[][slots_attributes][][_destroy]': true
      }));

      const productPayload = {
        'data[]id': catalogueId,
        'data[][price]': slots[0].price,
        'data[][images][]': [serviceImage],
        'data[][name]': serviceName,
        'data[][category_id]': category.id,
        'data[][sub_category_id]': subCategory.id,
        'data[][description]': serviceDescription,
        'data[]bx_block_categories_region_id': targetedRegion.id,
        'slotPairs': slotPairs,
        'slotPairsToDelete': slotsToDelete
      };
      getFormDataFromPayload(productPayload, catalogueId ? updFormData : formData);
    });
    const isFormData = !formData.entries().next().done;
    const isUpdFormData = !updFormData.entries().next().done;

    isFormData && this.createUpdateProduct(formData);
    isUpdFormData && this.createUpdateProduct(updFormData, true);
  };

  callDeleteCatalogues = () => this.state.catalogueIdsToDelete.forEach((catalogueId: number) => this.deleteProduct(catalogueId));

  callDeleteImages = () => this.state.imagesIdsToDelete.forEach((imageId: number) => this.deleteImage(imageId));

  goToSettingsConfirmation = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSettingsConfirmation)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToMyProfile = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSpaProfile)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getInputError = (isTouched?: boolean, error?: string) => Boolean(isTouched && error);

  getAutocompleteStyle = (isError: boolean) => (
    {
      padding: '12px 16px',
      borderRadius: '8px',
      minHeight: '56px',
      ...(isError && { border: '1px solid red' })
    }
  )

  setStep = (value: number, callback?: () => void) => this.setState({ activeStep: value }, () => callback?.());

  onSelectCountry = (countryName: string) => {
    const countryCode = this.state.countries.find((country) => country.name === countryName)?.isoCode || '';
    const states = State.getStatesOfCountry(countryCode);
    this.setState({ states, countryCode });
  }

  onSelectState = (stateName: string) => {
    const stateObj = this.state.states.find((state) => state.name === stateName);
    const { countryCode, isoCode } = stateObj || { countryCode: '', isoCode: '' };

    const cities = City.getCitiesOfState(countryCode, isoCode);
    const allCountryCities = City.getCitiesOfCountry(this.state.countryCode);

    const resultCities = (cities.length && cities) || allCountryCities || [];

    this.setState({ cities: resultCities });
  }

  handleSpaImgUpload = (imageItems: File[]) => {
    imageItems.forEach((imageItem) => {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", async () => {
        const upldSPAImgLinks = this.state.upldSPAImgLinks;
        upldSPAImgLinks.push(fileReader.result)
        this.setState({ upldSPAImgLinks });
      });
      fileReader.readAsDataURL(imageItem);
    })
  };

  handleImgUpload = (imageItem: File | undefined, index: number) => {
    const fileReader = new FileReader();
    fileReader.addEventListener("load", async () => {
      const upldImgLinks = this.state.upldImgLinks;
      upldImgLinks[index] = fileReader.result;
      this.setState({ upldImgLinks });
    });
    imageItem && fileReader.readAsDataURL(imageItem);
  };

  removeImgSpa = (index: number) => {
    const upldSPAImgLinks = this.state.upldSPAImgLinks;
    upldSPAImgLinks.splice(index, 1);
    this.setState({ upldSPAImgLinks });
  }

  removeImgUpload = (index: number) => {
    const upldImgLinks = this.state.upldImgLinks;
    upldImgLinks.splice(index, 1);
    this.setState({ upldImgLinks });
  }

  checkFirstStep = ({ country, state, city, address }:
    {
      country: string,
      state: string,
      city: string,
      address: string
    }) => {
    const isLocationCompleted = Boolean(country && state && city && address);
    isLocationCompleted != this.state.isLocationCompleted && this.setState({ isLocationCompleted });
  }

  isFinalStep = () => this.state.activeStep === 5;

  // Show all steps on a final step
  isLocationStep = () => this.state.activeStep === 0 || this.isFinalStep();
  isServicesStep = () => this.state.activeStep === 1 || this.isFinalStep();
  isAvailabilityStep = () => this.state.activeStep === 2 || this.isFinalStep();
  isTimingStep = () => this.state.activeStep === 3 || this.isFinalStep();
  isImagesStep = () => this.state.activeStep === 4 || this.isFinalStep();

  checkStepCompleted = (index: number) =>
    index ? this.state.activeStep > index : this.state.isLocationCompleted;

  showByTouched = (firstVal: boolean | undefined, secondVal: string | undefined) => firstVal && secondVal;

  getSimpleCategories = (): CategorySubCategory[] => this.state.categories.map((category) => ({
    id: category.id,
    name: category.attributes.name
  }))

  getSubCategories = (categoryId: number): CategorySubCategory[] =>
    this.state.categories.find((category) => category.id === categoryId)
      ?.attributes
      .sub_categories
      .map((subCategory) => ({
        id: subCategory.id,
        name: subCategory.name
      })) || [initCategory];

  handleCloseDurationPopOver = () => this.setState({ anchorDuration: null });
  handleOpenDurationPopOver = (event: React.MouseEvent<HTMLElement>) => this.setState({ anchorDuration: event.currentTarget });

  handleCloseTimingPopOver = () => this.setState({ anchorTiming: null });
  handleOpenTimingPopOver = (event: React.MouseEvent<HTMLElement>) => this.setState({ anchorTiming: event.currentTarget });

  onDurationChange = (duration: DurationPickerObjInit) => {
    const hours = addZeroToTime(duration.hours);
    const minutes = addZeroToTime(duration.minutes);

    const { durationFrom, durationTo } = this.state.durationPicker;

    // Handele empty values
    if (minutes) {
      const resDuration = this.state.isDurationFrom ?
        { durationFrom: { ...durationFrom, hours, minutes } } :
        { durationTo: { ...durationTo, hours, minutes } }

      this.state.durationPicker &&
        this.setState({
          durationPicker: {
            ...this.state.durationPicker,
            ...resDuration,
            hasStarted: true
          }
        })
    }
  };

  getTimeColor = ({ isFromTime }: { isFromTime: boolean }) => isFromTime ? '#398378' : '#94A3B8';

  setIsDurationFrom = (value: boolean) => this.setState({ isDurationFrom: value });

  getUniqArray = (targetArray: string[]) => Array.from(new Set(targetArray));

  getFoolDate = (multiDateObj: any) => {
    const { year, month, day } = multiDateObj;
    return `${year}-${addZeroToTime(Number(month))}-${addZeroToTime(Number(day))}`;
  }

  convertDateToIso = (date: Date) => date.toISOString().split('T')[0];

  setDates = (dates: DateObject[][], callback: (dates: string[]) => void) => {
    const oneDayMs = 86400000;
    const allDatesRange: string[] = [];
    Array.isArray(dates) && dates.forEach((dateObject: any, index) => {
      if (dateObject.length) {
        const beginDate = new Date(this.getFoolDate(dateObject[0]));

        allDatesRange.push(this.convertDateToIso(beginDate));

        if (dateObject.length - 1) {
          const endDate = new Date(this.getFoolDate(dateObject[1]));
          const datesRange = ((endDate as any) - (beginDate as any) + oneDayMs) / oneDayMs;

          Array.from(Array(datesRange)).forEach((_, index) => {
            const beginDataCopy = new Date(beginDate);
            beginDataCopy.setDate(beginDataCopy.getDate() + index);
            allDatesRange.push(this.convertDateToIso(beginDataCopy));
          })
        }
      }
    });

    const openingDatesList = this.getUniqArray(allDatesRange).sort();
    callback(openingDatesList);
  }

  setSlotDates = (slotDates: any, index: number) => {
    const copySlotDates = this.state.slotDates;
    copySlotDates[index] = slotDates;
    this.setState({ slotDates: copySlotDates });
  }

  setTimingDate = (timingDate: string) => this.setState({ timingDate });

  getErrorBorder = (isError: boolean) => isError ? { border: '1px solid #DC2626' } : {};

  getHighlightSlotStyle = (index: number, slotIndex: number) => this.state.selectedSlotIndexes[index]?.includes(slotIndex) ?
    {
      backgroundColor: '#398378ff',
      color: '#ffffff'
    } : {};

  pushOrRemoveIndex = (targetArray: any[], item: string | number) => {
    const slotIdentIndex = targetArray.indexOf(item as never);
    const targetListCopy = [...targetArray];
    ~slotIdentIndex ? targetListCopy.splice(slotIdentIndex, 1) : targetListCopy.push(item as never);
    return targetListCopy;
  }

  setSelectedSlotIndexes = (indexes: number[], slotIndex: number) => {
    const copySelectedIndexes = this.state.selectedSlotIndexes;
    copySelectedIndexes[slotIndex] = indexes;
    this.setState({ selectedSlotIndexes: copySelectedIndexes });
  }

  getTimeString = (mode: 'from' | 'to'): string => {
    const { durationFrom, durationTo } = this.state.durationPicker;
    return mode === 'from' ?
      `${durationFrom.hours}:${durationFrom.minutes} ${durationFrom.marker}` :
      `${durationTo.hours}:${durationTo.minutes} ${durationTo.marker}`;
  }

  getCommonDuration = (): string => `${this.getTimeString('from')} - ${this.getTimeString('to')}`;

  getDuration = (title: string) =>
    this.state.durationPicker.hasStarted ?
      this.getCommonDuration() :
      title

  setTimeSlots = (timeSlots: string[], index: number) => {
    const timeSlotsCopy = this.state.timeSlots;
    timeSlotsCopy[index] = timeSlots;
    this.setState({ timeSlots: timeSlotsCopy });
  }

  handleTimeSlots = (index: number) => {
    const timeSlotsCopy = this.state.timeSlots;
    timeSlotsCopy[index] = [...timeSlotsCopy[index], this.getCommonDuration()];
    this.setState({
      timeSlots: timeSlotsCopy,
      anchorDuration: null,
      durationPicker: initDurationPicker,
      isDurationFrom: true
    });
  }

  setTimeMarker = (marker: string, durationFrom: boolean) => {
    const durPicker = this.state.durationPicker;
    const resDuration = durationFrom ? {
      durationFrom: {
        ...durPicker.durationFrom,
        marker
      }
    } : {
      durationTo: {
        ...durPicker.durationTo,
        marker
      }
    };

    this.setState({
      durationPicker: {
        ...durPicker,
        ...resDuration
      }
    })
  }

  setAmPmColor = (markerExp: string, durationFromExp: boolean) => {
    const { durationFrom, durationTo } = this.state.durationPicker;
    const isFrom = durationFromExp && durationFrom.marker === markerExp;
    const isTo = !durationFromExp && durationTo.marker === markerExp;
    return isFrom || isTo ? '#398378' : '#CBD5E1';
  }

  getTrullyVal = (first?: string | boolean, second?: string) => first || second;

  SpaTodayStatusStyle = (option: string) => {
    switch (option.toUpperCase()) {
      case 'OPEN':
        return '#059669';
      case 'CLOSED':
        return '#DC2626';
      default:
        return '#D97706';
    }
  };

  setEditMode = () => this.setState({ isEditMode: true });

  stripTags = (data: string) => {
    const tmpElement = document.createElement('div');
    tmpElement.innerHTML = data;
    return tmpElement.textContent || tmpElement.innerText || '';
  }

  urlToFileObject = async (image?: string): Promise<File | undefined> => {
    if (image) {
      const response = await fetch(image);
      // here image is url/location of image
      const blob = await response.blob();
      return new File([blob], 'image.jpg', { type: blob.type });
    }
  }

  isCatalogueStep = () => this.isServicesStep() || this.isAvailabilityStep();

  generateTimeSlots = (slotDuration: string, index: number) => {
    const timeStrings = calculateSlots('10:00 am - 06:00 pm', slotDuration).map((timeObject) => timeObject.time);
    this.setTimeSlots(timeStrings, index);
    return timeStrings;
  }

  setDurationPicker = (durationPicker: DurationPicker) => this.setState({ durationPicker });

  getHoursAndMinutes = (timeString: string) => {
    const marker = timeString.match(/[a-zA-Z]./g)?.[0]
    const [first, second] = timeString.replace(/\s*[a-zA-Z]/g, '').split(':');
    return [
      first,
      second,
      marker
    ]
  };

  setUpldSPAImgLinks = (upldSPAImgLinks: ImgLink[]) => this.setState({ upldSPAImgLinks });

  setCatalogueIdsToDelete = (catalogue: number) => this.setState({
    catalogueIdsToDelete: [
      ...this.state.catalogueIdsToDelete,
      catalogue
    ]
  });

  setImagesIdsToDelete = (imageId?: number) => imageId && this.setState({
    imagesIdsToDelete: [
      ...this.state.imagesIdsToDelete,
      imageId
    ]
  });

  setSlotIdsToDelete = (slot: number, index: number) => {
    const copySlots = this.state.slotsIdsToDelete;
    copySlots[index] = [
      ...(copySlots[index] || []),
      slot
    ];
    this.setState({ slotsIdsToDelete: copySlots });
  }

  resetCatalogues = () => this.setState({ catalogues: initCatalogues });

  resetDeleteIds = () => this.setState({
    slotsIdsToDelete: [],
    catalogueIdsToDelete: [],
    imagesIdsToDelete: []
  })
  // Customizable Area End
}
