import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import {
  imgPasswordInVisible,
  imgPasswordVisible,
  defaultProfile,
} from './assets';
import React from 'react';
import dayjs from "dayjs";
import { getStorageData } from '../../../framework/src/Utilities';
import { calculateSlots } from '../../../components/src/datesRangeHelper';
import { Catalogue } from "../../settings1/src/Settings1Controller";

type BookingFormValues = {
  name: string;
  phone: string;
  gender: string;
  email: string;
  serviceName: { id: string; name: string };
  serviceCategory: { id: string; name: string };
  serviceSubCategory: { id: string; name: string };
  date: string;
  duration: { id: number; duration: string };
  slots: string;
  notes?: string;
};

interface CompleteAttributes {
  country: string;
  address: string | undefined;
  city: string;
  postal_code: string;
}

type RescheduleFromValues = {
  name: string;
  serviceName: string;
  duration: string;
  slot: string;
  date: string;
  price: number;
};

type CustomerProfile = {
  id: number;
  image_url: string;
  name: string;
  shortDescription: string;
  customerInfo?: {
    fullName: string;
    phoneNumber: number;
    email: string;
    gender: string;
    totalAmount: string;
  };
};

type BookingsCount = {
  completed: number;
  booked: number;
  cancelled: number;
};

export enum UserDetails {
  name = "Name",
  phone_number = "Phone Number",
  email = "Email ID",
  gender = "Gender",
  total_price = "Total Amount",
}

export const initBookingFormValues = {
  name: '',
  phone: '',
  gender: '',
  email: '',
  serviceName: { id: "0", name: "" },
  serviceCategory: { id: "0", name: "" },
  serviceSubCategory: { id: "0", name: "" },
  date: '',
  duration: { id: 0, duration: "" },
  slots: "",
  notes: '',
};

export const initRescheduleFormValues = {
  name: "",
  serviceName: "",
  duration: "",
  slot: "",
  date: "",
  price: 0
};

const initScheduleItem = {
  id: "",
  name: "",
  backgroundColor: "#059669",
  title: "",
  start: "",
  time: "",
  photo: "",
  price: "",
  duration: "",
  display: "list-item",
  classNames: ["green-card"],
}

export interface CategorySubCategory {
  id: string;
  name: string;
}

export interface Category {
  id: string;
  attributes: {
    name: string;
    sub_categories: CategorySubCategory[];
  };
}

export enum BookingStatusEnum {
  "booked" = "Upcoming",
  "completed" = "Completed",
  "cancelled" = "Cancelled"
}

export type Status = "booked" | "completed" | "cancelled"

type BookingDetails = {
  id: number;
  start_time: string;
  end_time: string;
  booking_date: string;
  total_price: number;
  status: "booked" | "completed" | "cancelled";
  personal_data: {
    name: string;
    service_name: string | null;
    image: string | null;
    rating: number | null;
    is_rated: boolean;
    address?: string;
    customer_rating: {
      id: number;
      catalogue_id: number | null;
      comment: string;
      rating: number;
      created_at: string;
      updated_at: string;
      spa_id: number;
      account_id: number;
    } | null;
    slot: {
      id: number;
      sub_category_id: number | null;
      duration: string;
      price: string;
      created_at: string;
      updated_at: string;
      start_time: string;
      end_time: string;
      catalogue_id: number;
    } | null;
    email: string;
    phone_number: number | null;
    country_code: number | null;
    category: string | null;
    sub_category_name: string | null;
    region: any | null;
    spa_image: string | null;
  };
};

interface ScheduledBooking {
  id: string;
  name: string;
  backgroundColor: string;
  title: string;
  start: string;
  time: string;
  photo: string | null;
  price: string;
  duration: string;
  display: string;
  classNames: string[];
}

// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  serviceName?: string;
  userName?: string;
  bookedID?: number;
  onCancelBooking?: (bookingId: number) => Promise<void>;
  openRescheduleModal?: boolean;
  setOpenRescheduleModal?: (arg: boolean, id?: number, name?: string) => void;
  openCancelModal?: boolean;
  setOpenCancelModal?:
  | ((open?: boolean, bookingId?: number, service_name?: string) => void)
  | ((bookingId?: number, service_name?: string) => void);
  openCustomerCancelModal?: boolean;
  setOpenCustomerCancelModal?: (arg: boolean) => void;
  modalContent?: {
    title: string;
    buttonTitle: string;
  };
  modalData?: RescheduleFromValues;
  setOpenSnack?: () => void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  bookingFormValues: BookingFormValues;
  bookingsCount: BookingsCount;
  selectedSlot: string;
  currentPage: number;
  lastPage: number;
  customerProfiles: CustomerProfile[];
  customerProfilesPage: CustomerProfile[];
  openCancelModal: boolean;
  openCustomerCancelModal: boolean;
  openRescheduleModal: boolean;
  anchorEl: HTMLElement | null;
  currentSlotIndex: number;
  datesRange: any[];
  scheduleServiceType: string;
  showBookings: boolean;
  showSnack: boolean;
  viewMode: string;
  isCustomer: boolean;
  bookingDetails: BookingDetails | null;
  discountedPrice: number | null;
  selectedBookingId?: number;
  selectedService: Catalogue | null;
  slotDays: string[];
  timeSlots: string[];
  scheduleDate: string;
  scheduledBookings: ScheduledBooking[];
  selectedSchedule: ScheduledBooking;

  loading: boolean;
  servicesLoading: boolean;

  categories: Category[];
  services: Catalogue[];
  servicesOptions: CategorySubCategory[];

  buttonMode: string;
  modalName: string;
  bookingId: number;
  preview: string;
  storedBookingInfo?: {
    full_name: string;
    phone_number: number | null;
    email: string;
    image_url: string;
    complete_attributes: CompleteAttributes
  };
  selectedTimeSlotPrice: {
    date: string;
    time: string;
    start_time: string;
    end_time: string;
    duration: string;
    price: string;
    subcategoryName: string | null;
    slotId: number;
    accountId: number | undefined;
    catelogueId: string | undefined;
  } | null;
  isPreviewMode: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Ordermanagement1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  mounted: boolean = false;
  imagesCount = 9;
  apiGetBookingsCallId = "";
  apiGetUserDetails = '';
  apiGetBookingDetailsCallId = '';
  apiGetCategoriesCallId = "";
  apiPutCancelBookingCallId = "";
  apiGetServicesCallId = "";
  apiPatchRescheduleBookingCallId = "";
  apiPostCreateBookingCallId = "";
  apiGetScheduleCallId = "";
  apiPostCustomerDetailsCallId = "";

  pathParam = this.props.navigation.getParam("navigationBarTitleText", 0);
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleBookNow = this.handleBookNow.bind(this);

    // Customizable Area Start
    this.cancelBooking = this.cancelBooking.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: '',
      txtSavedValue: 'A',
      enableField: false,
      // Customizable Area Start
      bookingFormValues: initBookingFormValues,
      bookingsCount: {
        completed: 0,
        booked: 0,
        cancelled: 0,
      },
      selectedSlot: "",
      currentPage: 1,
      lastPage: 0,
      customerProfiles: [],
      customerProfilesPage: [],
      openCancelModal: false,
      openCustomerCancelModal: false,
      openRescheduleModal: false,
      anchorEl: null,
      currentSlotIndex: 0,
      datesRange: [],
      scheduleServiceType: '',
      showBookings: true,
      showSnack: false,
      viewMode: 'completed',
      isCustomer: false,
      bookingDetails: null,
      discountedPrice: null,
      selectedBookingId: undefined,
      selectedService: null,
      slotDays: [],
      timeSlots: [],
      scheduleDate: "",
      scheduledBookings: [],
      selectedSchedule: initScheduleItem,

      loading: false,
      servicesLoading: false,

      categories: [],
      services: [],
      servicesOptions: [],

      buttonMode: "",
      modalName: "",
      bookingId: 0,
      preview: "",
      storedBookingInfo: undefined,
      selectedTimeSlotPrice: null,
      isPreviewMode: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.mounted = true;
    try {
      const userRole = await getStorageData('userRole');
      this.setState({ isCustomer: userRole === 'Customer' });

      const discountPriceStr = await getStorageData('appliedCoupon');
      if (discountPriceStr) {
        const appliedCoupon = JSON.parse(discountPriceStr);
        const discountPrice = parseFloat(appliedCoupon.discount);
        this.setState({ discountedPrice: discountPrice });
      }
      const bookingInfoStr = await getStorageData('bookingInfo');
      if (bookingInfoStr) {
        const bookingInfo = JSON.parse(bookingInfoStr);
        this.setState({ storedBookingInfo: bookingInfo });
      }
      const timeSlotInfo = await getStorageData('selectedTimeSlotPrice');
      if (timeSlotInfo) {
        const slotInfo = JSON.parse(timeSlotInfo);
        this.setState({ selectedTimeSlotPrice: slotInfo });
      }
    } catch (error) {
    }

    const urlParams = new URLSearchParams(window.location.search);
    const buttonMode = urlParams.get('buttonMode');
    const pathParts = window.location.pathname.split('/');
    const id = Number(pathParts[pathParts.length - 1]);
    const urlData = window.location.pathname.split('/');
    const preview = urlData[urlData.length - 1];
    const previewMode = preview === 'preview';
    if (!isNaN(id)) {
      this.pathParam = id;
    }

    if (preview === 'preview') {
      this.setState({ preview, isPreviewMode: previewMode });
    }

    if (buttonMode) {
      this.setState({ buttonMode, bookingId: id });
    } else {
      this.setState({ bookingId: id });
    }

    if (this.pathParam) {

      await this.getBookingDetails(this.pathParam);
    } else if (window.location.pathname.toLowerCase() === "/spabookingform") {
      this.getCategories();
    } else {
      await this.getBookings();
    }
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>
  ): Promise<void> {
    if (this.state.viewMode !== prevState.viewMode) {
      await this.getBookings(this.state.viewMode);
    }
    if (this.state.scheduleDate !== prevState.scheduleDate) {
      await this.getSchedule();
    }
  }

  async componentWillUnmount(): Promise<void> {
    this.mounted = false;
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        'Change Value',
        'From: ' + this.state.txtSavedValue + ' To: ' + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const navigationData = message.getData(getName(MessageEnum.NavigationPayLoadMessage));

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    const { bookings, buttonMode } = this.getDevidedResultValue(navigationData, {});
    if (this.getCombinedLogicalValue(
      this.getCombinedLogicalValue(!this.state.bookingDetails, navigationData),
      this.getCombinedLogicalValue(bookings, buttonMode))
    ) {
      this.setState({
        bookingDetails: Array.isArray(bookings) ? bookings[0] : bookings,
        buttonMode: buttonMode,
        loading: false,
      });
    }

    this.handleAPIData(message);

    if (responseJson) {
      if (apiRequestCallId === this.apiPostCustomerDetailsCallId) {
        const bookingId = responseJson.data?.id;
        if (bookingId) {
          const idParam = !bookingId ? 'preview' : `${bookingId}`;
          window.location.href = `/BookingDetails/${idParam}`;
        }
      }
    }

    if (errorReponse) {
      this.setState({ loading: false, servicesLoading: false });
      this.showErrorAlert(errorReponse, configJSON);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: 'email',
    keyboardType: 'email-address',
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  setSelectedService = (id: string) => {
    const selectedService =
      this.state.services.find((service: Catalogue) => service.id === id) ||
      null;
    this.setState({ selectedService });

    return selectedService;
  };

  // Customizable Area Start
  setBookingDetails = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.apiGetBookingDetailsCallId) {
      if (!responseJson || !responseJson.data || !responseJson.data.detail) {
        if (this.mounted) {
          this.setState({ loading: false });
        }
        return;
      }

      const bookingDetails = responseJson.data.detail;
      if (this.mounted) {
        this.setState({ bookingDetails, loading: false });
      }
    }
  };

  setBookings = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.apiGetBookingsCallId) {
      if (!responseJson || !responseJson.data) {
        this.setState({ loading: false });
        return;
      }

      const bookings = this.getDevidedResultValue(
        responseJson.data[this.state.viewMode],
        []
      ).map((booking: BookingDetails) => ({
        id: booking.id,
        image_url: booking.personal_data.image || defaultProfile,
        name: booking.personal_data.name,
        shortDescription: booking.personal_data.service_name,
      }));
      this.setState({
        bookingsCount: responseJson.data.count,
        lastPage: Math.ceil(bookings.length / this.imagesCount),
        customerProfiles: bookings,
        customerProfilesPage: bookings.slice(0, this.imagesCount),
        loading: false,
      });
    }
  };

  setBookingFormData = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.apiGetCategoriesCallId) {
      this.setState({ categories: responseJson.data });
    }
    if (apiRequestCallId === this.apiGetServicesCallId) {
      const servicesOptions = responseJson.data.map((service: Catalogue) => ({
        id: service.id,
        name: service.attributes.name,
      }));
      this.setState({
        servicesOptions,
        services: responseJson.data,
        servicesLoading: false,
      });
    }
  };

  applyCancelBooking = async (apiRequestCallId: string) => {
    if (apiRequestCallId === this.apiPutCancelBookingCallId) {
      this.setOpenCancelModal();
      this.gotoMyBooking();
      await this.getBookings(this.state.viewMode);
    }
  };

  confirmBookingData = (apiRequestCallId: string) => {
    if (
      apiRequestCallId === this.apiPatchRescheduleBookingCallId ||
      apiRequestCallId === this.apiPostCreateBookingCallId
    ) {
      this.gotoMyBooking();
    }
  };

  setBookingSchedule = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.apiGetScheduleCallId) {
      const data = responseJson.data.booked_slots;
      const scheduledBookings = data.map((booking: BookingDetails) => ({
        id: `${booking.id}`,
        name: booking.personal_data.name,
        backgroundColor: '#059669',
        title: booking.personal_data.category,
        start: dayjs(booking.start_time.split(' ')[0]).format('YYYY-MM-DD'),
        time: `${dayjs(booking.start_time.split(' ')[0]).format(
          'D MMM, hh:mm'
        )}-${dayjs(booking.end_time.split(' ')[0]).format('hh:mma')}`,
        photo: booking.personal_data.image,
        price: booking.personal_data.slot?.price,
        duration: booking.personal_data.slot?.duration,
        display: 'list-item',
        classNames: ['green-card'],
      }));
      this.setState({
        loading: false,
        scheduledBookings,
      });
    }
  };

  handleAPIData = async (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson) {
      this.setBookingDetails(apiRequestCallId, responseJson);
      this.setBookings(apiRequestCallId, responseJson);
      this.setBookingFormData(apiRequestCallId, responseJson);
      this.setBookingSchedule(apiRequestCallId, responseJson);
      this.applyCancelBooking(apiRequestCallId);
      this.confirmBookingData(apiRequestCallId);
    }
  };

  gotoMyBooking = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationBooking)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  gotoBookingForm = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationBookingForm)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  gotoCalendar = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationBookingCalendar)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  gotoBookingDetails = async (id: number) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationBookingDetails)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    this.send(message);
  };

  // Coupons Navigation adding
  goToCouponsPage = (id: number | string) => {
    const url = `/Coupons?id=${id}`;
    const message: Message = new Message(
      getName(MessageEnum.NavigationCouponPage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
    window.location.href = url;
  };

  setBookingFormValues = (values: BookingFormValues) => {
    this.setState({ bookingFormValues: values });
  };

  getAutocompleteStyle = (isError: boolean) => ({
    padding: '12px 16px',
    borderRadius: '8px',
    ...(isError && { border: '1px solid red' }),
  });

  setSelectedSlot = (slot: string) => {
    this.setState({ selectedSlot: slot });
  };

  setTimeSlots = (timeSlots: string[]) => {
    this.setState({ timeSlots })
  }

  setSlotDays = (slotDates: string[]) => {
    const slotDays = slotDates.map((dateItem) => dayjs(dateItem).format("ddd"));
    this.setState({ slotDays });
  };

  goForward = () => {
    const profiles = this.state.customerProfiles;
    const startIndex = this.state.currentPage * this.imagesCount;
    const endIndex = startIndex + this.imagesCount;
    const customerProfilesPage = profiles.slice(startIndex, endIndex);
    if (customerProfilesPage.length) {
      this.setState({
        currentPage: this.state.currentPage + 1,
        customerProfilesPage,
      });
    }
  };

  goBack = () => {
    const profiles = this.state.customerProfiles;
    const endIndex = (this.state.currentPage - 1) * this.imagesCount;
    const startIndex = endIndex - this.imagesCount;
    const customerProfilesPage = profiles.slice(startIndex, endIndex);
    if (customerProfilesPage.length) {
      this.setState({
        currentPage: this.state.currentPage - 1,
        customerProfilesPage,
      });
    }
  };

  getGoBackColor = () => (this.state.currentPage > 1 ? '#398378' : '#94A3B8');

  getGoForwardColor = () =>
    this.state.currentPage * this.imagesCount <
      this.state.customerProfiles.length
      ? '#398378'
      : '#94A3B8';

  pushOrRemoveIndex = (targetArray: any[], item: string | number) => {
    const slotIdentIndex = targetArray.indexOf(item as never);
    slotIdentIndex
      ? targetArray.splice(slotIdentIndex, 1)
      : targetArray.push(item as never);
    return targetArray;
  };

  getLogicalValue(firstValue: boolean, secondValue: React.ReactNode): React.ReactNode | null {
    return firstValue ? secondValue : null;
  }

  getTernaryValue(condition: boolean, firstValue: any, secondValue: any) {
    return condition ? firstValue : secondValue;
  }

  getCombinedLogicalValue(condition1: boolean, condition2: boolean, condition3?: boolean | Element): boolean {
    return condition3 ? (condition1 && condition2 && Boolean(condition3)) : (condition1 && condition2);
  }

  getDevidedResultValue<T>(condition1: T, condition2: T): T {
    return condition1 || condition2;
  }

  getErrorText(touched?: any, errorText?: string) {
    return touched && errorText;
  }

  getResultValue<T>(sourceValue1: T, sourceValue2: T, sourceValue3: T): T {
    return sourceValue1 && sourceValue2 && sourceValue3
  }

  getEmptyObject<T>(objectValue: T): T | {} {
    return objectValue || {}
  }

  showErrorAlert(errorResponse: any, configJSON: any) {
    if (errorResponse) {
      const errorMessage = this.parseApiCatchErrorResponse
        ? this.parseApiCatchErrorResponse(errorResponse.message)
        : configJSON.commonErrorDescription;
      this.showAlert(configJSON.commonErrorText, errorMessage);
    }
  }

  setOpenCancelModal = (id?: number) =>
    this.setState((prev) => ({
      openCancelModal: !prev.openCancelModal,
      selectedBookingId: id || undefined,
    }));

  selectedBookingUserName = () => {
    if (this.state.selectedBookingId) {
      const selectedProfile = this.state.customerProfiles.find(
        (profile) => profile.id === this.state.selectedBookingId
      );
      return selectedProfile?.customerInfo?.fullName;
    }
  };

  setOpenCustomerCancelModal = (value: boolean) =>
    this.setState({ openCustomerCancelModal: value });

  setOpenRescheduleModal = (open: boolean, id?: number, name?: string) => {
    this.setState({
      openRescheduleModal: open,
      modalName: name || 'Default Name',
      selectedBookingId: id || undefined,
    });
  };

  setScheduleCancelModal = (id: string) => {
    const selectedSchedule =
      this.state.scheduledBookings.find((booking) => booking.id === id) ||
      initScheduleItem;
    this.setState((prev) => ({
      openRescheduleModal: !prev.openRescheduleModal,
      selectedSchedule,
    }));
  };

  setOpenSnack = () => this.setState({ showSnack: true });
  setDatesRange = (value: any) => this.setState({ datesRange: value });
  setScheduleServiceType = (
    event: React.ChangeEvent<{ name?: string; value: any }>
  ) => this.setState({ scheduleServiceType: event.target.value });
  getHighlightSlotStyle = (slot: string) =>
    this.state.selectedSlot === slot
      ? {
        backgroundColor: '#398378ff',
        color: '#ffffff',
      }
      : {};
  getDateError = (isDateError: boolean) =>
    isDateError ? { border: '1px solid #DC2626' } : {};
  getInputError = (isTouched?: boolean, error?: string) =>
    !!(isTouched && error);
  renderInitSelect = (value: string, placeholder: string) =>
    value
      ? undefined
      : () => (
        <div style={{ color: '#94A3B8' } as React.CSSProperties}>
          {placeholder}
        </div>
      );
  getButtonBorder = (buttonName: string) => {
    const viewMode = this.state.viewMode;
    const name = viewMode === buttonName ? viewMode : 'defaultStyles';

    const styleVariations: any = {
      completed: { border: '1px solid #059669', backgroundColor: '#D1FAE5' },
      booked: { border: '1px solid #D97706', backgroundColor: '#FEF3C7' },
      cancelled: { border: '1px solid #DC2626', backgroundColor: '#FEE2E2' },
      defaultStyles: { border: '#CBD5E1', backgroundColor: '#FFFFFF' },
    };

    return styleVariations[name];
  };

  async getBookings(status = "completed") {
    this.setState({ loading: true });
    const authToken = await getStorageData("authToken");
    const headers = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetBookingsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.bookingsAPIEndPoint}?status=${status}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.bookingDetailsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getBookingDetails(id: number) {
    this.setState({ loading: true });
    const authToken = await getStorageData('authToken');
    const headers = {
      'Content-Type': 'application/json',
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetBookingDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userBookingAPIEndPoint}?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.bookingDetailsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCategories() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCategoriesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.bookingDetailsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getServices(category: string, subCategory: string) {
    this.setState({ servicesLoading: true, services: [] });
    const authToken = await getStorageData("authToken");
    const headers = {
      "Content-Type": "application/json",
      token: authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetServicesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.serviceApiEndPoint}?id=${category}&sub_category_id=${subCategory}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.bookingDetailsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async cancelBooking(id: number) {
    const authToken = await getStorageData("authToken");
    const headers = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPutCancelBookingCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.cancelBookingApiEndPoint}?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.cancelBookingApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async rescheduleBooking(data: any, id: string) {
    const authToken = await getStorageData("authToken");
    const headers = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPatchRescheduleBookingCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.rescheduleBookingApiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.rescheduleBookingApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async createBooking(data: any) {
    const authToken = await getStorageData("authToken");
    const headers = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostCreateBookingCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createBookingApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getSchedule() {
    this.setState({ loading: true });
    const authToken = await getStorageData("authToken");
    const headers = {
      "Content-Type": "application/json",
      token: authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetScheduleCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.scheduleApiEndPoint}?date=${this.state.scheduleDate}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.bookingDetailsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSimpleCategories = (): CategorySubCategory[] =>
    this.state.categories.map((category) => ({
      id: category.id,
      name: category.attributes.name,
    }));

  getSubCategories = (categoryId: string): CategorySubCategory[] =>
    this.state.categories
      .find((category) => category.id === categoryId)
      ?.attributes.sub_categories.map((subCategory) => ({
        id: subCategory.id,
        name: subCategory.name,
      })) || [];

  generateTimeSlots = (slotDuration: string, timeSlots?: string[]) => {
    if (timeSlots) {
      this.setState({ timeSlots });
      return timeSlots;
    }
    const timeStrings = calculateSlots("10:00 am - 06:00 pm", slotDuration).map(
      (timeObject) => timeObject.time
    );
    this.setState({ timeSlots: timeStrings });
    return timeStrings;
  };

  removeTimeZoneOffset(dateTimeString: string) {
    if (!dateTimeString) {
      return "N/A";
    }
    const offsetIndex = dateTimeString.lastIndexOf('+');
    if (offsetIndex !== -1) {
      const spaceIndex = dateTimeString.lastIndexOf(' ', offsetIndex);
      if (spaceIndex !== -1) {
        return dateTimeString.substring(0, spaceIndex);
      }
    }
    return dateTimeString;
  }

  formatTime(dateTimeString: string) {
    const dateTimeWithoutOffset = this.removeTimeZoneOffset(dateTimeString);
    const date = new Date(dateTimeWithoutOffset);
    if (isNaN(date.getTime())) {
      return '';
    }
    const formattedTime = date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return formattedTime;
  }

  formatDate(dateTimeString: string) {
    const date = new Date(dateTimeString);
    if (isNaN(date.getTime())) {
      return '';
    }
    const formattedDate = date
      .toLocaleString('en-US', {
        month: 'long',
        day: '2-digit',
      })
      .toUpperCase()
      .replace(',', '');

    return ` ${formattedDate}`;
  }

  isValidDate(dateTimeString: string) {
    const date = new Date(dateTimeString);
    return !isNaN(date.getTime());
  }

  calculateFinalPrice(bookingPrice: number, discountedPrice: number | null): number {
    if (discountedPrice !== null) {
      return bookingPrice * (discountedPrice / 100);
    } else {
      return bookingPrice;
    }
  }
  formatTimesBasedOnMode(
    previewStartTime: string | undefined,
    previewEndTime: string | undefined,
    bookingStartTime: string | undefined,
    bookingEndTime: string | undefined,
    isPreviewMode: boolean
  ): { formattedStartTime: string; formattedEndTime: string } {
    let startTime, endTime;

    if (isPreviewMode) {
      startTime = previewStartTime ?? 'Not Available';
      endTime = previewEndTime ?? 'Not Available';
    } else {
      startTime = this.formatTime(this.removeTimeZoneOffset(bookingStartTime ?? 'Not Available'));
      endTime = this.formatTime(this.removeTimeZoneOffset(bookingEndTime ?? 'Not Available'));
    }

    return { formattedStartTime: startTime, formattedEndTime: endTime };
  }

  handleBookNow = async () => {
    const dateTime = this.state.selectedTimeSlotPrice;

    if (!dateTime || !dateTime.time || !dateTime.date || !dateTime.accountId || !dateTime.catelogueId || !dateTime.slotId) {
      return;
    }

    const [startTime, endTime] = dateTime.time.split(' - ');

    const startDateTime = new Date(`${dateTime.date} ${startTime}`);
    const endDateTime = new Date(`${dateTime.date} ${endTime}`);

    const formatDateTime = (date: Date) => {
      return date.toISOString().replace('Z', '') + ' +00:00';
    };

    const formattedStartTime = formatDateTime(startDateTime);
    const formattedEndTime = formatDateTime(endDateTime);

    const bookingData = {
      data: {
        start_time: formattedStartTime,
        end_time: formattedEndTime,
        service_provider_id: dateTime.accountId,
        booking_date: dateTime.date,
        timezone: 'UTC',
        catalogue_id: dateTime.catelogueId,
        bx_block_categories_slot_id: dateTime.slotId,
      },
    };
    await this.postCustomerBookingDetails(bookingData);
  };

  async postCustomerBookingDetails(bookingData: any) {
    const authToken = await getStorageData('authToken');
    const headers = {
      'Content-Type': configJSON.exampleApiContentType,
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostCustomerDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createBookingsApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bookingData)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCombinedAddress(attrs?: CompleteAttributes): string {
    return [attrs?.address, attrs?.city, attrs?.postal_code, attrs?.country]
      .filter(part => part && part.trim() !== '')
      .join(', ');
  }
  // Customizable Area End
}
