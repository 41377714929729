import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { AppHeader } from '../../../components/src/AppHeader.web';
import CouponsController, { Props } from './couponsController';
import { AppFooter } from '../../../components/src/AppFooter.web';
import { couponImg, profileCover } from './assets';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { BreadCrumbs } from '../../../components/src/BreadCrumbs.web';

const outerBoxStyle: React.CSSProperties = {
  backgroundColor: "rgb(232, 246, 244)",
  padding: "50px 150px",
};

const innerBoxStyle: React.CSSProperties = {
  position: "relative" as "relative",
  background: "var(--Primary, #398378)",
  height: "213px",
  borderRadius: 10,
};

const absoluteDivStyle: React.CSSProperties = {
  position: 'absolute' as "absolute",
  padding: "20px",
  zIndex: 10,
};

const profileNameContainerStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: '100%',
  background: `rgba(0, 0, 0, 0.3) url(${profileCover}) center bottom 30% / 100% auto no-repeat`,
  zIndex: 0,
};

const headingStyle: React.CSSProperties = {
  color: "#FFFFFF",
  fontFamily: '"Ubuntu"',
  fontSize: '36px',
  fontWeight: 700,
  lineHeight: '44px',
  letterSpacing: "-0.01em",
  textAlign: "center",
};

export default class Coupons extends CouponsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  navigationMap = {
    'Home': 'LandingPage',
    'My Profile': 'SpaUserProfile',
    'Coupons': 'CustomerCoupons',
  };

  handleNavigate = (route: string) => {
    this.props.navigation.navigate(route);
  };

  render() {
    const {
      error,
      coupons,
      showWarning,
      warningMinCartValue,
      warningCouponId,
    } = this.state;

    const {
      fromCustomerProfile,
    } = this.props;

   const id = this.state.isPreviewMode ? 'preview' : this.state.bookingId;
   console.log(id);
   
    return (
      <Box bgcolor='#E8F6F4' height='full'>
        {
          fromCustomerProfile ? (
            <>
              <AppHeader showLogin={false} />
              <Box style={outerBoxStyle}>
                <Box style={innerBoxStyle}>
                  <div style={absoluteDivStyle}>
                    <Box display={"flex"} alignItems={"center"}>
                      <Box display='flex' zIndex="100">
                        <BreadCrumbs
                          data-testid="customer-breadcrumbs"
                          items={['Home', 'My Profile', 'Coupons']}
                          targetColor='#FFFFFF'
                          navigationMap={this.navigationMap}
                          navigate={this.handleNavigate}
                        />
                      </Box>
                    </Box>
                  </div>
                  <div className="profileNameContainer" style={profileNameContainerStyle}>
                    <div>
                      <Typography
                        style={headingStyle}
                        data-test-id="baner-heading"
                      >
                        Coupons
                      </Typography>
                    </div>
                  </div>
                </Box>
              </Box>
            </>
          ) :
            (
              <AppHeader />
            )
        }
        <Box mx='10rem' marginTop='40px'>
          <Box mb={2} mt={-2}>
            {!fromCustomerProfile && (
              <ArrowBackIcon
                style={{ padding: '10px', cursor: 'pointer' }}
                onClick={() => this.goToBookingsPage(id)}
                />
            )}
          </Box>
          <Typography
            variant='h2'
            style={{ fontWeight: 600, marginBottom: '30px' }}
          >
            Coupons for you
          </Typography>
          <Typography variant='body1' style={{ marginBottom: '2rem' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Typography>
          {error ? (
            <Typography
              className='error-message'
              variant='body2'
              style={{ color: '#E53E3E' }}
            >
              {error}
            </Typography>
          ) : (
            coupons.map((coupon, index) => {
              const {
                id: couponId,
                code,
                discount,
                discount_type,
                valid_to,
                min_cart_value,
              } = coupon.attributes;
              const formattedExpiryDate = new Date(valid_to)
                .toLocaleString('en-US', {
                  month: 'short',
                  day: '2-digit',
                  year: 'numeric',
                })
                .toUpperCase()
                .replace(',', '');

              const timePart = new Date(valid_to).toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              });

              return (
                <Box key={couponId + index} mb='1rem'>
                  <Box
                    bgcolor='#FFFFFF'
                    p='1rem'
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    borderRadius='8px'
                    boxShadow='0 4px 8px rgba(0, 0, 0, 0.1)'
                  >
                    <Box display='flex' alignItems='center' py={4}>
                      <Box>
                        <img
                          src={couponImg}
                          alt='Coupon'
                          style={{
                            width: '50px',
                            height: '50px',
                            borderRadius: '8px',
                          }}
                        />
                      </Box>
                      <Box
                        mx='1rem'
                        width='2px'
                        height='50px'
                        bgcolor='#B3B3B3'
                      ></Box>
                      <Box data-test-id="coupon-details">
                        <Typography variant='body1'>
                          On Minimum booking of ${min_cart_value} 
                          <Typography
                            component='span'
                            style={{
                              color: '#398378',
                              fontWeight: 400,
                              fontSize: '16px',
                              marginLeft: '1rem',
                            }}
                            data-test-id="coupon-discount"
                          >
                            {
                              !fromCustomerProfile && (
                                discount_type === 'percentage'
                                  ? `${discount}% off`
                                  : `$${discount} off`
                              )
                            }
                          </Typography>
                        </Typography>
                        <Typography
                          variant='body2'
                          style={{ color: '#A0AEC0' }}
                        >
                          Code:{' '}
                          <Typography
                            component='span'
                            style={{
                              fontWeight: 'bold',
                              fontSize: '16px',
                              color: 'black',
                            }}
                          >
                            {code}
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                    {
                      fromCustomerProfile ? (
                        <Typography
                          style={{
                            fontWeight: 700,
                            fontSize: '16px',
                            lineHeight: "24px",
                            fontFamily: 'Ubuntu',
                            color: '#398378',
                            textTransform: 'uppercase',
                          }}
                        >
                          {discount_type === 'percentage'
                            ? `${discount}% off`
                            : `$${discount} off`}
                        </Typography>
                      ) : (
                        <Button
                          data-test-id={'applyBtn'}
                          variant='contained'
                          style={{
                            fontWeight: 700,
                            fontSize: '16px',
                            fontFamily: 'Ubuntu',
                            backgroundColor: '#398378',
                            borderRadius: '8px',
                            color: '#FFFFFF',
                            padding: '10px 16px',
                          }}
                          onClick={() =>
                            this.applyCouponHandler(
                              min_cart_value,
                              couponId,
                              code,
                              discount
                            )
                          }
                        >
                          Apply
                        </Button>
                      )
                    }
                  </Box>
                  <Typography
                    variant='body2'
                    style={{
                      marginTop: '1rem',
                      color: '#334155',
                      fontWeight: 400,
                      fontSize: '16px',
                    }}
                  >
                    Expiry:{' '}
                    <strong>
                      <Typography
                        component='span'
                        style={{ fontWeight: 'bold' }}
                      >
                        {formattedExpiryDate}
                      </Typography>
                    </strong>{' '}
                    {timePart}
                  </Typography>
                  {showWarning && warningCouponId === couponId && (
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      bgcolor='#FEE2E2'
                      padding='0.5rem'
                      borderRadius='4px'
                      marginTop='1rem'
                      className='warning-message-box'
                    >
                      <Typography
                        className='warning-message-box'
                        variant='body2'
                        style={{ color: '#DC2626' }}
                      >
                        This Coupon code is not applicable on this booking.
                        Please add booking worth ${warningMinCartValue} to
                        unlock this coupon.
                      </Typography>
                      <Typography
                        variant='body2'
                        style={{
                          color: '#DC2626',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                        }}
                      >
                        X
                      </Typography>
                    </Box>
                  )}
                </Box>
              );
            })
          )}
        </Box>
        <AppFooter navigation={this.props.navigation} />
      </Box>
    );
  }
}
