import React from "react";
// Customizable Area Start
import { Box, Button, Typography, styled } from '@material-ui/core';
import { profileCover, notFound } from "./assets"
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import { BookingWrapper } from "../../ordermanagement1/src/components/BookingWrapper";
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import CancelModal from "../../../blocks/ordermanagement1/src/components/CancelModal";

const CustomButton = styled(Button)({
    lineHeight: '24px',
    textTransform: 'none',
    backgroundColor: '#FFFFFF',
    color: '#398378',
    fontWeight: 700,
    fontSize: '16px',
    padding: "10px 16px",
    borderRadius: "8px",
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    '&:hover': {
        backgroundColor: '#f0f0f0',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    },
});

const CardButtonsWhite = styled(Button)({
    lineHeight: '24px',
    textTransform: 'none',
    backgroundColor: '#E8F6F4',
    color: '#398378',
    fontWeight: 700,
    fontSize: '16px',
    borderRadius: "8px",
    padding: "10px 16px",
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    '&:hover': {
        backgroundColor: '#d6ece8',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    },
});

const CardButtonsDark = styled(Button)({
    lineHeight: '24px',
    textTransform: 'none',
    backgroundColor: '#398378',
    color: '#E8F6F4',
    fontWeight: 700,
    fontSize: '16px',
    padding: "10px 16px",
    borderRadius: "8px",
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    '&:hover': {
        backgroundColor: '#2e6a60',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    },
});

const ToggleButtonBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center !important",
    justifyContent: 'space-between',
    '& .normalBtn': {
        display: 'flex',
        width: '152px',
        height: '68px',
        borderRadius: '8px',
        border: "1px",
        fontSize: '12px',
        backgroundColor: "#398378",
        fontFamily: 'Ubuntu',
        textTransform: 'none',
        textAlign: 'center',
        padding: "10px",
        marginRight: "20px"
    },
    '& .typoBox': {
        width: '100%',
        color: '#047854',
        textAlign: 'left',
    },
    '& .typograph': {
        fontWeight: 700
    },
    '& .typoAppoint': {
        fontWeight: 400
    },
})

// Customizable Area End


class MyBookings extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderBookings = () => {
        const { buttonMode, bookedBookings, completedBookings, cancelledBookings } = this.state;
        let currentBookings = [];
        switch (buttonMode) {
            case 'completed':
                currentBookings = completedBookings;
                break;
            case 'booked':
                currentBookings = bookedBookings;
                break;
            case 'cancelled':
                currentBookings = cancelledBookings;
                break;
            default:
                break;
        }

        return currentBookings.length > 0 ? (
            <Box display="flex" flexWrap="wrap" mt={2} data-testid="bookings-list">
                {currentBookings.map((booking) => (
                    <Box
                        key={booking.id}
                        marginBottom={2}
                        style={{
                            background: `linear-gradient(
                            rgba(0, 0, 0, 0.2), 
                            rgba(0, 0, 0, 0.5)
                          ), url(${profileCover}) center center / cover no-repeat`,
                            padding: '20px',
                            borderRadius: '8px',
                            width: 'calc(33.33% - 16px)',
                            height: "15rem",
                            margin: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            boxSizing: 'border-box',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Box mt={4}>
                            <Typography data-testid="service-name" style={{ color: '#FFF', fontWeight: 700, fontSize: "24px", marginBottom: '8px' }}>
                                {booking.personal_data.spa_detail?.full_name || 'No Spa name available'}
                            </Typography>
                            <Typography data-testid="fallback-text" style={{ color: '#FFF', fontWeight: 700, fontSize: "16px" }}>
                                {booking.personal_data.service_name ? booking.personal_data.service_name : 'No service name available'}
                            </Typography>
                        </Box>
                        <Box marginTop={2}>
                            {buttonMode === 'booked' ? (
                                <Box>
                                    <CardButtonsWhite style={{ marginRight: '20px' }}
                                        data-testid="cancel-button"
                                        onClick={() => {
                                            this.setOpenCancelModal(true, booking.id, booking.personal_data.spa_detail.full_name);
                                        }}
                                    >Cancel</CardButtonsWhite>
                                    <CardButtonsDark onClick={() => this.gotoBookingDetails(booking.id)} data-testid="view-details-button">View Details</CardButtonsDark>
                                </Box>
                            ) : (
                                <CardButtonsWhite onClick={() => this.gotoBookingDetails(booking.id)} data-testid="view-details-buttons">View Details</CardButtonsWhite>
                            )}
                        </Box>
                    </Box>
                ))}
            </Box>
        ) : (
            <Box mt={4} display="flex" justifyContent="center" alignItems="center">
                <img src={notFound} style={{ width: "38rem", height: "17rem" }} alt="not-found" />
            </Box>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const customStyles = {
            background: `linear-gradient(
                rgba(0, 0, 0, 0.2), 
                rgba(0, 0, 0, 0.5)
              ), url(${profileCover}) center bottom 30% / 100% auto no-repeat`,
        };

        return (
            <Box bgcolor="#D9EEEB">
                <BookingWrapper
                    navigation={this.props.navigation}
                    backgroundImage={profileCover}
                    heading="My Bookings"
                    breadcrumbs={['Home', 'My Profile', 'My Bookings']}
                    customStyles={customStyles}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={4} data-testid="toggle-button-box">
                        <ToggleButtonBox>
                            <Button variant="contained" className="normalBtn"
                                data-testId="completed"
                                onClick={() => this.setState({ buttonMode: 'completed' })}
                                style={this.addButtonStyles('completed')}
                            >
                                <Box className="typoBox">
                                    <Typography className="typograph">
                                        Completed
                                    </Typography>
                                    <Typography className="typoAppoint">
                                        {this.state.count.completed} Appointments
                                    </Typography>
                                </Box>
                            </Button>
                            <Button
                                variant="contained"
                                className="normalBtn"
                                data-testId="booked"
                                onClick={() => this.setState({ buttonMode: 'booked' })}
                                style={this.addButtonStyles('booked')}
                            >
                                <Box className="typoBox" style={{ color: '#D97706' }}>
                                    <Typography className="typograph">
                                        Upcoming
                                    </Typography>
                                    <Typography className="typoAppoint">
                                        {this.state.count.booked} Appointments
                                    </Typography>
                                </Box>
                            </Button>
                            <Button variant="contained" className="normalBtn"
                                data-testId="cancelled"
                                onClick={() => this.setState({ buttonMode: 'cancelled' })}
                                style={this.addButtonStyles('cancelled')}
                            > <Box className="typoBox" style={{ color: '#DC2626' }}>
                                    <Typography className="typograph">
                                        Cancelled
                                    </Typography>
                                    <Typography className="typoAppoint">
                                        {this.state.count.cancelled}  Appointments
                                    </Typography>
                                </Box>
                            </Button>
                        </ToggleButtonBox>
                    </Box>
                    {this.renderBookings()}
                </BookingWrapper>
                <CancelModal
                    openCancelModal={this.state.openCancelModal}
                    setOpenCancelModal={this.setOpenCancelModal}
                    serviceName={this.state.cancelModalServiceName}
                    bookedID={this.state.bookedID}
                    onCancelBooking={this.onCancelBooking}
                    navigation={this.props.navigation}
                    id=''
                />
            </Box>
        );
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

}
// Customizable Area Start
export default MyBookings;
// Customizable Area End


