import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styles, TypographyView } from './styledFont';
import { AppHeader } from '../../../components/src/AppHeader.web';
import { BreadCrumbs } from '../../../components/src/BreadCrumbs.web';
import { AppFooter } from '../../../components/src/AppFooter.web';
import SubscriptionBlock from './components/SubscriptionBlock.web';
// Customizable Area End

import SubscriptionbillingController, {
  Props,
  SubscriptionTime,
  configJSON,
  initSubscription,
} from "./SubscriptionbillingController";

export default class Subscriptionbilling extends SubscriptionbillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getUserCurrentSubscription();
  }
  // Customizable Area End

  render() {
    const {
      id,
      plan: {
        name,
        description,
        price,
        monthly_price: monthlyPrice,
        symbol,
        image_url: imageUrl,
        update_plan: updatePlan,
        plan_content: planContent
      },
      status,
      expiry_date: expiryDate,
    } = this.state.currentSubscriptionPlan || initSubscription;

    const parsedDescrList = JSON.parse(planContent);
    const isExpired = status === 'Expired';

    return (
      // Customizable Area Start
      <TypographyView>
        <Box style={styles.SafeAreaView} className='landing-venue-block'>
          <AppHeader />
          <Box className='search-location-block' py={6} px='140px'>
            <BreadCrumbs items={['Home', 'Spa Profile', 'My Subscription']} targetColor='#398378' />
            <Typography
              style={{
                color: '#0F172A',
                fontFamily: 'Ubuntu',
                fontSize: '48px',
                fontWeight: 700,
                padding: '30px 0'
              }}
            >My Subscription</Typography>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. . Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. .
            </Typography>
            {/* Plan Block */}
            <Box my={4} width='100%' style={{ backgroundColor: '#FFFFFF' }}>
              <Box py={2} px={4} mb={2} style={{ backgroundColor: '#398378' }}>
                <Typography style={{ fontSize: '30px', fontWeight: 700, color: '#FFFFFF' }}>My Current Plan</Typography>
              </Box>
              {this.state.currentSubscriptionPlan ? <SubscriptionBlock
                navigation={this.props.navigation}
                id=''
                subsContent={{
                  title: name,
                  currancy: symbol,
                  price,
                  monthlyPrice,
                  period: SubscriptionTime.month,
                  status,
                  expiryDate,
                  description,
                  parsedDescrList,
                  imageUrl,
                  updatePlan
                }}
                subscriptionButtons={
                  <Box display='flex' gridGap={24}>
                    {isExpired && <Button
                      data-test-id='renew-btn'
                      variant="contained"
                      color="primary"
                      style={{
                        ...styles.actionButton,
                        ...this.getRenewButtonStyle(updatePlan)
                      } as React.CSSProperties}
                      onClick={() => this.gotoSubscriptionPayment(id)}
                    >
                      {`Renew ${name}`}
                    </Button>}
                    <Button
                      data-test-id='update-btn'
                      variant="contained"
                      color="primary"
                      style={{
                        ...styles.actionButton,
                        ...(!updatePlan && {
                          visibility: 'hidden'
                        }),
                        background: 'var(--Primary, #D9EEEB)',
                        color: '#398378',
                      } as React.CSSProperties}
                      onClick={() => this.gotoSubscriptionPayment(id)}
                    >
                      {`Upgrade to ${updatePlan}`}
                    </Button>
                  </Box>
                }
              /> :
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '38vh'
                }}>
                  <CircularProgress style={{ color: '#398378' }} />
                </Box>}
            </Box>
          </Box>
          {/* footer */}
          <AppFooter navigation={this.props.navigation} />
        </Box>
      </TypographyView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
