import React from "react";
// Customizable Area Start
import { Box, Button, InputLabel, TextField, Typography, styled, Select, MenuItem } from '@material-ui/core';
import { location, camera, editIcon, profileCover } from "./assets"
import UserProfileBasicController, {
    Props, configJSON
} from "./UserProfileBasicController";
import { AppHeader } from "../../../../packages/components/src/AppHeader.web";
import { AppFooter } from "../../../../packages/components/src/AppFooter.web";
import { BreadCrumbs } from '../../../components/src/BreadCrumbs.web';
import { arrowDown } from "../../../../packages/blocks/email-account-registration/src/assets";
import { Autocomplete } from "@material-ui/lab";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import { getCountryCallingCode } from 'libphonenumber-js';
import { CustomPopover, MainWrapper } from "./SpaUserProfile.web";
import ExpandMore from '@material-ui/icons/ExpandMore';

export const InputFields = styled(Box)({
    '& .testLabel': {
        paddingTop: '10px',
        fontWeight: 700,
        color: '#334155',
        fontFamily: 'Ubuntu',
        fontSize: 14,
        marginBottom: "-5px"
    },
    '& .testStar': {
        color: 'red',
        paddingTop: '10px',
        paddingLeft: '5px'
    },
    '& .basicHeading': {
        color: "#000000",
        fontFamily: 'Ubuntu',
        fontSize: "24px",
        fontWeight: 700,
        paddingBottom: "20px"
    },
    '& .inputeStyle': {
        borderRadius: '8px',
        border: '1px solid var(--Neutrals-Cool-gray-300, #CBD5E1)',
        background: 'var(--Basic-White, #FFF)',
        '& .Mui-disabled': {
            color: "grey",
            fontFamily: 'Ubuntu',
            fontSize: 16,
        }
    },
    "& .buttonContainer": {
        height: "44px",
        width: "120px",
        padding: "10px 16px",
        gap: "8px",
        borderRadius: "8px",
        background: "#398378",
        fontFamily: "'Ubuntu'",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: 'center',
        color: "#FFFFFF",
        textTransform: "none",
        whiteSpace: 'nowrap',
        marginBottom: " 20px",
        flexShrink: 0
    },
    '& .cancelButton': {
        height: "44px",
        width: "120px",
        padding: "10px 16px",
        gap: "8px",
        borderRadius: "8px",
        background: "#D9EEEB",
        fontFamily: "'Ubuntu'",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: 'center',
        color: "#398378",
        textTransform: "none",
        whiteSpace: 'nowrap',
        marginLeft: "20px",
        marginBottom: " 20px"
    },
})

const StyleTextInput = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: "8px",
        background: "var(--Basic-White, #FFF)",
        "&fieldset": {
            borderRadius: "8px",
        }
    },
    '& .MuiFormHelperText-root': {
        fontSize: '0.75rem',
        marginLeft: '0px',
        color: theme.palette.error.main,
    },
    '& .Mui-disabled': {
        color: "grey",
        fontFamily: 'Ubuntu',
        fontSize: 16,
        lineHeight: 24
    }
}));

const StyleSelectInput = styled(Select)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: "8px",
        "&.Mui-focused fieldset": {
            borderColor: theme.palette.primary.main,
        }
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: "8px",
    },
    '& .MuiSelect-select': {
        color: "black",
        fontWeight: 400,
        fontFamily: 'Ubuntu',
        fontSize: "16px",
    },
    '& .Mui-disabled': {
        color: "grey",
        fontFamily: 'Ubuntu',
    },
    '& .MuiSelect-icon': {
        fontSize: "1.5rem",
        color: "#B0B0B0",
    },
    '&.Mui-focused .MuiSelect-select': {
        backgroundColor: "transparent"
    }
}));


const PhoneInputContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    height: '45px',
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: "8px",
    padding: theme.spacing(0.5, 1),
    marginTop: "17px",
    backgroundColor:"white",
    '&:focus-within': {
        border: `2px solid ${theme.palette.primary.main}`,
    },
    '& input': {
        flexGrow: 1,
        border: 'none',
        outline: 'none',
        fontSize: theme.typography.body1.fontSize,
        fontFamily: 'Ubuntu',
        backgroundColor:"white",
        marginLeft: "0.5rem"
    },
    '&.react-tel-input .flag': {
        transform: 'scale(1.5)',
        transformOrigin: 'center',
    },
    '& .country-dropdown': {
        marginLeft: theme.spacing(1),
        cursor: 'pointer',
    },
    '& .fileSize': {
        display: 'flex',
    },
    '& .PhoneInputCountrySelectArrow': {
        color: 'blue',
        marginLeft: '1rem',
        fontSize: '1.2rem',
    },
    '& .PhoneInputCountryIcon': {
        marginLeft: '0.5rem',
    },
}));

// Customizable Area End


class CustomerInformation extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    navigationMap = {
        'Home': 'LandingPage',
        'My Profile': 'SpaUserProfile',
        'Basic Information': 'CustomerInformation',
    };

    handleNavigate = (route: string) => {
        this.props.navigation.navigate(route);
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const userDetails = this.getTruthyValue(this.state.userDetails?.attributes);
        const { profile_data: profileData, full_name: SPAVenueName } = userDetails.attributes;

        const { isEditMode, editedDetails } = this.state;

        const renderProfilePicInput = () => (
            <input
                data-testId='profilepic'
                accept="image/*"
                id="inputinuserblock"
                className="imgContainer"
                onChange={this.handleProfilePicUpload}
                type="file"
            />
        );

        const city = this.getTruthyValueOrDefault(
            this.getEitherLogicalValue(
                this.state.city,
                this.state.userDetails?.attributes?.profile_data?.attributes?.city,
            ),
            "");

        const country = this.getTruthyValueOrDefault(
            this.getEitherLogicalValue(
                this.state.country,
                this.state.userDetails?.attributes?.profile_data?.attributes?.country
            ),
            "");

        return (
            <MainWrapper>
                <AppHeader showLogin={false} />
                <Box style={{ backgroundColor: "rgb(232, 246, 244)", padding: "50px 150px" }}>
                    <Box style={{ boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15)", background: "#F8FFFE", borderRadius: 10 }}>
                        <Box style={{ position: "relative", background: "var(--Primary, #398378)", height: "213px", borderRadius: 10 }}>
                            <div style={{ position: 'absolute', padding: "20px", zIndex: 10 }}>
                                <Box display={"flex"} alignItems={"center"}>
                                    <Box display='flex' zIndex="100px">
                                        <BreadCrumbs
                                            data-testid="customer-breadcrumbs"
                                            items={['Home', 'My Profile', 'Basic Information']}
                                            targetColor='#FFFFFF'
                                            navigationMap={this.navigationMap}
                                            navigate={this.handleNavigate}
                                        />
                                    </Box>
                                </Box>
                            </div>
                            <div className="profileNameContainer" style={{
                                height: '100%',
                                background: `rgba(0, 0, 0, 0.3) url(${profileCover}) center bottom 30% / 100% auto no-repeat`,
                                zIndex: 0,
                            }}>
                                <div style={{ marginTop: "4%" }}>
                                    <Typography
                                        style={{
                                            color: "#FFFFFF",
                                            fontFamily: '"Ubuntu"',
                                            fontSize: '36px',
                                            fontWeight: 700,
                                            lineHeight: '44px',
                                            letterSpacing: "-0.01em",
                                            textAlign: "center"
                                        }}
                                        data-test-id="baner-heading"
                                    >
                                        Basic Information
                                    </Typography>
                                </div>
                            </div>
                        </Box>
                        <InputFields>
                            <Box style={{ background: "#F8FFFE", margin: "0px 100px", paddingBottom: 100 }}>
                                <div>
                                    <div style={{ display: "flex", height: '106px' }}>
                                        <div style={{ position: 'relative' }}>
                                            <div className="imgContainer" style={{
                                                display: 'flex',
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                gap: 12,
                                                alignItems: "center",
                                                height: "156px",
                                                width: "156px",
                                                background: "var(--Primary, #398378)",
                                                borderRadius: "50%",
                                                position: "absolute",
                                                top: "-50px",
                                                border: "4px solid #fff",
                                            }}>
                                                <img
                                                    id="profileImage"
                                                    src={this.state.avatarImage}
                                                    className="profileImg"
                                                    style={{
                                                        position: 'absolute',
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: "50%"
                                                    }}
                                                />
                                                <>
                                                    <img src={camera} alt="Camera icon" style={{ position: 'absolute', cursor: 'pointer' }} />
                                                    <div>
                                                        <Typography className="imgsuggestionText" style={{ fontFamily: 'Ubuntu', fontSize: "10px", fontWeight: "400", lineHeight: "12px", textAlign: "center", color: "#D9EEEB" }}>jpg, GIF or PNG,<br />Max size of 800k</Typography>
                                                    </div>
                                                    {renderProfilePicInput()}
                                                </>
                                                {this.getLogicalValue(
                                                    this.state.avatarImage,
                                                    <div className="editIconContainer" style={{ cursor: 'pointer', position: 'absolute', right: '-2px', bottom: "6px", background: "#E8F6F4", padding: "12px", borderRadius: "50%" }}>
                                                        <div style={{ position: "relative" }}>
                                                            <img
                                                                data-testId="editIcon"
                                                                src={editIcon}
                                                                width={"24px"}
                                                                height={"24px"}
                                                                onClick={(event) => this.setState({ anchorEl: event.currentTarget })}
                                                                alt="Edit icon"
                                                            />
                                                            <CustomPopover
                                                                data-test-id="edit-logo-prompt"
                                                                open={Boolean(this.state.anchorEl)}
                                                                onClose={() => {
                                                                    this.setState({ anchorEl: null });
                                                                }}
                                                                anchorEl={this.state.anchorEl}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'center',
                                                                }}
                                                                PaperProps={{
                                                                    style: { width: '300px', height: 96 }
                                                                }}
                                                            >
                                                                <div className="tooltip" style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <Button className="popoverButton" style={{ height: "48px", padding: '12px 16px 12px 16px', textTransform: 'none', fontWeight: 400 }} onClick={() => { }}>
                                                                        Edit Photo
                                                                    </Button>
                                                                    {renderProfilePicInput()}
                                                                    <Button data-testId="removeLogo" className="popoverButton" style={{ height: "48px", padding: '12px 16px 12px 16px', textTransform: 'none', fontWeight: 400 }} onClick={this.handleRemoveProfilePic}>Remove Photo</Button>
                                                                </div>
                                                            </CustomPopover>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="" style={{ marginLeft: "200px", width: "100%", display: 'flex', alignItems: "center", justifyContent: 'space-between' }}>
                                            <div>
                                                <Typography className="userNameText" data-test-id="spa-name">
                                                    {SPAVenueName}
                                                </Typography>
                                                <Box display={'flex'} alignItems={"center"} style={{ gap: 8 }}>
                                                    <img src={location} height={20} alt="Location icon" />
                                                    <Typography className="locationSummarytext" data-test-id="location">
                                                        {profileData?.attributes.address}{" "}
                                                        <Typography>
                                                            {profileData?.attributes.city}
                                                            {profileData?.attributes.city && profileData?.attributes.country && ', '}
                                                            {profileData?.attributes.country}
                                                        </Typography>
                                                    </Typography>
                                                </Box>
                                            </div>
                                            {this.getLogicalValue(
                                                !isEditMode,
                                                <div>
                                                    <Button className="buttonContainer" onClick={this.handleEdit}>Edit Profile</Button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Box>


                            <Box mx={10}>
                                <Typography className="basicHeading">Basic Information</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: "20px" }}>
                                    <Box sx={{ flex: 1, mr: 1 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <InputLabel className="testLabel">{"Name"}</InputLabel>
                                            {this.getLogicalValue(
                                                isEditMode,
                                                <InputLabel className="testStar">{"*"}</InputLabel>
                                            )}
                                        </Box>
                                        <StyleTextInput
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            name="full_name"
                                            value={this.getTernaryValue(
                                                isEditMode,
                                                editedDetails.full_name,
                                                this.state.userDetails?.attributes.full_name || ''
                                            )}
                                            onChange={this.handleChange}
                                            disabled={!isEditMode}
                                            error={this.getCombinedLogicalValue(isEditMode, !this.validateFullName(editedDetails.full_name))}
                                            helperText={this.getTernaryValue(
                                                this.getCombinedLogicalValue(isEditMode, !this.validateFullName(editedDetails.full_name)),
                                                'Please enter a valid Full Name',
                                                ''
                                            )}
                                            inputProps={{
                                                maxLength: 20,
                                            }}
                                        />

                                    </Box>
                                    <Box sx={{ flex: 1, ml: 1 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <InputLabel className="testLabel">{"Contact Number"}</InputLabel>
                                            {this.getLogicalValue(
                                                isEditMode,
                                                <InputLabel className="testStar">{"*"}</InputLabel>
                                            )}
                                        </Box>
                                        <PhoneInputContainer>
                                            <PhoneInput
                                                disabled={!isEditMode}
                                                data-test-id="phone_input"
                                                country={this.state.userDetails?.attributes.country_code ? this.state.userDetails.attributes.country_code.toString() : "us"}
                                                value={this.getFormattedPhoneNumber()}
                                                onChange={(phone) => {
                                                    if (phone) {
                                                        const countryCode = this.state.userDetails?.attributes.country_code || '';

                                                        const numericPhone = parseInt(phone.replace(new RegExp(`^\\+?${countryCode}`), '').replace(/\D/g, ''), 10);
                                                        this.setState({
                                                            phoneNumber: numericPhone
                                                        });

                                                    }
                                                }}
                                                onCountryChange={(country) => {
                                                    if (country) {
                                                        const countryCode = parseInt(getCountryCallingCode(country), 10);

                                                        this.setState({
                                                            userDetails: {
                                                                ...this.state.userDetails,
                                                                attributes: {
                                                                    ...this.state.userDetails.attributes,
                                                                    country_code: countryCode,
                                                                }
                                                            }
                                                        });

                                                    }
                                                }}
                                            />
                                        </PhoneInputContainer>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: "20px" }}>
                                    <Box sx={{ flex: 1, mr: 1 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <InputLabel className="testLabel">{"Email Id"}</InputLabel>
                                            {this.getLogicalValue(
                                                isEditMode,
                                                <InputLabel className="testStar">{"*"}</InputLabel>
                                            )}
                                        </Box>
                                        <StyleTextInput
                                            disabled={!isEditMode}
                                            fullWidth
                                            data-test-id="email"
                                            variant="outlined"
                                            margin="normal"
                                            onChange={this.handleChange}
                                            name="email"
                                            value={this.getTernaryValue(
                                                isEditMode,
                                                editedDetails.email,
                                                userDetails?.attributes.email || ''
                                            )}
                                            error={this.getCombinedLogicalValue(isEditMode, !this.validateEmailId(editedDetails.email))}
                                            helperText={this.getTernaryValue(
                                                this.getCombinedLogicalValue(isEditMode, !this.validateEmailId(editedDetails.email)),
                                                'Please enter a valid email',
                                                ''
                                            )}
                                            inputProps={{
                                                maxLength: 50,
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ flex: 1, ml: 1 }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: "15px" }}>
                                            <InputLabel className="testLabel">{"Gender"}</InputLabel>
                                            {this.getLogicalValue(
                                                isEditMode,
                                                <InputLabel className="testStar">{"*"}</InputLabel>
                                            )}
                                        </Box>
                                        <StyleSelectInput
                                            data-test-id="select-gender"
                                            disabled={!isEditMode}
                                            fullWidth
                                            variant="outlined"
                                            value={this.getTernaryValue(
                                                isEditMode,
                                                this.state.editedDetails.gender,
                                                this.state.userDetails?.attributes.gender || ''
                                            )}
                                            name="gender"
                                            onChange={this.handleSelectChange}
                                            IconComponent={ExpandMore}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null
                                            }}
                                        >
                                            <MenuItem value={"Male"}>Male</MenuItem>
                                            <MenuItem value={"Female"}>Female</MenuItem>
                                        </StyleSelectInput>
                                    </Box>

                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: "15px" }}>
                                    <Box sx={{ flex: 1, mr: 1, mb: 5 }}>
                                        <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                                            <InputLabel className="testLabel" style={{ marginBottom: "15px" }}>{"Country"}{" "}</InputLabel>
                                            {this.getLogicalValue(
                                                isEditMode,
                                                <InputLabel className="testStar">{"*"}</InputLabel>
                                            )}
                                        </Box>
                                        <Autocomplete
                                            data-test-id="country-autocomplete"
                                            disabled={!isEditMode}
                                            fullWidth
                                            options={this.state.countries.map((country) => country.name)}
                                            className='inputeStyle'
                                            autoComplete
                                            autoHighlight
                                            disableClearable
                                            disablePortal
                                            value={this.getDisplayCountry()}
                                            style={{ ...(this.isAndConditionRendering(this.state.countryError, { borderColor: '#DC2626' })) }}
                                            onChange={this.onSelectCountryAndCities}
                                            popupIcon={<img width={25} height={25} style={{ marginRight: "0.5rem" }} src={arrowDown} alt="" />}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder='Select country'
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '12px 16px',
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {this.getLogicalValue(
                                            this.state.countryError,
                                            <Typography className="txtErrorMsg" style={{ color: "red" }}>Please enter your country</Typography>
                                        )}
                                    </Box>
                                    <Box sx={{ flex: 1, ml: 1 }}>
                                        <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                                            <InputLabel className="testLabel" style={{ marginBottom: "15px" }}>{"City"}{" "}</InputLabel>
                                            {this.getLogicalValue(
                                                isEditMode,
                                                <InputLabel className="testStar">{"*"}</InputLabel>
                                            )}
                                        </Box>
                                        <Autocomplete
                                            data-test-id="city-autocomplete"
                                            disabled={!isEditMode}
                                            fullWidth
                                            options={this.state.cities.map((city) => city.name)}
                                            className='inputeStyle'
                                            autoComplete
                                            autoHighlight
                                            disableClearable
                                            disablePortal
                                            value={this.getDisplayCity()}
                                            style={{ ...(this.isAndConditionRendering(this.state.cityError, { borderColor: '#DC2626' })) }}
                                            onChange={this.onSelectCity}
                                            popupIcon={<img width={25} height={25} style={{ marginRight: "0.5rem" }} src={arrowDown} alt="" />}
                                            renderInput={(params) => (
                                                <StyleTextInput
                                                    {...params}
                                                    placeholder='Select city'
                                                    error={!this.validateCity(city)}
                                                    helperText={this.getTernaryValue(!this.validateCity(city), 'Please select a city', '')}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        disableUnderline: true,
                                                        style: {
                                                            padding: '12px 16px',
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                        {this.getLogicalValue(
                                            this.state.cityError,
                                            <Typography className="txtErrorMsg" style={{ color: "red" }}>{configJSON.cityErrorMsg}</Typography>
                                        )}
                                    </Box>
                                </Box>
                                {this.getLogicalValue(
                                    isEditMode,
                                    <Box display="flex" mt={1} mb={5}>
                                        <div>
                                            <Button className="buttonContainer" data-test-id="save-btn" onClick={() => this.handleSave()}>Save</Button>
                                        </div>
                                        <div>
                                            <Button className="cancelButton" onClick={this.handleCancel}>Cancel</Button>
                                        </div>
                                    </Box>
                                )}
                            </Box>
                        </InputFields>

                    </Box>
                </Box>

                {/* footer */}
                <AppFooter navigation={this.props.navigation} />
            </MainWrapper >
        );
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

}
// Customizable Area Start
export default CustomerInformation;
// Customizable Area End


