import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from '../../../framework/src/Utilities';
import { CategorySlot } from '../../../blocks/settings1/src/Settings1Controller';
import { getFormDataFromPayload } from './handle-form';
export const configJSON = require("./config");

type AvailClassInstance = {
    setState: ({ loading }: { loading: true }) => void;
    apiAvailabilityCallId: string
}

const createUpdateAvailability = async (classInstance: AvailClassInstance, formData: any, isUpdate?: boolean) => {
    classInstance.setState({ loading: true });
    const authToken = await getStorageData('authToken');
    const headers = {
        "token": authToken
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    classInstance.apiAvailabilityCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        isUpdate ? configJSON.availabilityUpdateApiEndPoint : configJSON.availabilityApiEndPoint
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        isUpdate ? configJSON.updateApiMethod : configJSON.saveApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
}

export const callAvailability = (settingsForm: Record<string, CategorySlot[] | any>, classInstance: AvailClassInstance) => {
    const formData = new FormData();
    const updateFormData = new FormData();
    settingsForm.categorySlots.forEach((categorySlot: CategorySlot) => {
        const {
            availabilityId,
            date,
            slots,
            capacity,
            catalogueId,
            slotData: {
                id: slotId
            }
        } = categorySlot;

        const productPayload = {
            'availability[][catalogue_id]': catalogueId,
            'availability[][dates]': JSON.stringify(date),
            'availability[][bx_block_categories_slot_id]': slotId,
            'availability[][timeslots]': JSON.stringify(slots),
            'availability[][capacity]': capacity
        };
        const productUpdatePayload = {
            'availability[][id]': availabilityId,
            'availability[][dates]': JSON.stringify(date),
            'availability[][timeslots]': JSON.stringify(slots),
            'availability[][capacity]': capacity
        };
        availabilityId ?
            getFormDataFromPayload(productUpdatePayload, updateFormData, true) :
            getFormDataFromPayload(productPayload, formData, true);
    });
    const isFormData = !formData.entries().next().done;
    const isUpdFormData = !updateFormData.entries().next().done;

    isFormData && createUpdateAvailability(classInstance, formData);
    isUpdFormData && createUpdateAvailability(classInstance, updateFormData, true);
};
