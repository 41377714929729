import React from 'react'
import {
  Box,
  Button,
} from "@material-ui/core";
import {styled} from "@material-ui/core/styles";

const Logo = require("./logo_white.svg");
const avtar = require("./avtarHeader.png");

function navigateToSpaUserProfile() {
  window.location.href = '/SpaUserProfile'; 
}

export function AppHeader(props:any) {
  const handleProfileClick = () => {
    if (props.onProfileClick) {
      props.onProfileClick();
    } else {
      navigateToSpaUserProfile();
    }
  };
  return (
  <Box style={styles.container as React.CSSProperties}>
       <img  src={Logo} alt={""} style={{width:'105px',height:'43px',objectFit:'cover'}}/>
      {props.showLogin && <ButtonComponent>
        <Button
          className='login'
          onClick={() => props.onClickEvent()}
        >
          Login
        </Button>
      </ButtonComponent>
      }
       <img
          src={props.profileImageUrl || avtar}
          alt="User Profile"
          style={styles.profileImage as React.CSSProperties}
          onClick={handleProfileClick}
        />
    </Box>)
}
const ButtonComponent = styled(Button)({
  '& .login':{
    display: 'flex',
    // width: '120px',
    height: '44px',
    padding:'10px 16px',borderRadius:'8px',
    background: 'var(--Primary, #398378)',
    fontFamily: 'Ubuntu',
    textTransform:'none',
    color:'#fff'
  },
})
const styles = {
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '56px',
    backgroundColor: '#000',
    flexDirection:'row',
    paddingLeft:'141px',
    paddingRight:'141px',
    display:'flex'
  },
  text: {
   fontSize: 36,
   fontWeight: '600',
  },
  profileImage: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    cursor: 'pointer'
  }
}
