export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
// Customizable Area Start
export const arrowImg = require("../assets/image_arrow.svg")
export const personOutline = require("../assets/person_outline.svg")
export const couponImg = require("../assets/coupon.svg")
export const hocReporting = require("../assets/hoc_reporting.svg")
export const logout = require("../assets/logout.svg")
export const my_booking = require("../assets/my_booking.svg")
export const setAvailablity = require("../assets/set_availbilty.svg")
export const setting = require("../assets/setting.svg")
export const subscription = require("../assets/subscription.svg")
export const notification = require("../assets/notification.svg");
export const camera = require("../assets/camera.svg");
export const editIcon = require("../assets/editIcon.svg");
export const uploadImg = require("../assets/upload.svg");
export const closeIcon = require("../assets/closeIcon.svg");
export const reviewSpaNameIcon = require("../assets/spaNameReview.png");

export const imgArrowRight = require('../../landingpage/assets/arrow-right.png');
export const imgAdvLayer01 = require('../../landingpage/assets/adv-layer01.png');
export const imgAdvLayer02 = require('../../landingpage/assets/adv-layer02.png');
export const imgAdvLayer03 = require('../../landingpage/assets/adv-layer03.png');
export const imgLocationSearch = require('../../landingpage/assets/location-search.svg');
export const imgMapImage = require('../../landingpage/assets/map-image.png');
export const imgPendingClock = require('../../landingpage/assets/pending-clock.svg');
export const imgVenue01 = require('../../landingpage/assets/venue01.png');
export const imgVenue02 = require('../../landingpage/assets/venue02.png');
export const imgVenue03 = require('../../landingpage/assets/venue03.png');
export const imgVenue04 = require('../../landingpage/assets/venue04.png');
export const imgVenue05 = require('../../landingpage/assets/venue05.png');
export const imgCarouselArrowBack = require('../../landingpage/assets/carousel-arrow-back.svg');
export const imgCarouselArrowNext = require('../../landingpage/assets/carousel-arrow-next.svg');
export const imgDollarSign = require('../../landingpage/assets/dollarSign.svg');
export const imgArrowDownTriangle = require('../../landingpage/assets/arrow-down-triangle.svg');
export const imgMapVenue = require('../../landingpage/assets/map-venue.jpg');
export const image_venue = require('../../landingpage/assets/image_venue.svg');
export const image_venue_1 = require('../../landingpage/assets/image_venue (1).svg');
export const image_venue_2 = require('../../landingpage/assets/image_venue (2).svg');
export const image_venue_3 = require('../../landingpage/assets/image_venue (3).svg');
export const image_venue_4 = require('../../landingpage/assets/image_venue (4).svg');
export const image_venue_5 = require('../../landingpage/assets/image_venue (5).svg');
export const image_star = require("../../landingpage/assets/image.svg");
export const left_arrow = require('../../landingpage/assets/leftArrow.png');
export const right_arrow = require('../../landingpage/assets/rightArrow.png');
export const categoryDefaultImage = require('../../landingpage/assets/cat_03.jpg');
export const fileUploadImg = require("../assets/imgUpload.png");
export const addIcon = require("../assets/addIcon.png");
export const location = require("../assets/image_location.png")
export const backArrowIcon = require("../assets/back_arrow_icon.png");
export const arrowIcon = require("../assets/arrowIcon.svg");
export const filesIcon = require("../assets/files.svg");
export const threeDotIcon = require("../assets/threeDot.svg");
export const locationMap = require("../assets/locationmap.png");
export const bussiness_location = require("../assets/bussiness_location.png");
export const addWhiteIcon = require("../assets/addWhiteIcon.png");
export const deleteWhiteIcon = require("../assets/deleteWhite.png");
export const AddTimingIcon = require("../assets/AddTimingIcon.png");
export const calendarIcon = require("../assets/calenderIcon.png");
export const clockIcon = require("../assets/clockIcon.png");
export const fileIcon = require("../assets/fileIcon.png");
export const deleteLicence = require("../assets/deleteLicence.png");
export const editLicense = require("../assets/editLicense.png");
export const timeRangeArrow = require("../assets/timeRangeArrow.png");
export const timerClock = require("../assets/timer-clock.png");
export const clodUploadImg = require("../assets/image_cloud_upload.png");
export const profileCover = require("../assets/profile_cover.jpeg");
export const clockWait = require("../assets/clock-wait.png");
export const clockClosed = require("../assets/clock-closed.png");
export const clockOpened = require("../assets/clock-opened.png");
export const notFound = require("../assets/no_booking.png");
export const successMessageIcon = require("../assets/successMessageIcon.png");
export const imgPendingClockGrey = require('../assets/pending-clock.png');
// Customizable Area End